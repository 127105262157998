import * as React from 'react'

function SvgProcessCompleted(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="1em"
      viewBox="0 0 1024 1024"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M443.176 39.992C230.3 79.684 69.672 244.4 37.828 455.664 2.984 686.792 139.144 914.46 359.556 993.616c61.508 22.088 97.048 27.996 168.444 27.996s106.936-5.908 168.444-27.996C813.716 951.5 915.748 860.732 971.776 748.684c58.532-117.064 67.292-259.96 23.536-384.02C914.804 136.408 676.772-3.56 443.176 39.992m155.512 29.992C772.024 98.096 917.624 222.672 969.56 387.312c53.276 168.888 7.804 349.312-118.82 471.456-62.488 60.276-125.756 95.828-210.74 118.424-42.452 11.288-51.088 12.228-112 12.228-60.996 0-69.536-.932-112.62-12.336-157.712-41.748-281.424-157.732-328.94-308.396-51.376-162.892-10.404-339.12 106.98-460.16C300.568 98.04 452.156 46.216 598.688 69.984m-9.672 438.988L432.044 665.944l-61.068-60.972C337.388 571.436 307.136 544 303.752 544c-7.928 0-15.752 7.868-15.752 15.844C288 567.824 424.084 704 432.06 704 440 704 768 375.732 768 367.784c0-7.944-7.852-15.784-15.808-15.784-3.412 0-76.844 70.636-163.176 156.972" />
    </svg>
  )
}

export default SvgProcessCompleted
