import * as React from 'react'

function SvgEpilotSmallLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="1em"
      viewBox="0 0 35 32"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M28.349 11.165c0 1.814-.435 3.52-1.214 5.034-.253.489-.536.967-.86 1.416a11.638 11.638 0 01-2.013 2.143c-.486.399-1.012.768-1.568 1.077a11.403 11.403 0 01-4.633 1.445l-.404.03c.11-.478.171-.967.171-1.475 0-1.755.961-3.29 2.398-4.127.01 0 .01-.01.02-.01l.06-.03c.426-.25.81-.538 1.164-.867.425-.4.8-.848 1.103-1.346a6.27 6.27 0 00.92-3.29c0-.508-.06-1.007-.172-1.475a6.412 6.412 0 00-.637-1.615 6.48 6.48 0 00-5.665-3.29 6.48 6.48 0 00-5.664 3.29c-1.973 0-3.834.428-5.503 1.206C6.762 4.007 11.415 0 17.019 0s10.257 4.017 11.168 9.281c.111.608.162 1.246.162 1.884zM34.014 20.835c0 6.17-5.068 11.165-11.33 11.165-1.386 0-2.711-.25-3.935-.688a12.965 12.965 0 003.824-4.097h.111c3.57 0 6.474-2.851 6.474-6.38a6.27 6.27 0 00-.92-3.29 12.517 12.517 0 001.689-5.303c2.498 2.053 4.087 5.144 4.087 8.593z" />
      <path d="M21.875 11.165a4.703 4.703 0 01-1.265 3.22 4.657 4.657 0 01-1.122.897c-.01 0-.01.01-.02.01l-.061.03a6.38 6.38 0 00-2.266 2.213c-.04.07-.081.13-.122.2a6.291 6.291 0 00-.809 3.09c0 .508-.08.987-.222 1.445a4.874 4.874 0 01-.81 1.515 4.866 4.866 0 01-2.771 1.715 10.965 10.965 0 01-.88-2.791 10.448 10.448 0 01-.162-1.884c0-1.675.374-3.25 1.042-4.676.06-.12.11-.239.182-.359.192-.368.404-.727.637-1.076.07-.11.152-.22.223-.33a11.167 11.167 0 013.59-3.22 10.896 10.896 0 011.73-.807c.91-.338 1.892-.558 2.893-.648.132.459.213.947.213 1.456z" />
      <path d="M22.522 22.72a10.969 10.969 0 01-.88 2.79 10.53 10.53 0 01-.809 1.436 11.36 11.36 0 01-3.814 3.559c-.546.319-1.133.588-1.73.807-1.223.449-2.548.688-3.934.688C5.093 32 .025 27.006.025 20.835c0-3.45 1.588-6.54 4.097-8.593a10.597 10.597 0 011.568-1.077 11.336 11.336 0 015.027-1.475c.213-.01.425-.02.638-.02.354 0 .698.02 1.031.05 1.012.09 1.983.309 2.894.648a13.072 13.072 0 00-2.782 2.582c-.385.478-.729.987-1.042 1.515h-.111a6.497 6.497 0 00-5.554 3.09 6.27 6.27 0 00-.92 3.29c0 3.529 2.903 6.38 6.473 6.38h.112a6.28 6.28 0 001.74-.27 6.435 6.435 0 003.813-3.02c.627 0 1.244-.04 1.851-.13a13.176 13.176 0 003.662-1.086z" />
    </svg>
  )
}

export default SvgEpilotSmallLogo
