import { ConsentBox, Consents } from './types'

export const getRequiredConsents = (consents: ConsentBox[]) => {
  let requiredConsents: Consents = {}

  consents
    .filter((c) => c.isRequired)
    .forEach((c) => {
      requiredConsents = {
        ...requiredConsents,
        [c.name]: c.isRequired
      }
    })

  return requiredConsents
}

export const getConsentsMap = (data: Record<string, boolean> | undefined) => {
  return new Map(data ? Object.entries(data) : [])
}
