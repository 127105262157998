import * as React from 'react'

function SvgProcessQualification(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="1em"
      viewBox="0 0 1024 1024"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M178 176L78.056 276.004 313.508 511.56 197.796 627.652C134.156 691.5 81.612 745.16 81.036 746.888c-.576 1.732 52.956 57.128 118.956 123.104L320 989.948 556.584 754 734 936.192 857.06 960.7c67.684 13.48 124.208 23.364 125.608 21.968 1.396-1.4-8.536-57.924-22.076-125.608L935.972 734 756.016 554.036 989.948 320 870 200C804.028 134 749.084 80 747.9 80c-1.18 0-54.292 52.1-118.024 115.772L514 311.548 277.944 75.996 178 176m302 164.536c0 4.26-90.312 97.208-116.436 119.844l-24.18 20.944-203.372-203.256 141.916-142.08 101.036 100.976C434.536 292.5 480 339.108 480 340.536m145.008 284.448l-304.98 304.98-184.004-183.872 97.792-98.028 42.256 41.732 29.724-29.724-41.732-42.26 47.028-46.904c25.864-25.8 48.772-46.908 50.9-46.908s22.352 18.42 44.944 40.932l41.068 40.928 29.86-29.86-85.86-86.124 98.1-97.864 41.876 41.872 29.804-29.8-18.892-19.18C532.5 374.356 524 364.016 524 361.924s21.12-24.98 46.936-50.864l46.936-47.056L704 349.864l29.912-29.908-85.876-85.876 97.892-98.128 184.056 184.056-304.976 304.976M915.36 842.492c9.428 47.488 16.3 87.18 15.276 88.204-1.028 1.028-40.288-5.696-87.252-14.94L758 898.952 584.008 726.076l141.916-142.084 172.296 172.16 17.14 86.34" />
    </svg>
  )
}

export default SvgProcessQualification
