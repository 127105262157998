import { add } from 'date-fns'

import { findNextAvailableDay } from '../../../components/FieldDatePicker'
import { addDate } from '../../../utils/helper'

export const findInitialValues = (
  date: Date | null | undefined,
  prefillByDays: number | undefined,
  disableDays: number[] | undefined
) => {
  if (date) return date

  return typeof prefillByDays === 'number'
    ? findNextAvailableDay(add(addDate(), { days: prefillByDays }), disableDays)
    : null
}
