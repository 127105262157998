import * as React from 'react'

function SvgProcessClosed(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="1em"
      viewBox="0 0 1024 1024"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M443.176 39.992C230.3 79.684 69.672 244.4 37.828 455.664 2.984 686.792 139.144 914.46 359.556 993.616c61.508 22.088 97.048 27.996 168.444 27.996s106.936-5.908 168.444-27.996C813.716 951.5 915.748 860.732 971.776 748.684c58.532-117.064 67.292-259.96 23.536-384.02C914.804 136.408 676.772-3.56 443.176 39.992m155.512 29.992C772.024 98.096 917.624 222.672 969.56 387.312c53.276 168.888 7.804 349.312-118.82 471.456-62.488 60.276-125.756 95.828-210.74 118.424-42.452 11.288-51.088 12.228-112 12.228-60.996 0-69.536-.932-112.62-12.336-157.712-41.748-281.424-157.732-328.94-308.396-51.376-162.892-10.404-339.12 106.98-460.16C300.568 98.04 452.156 46.216 598.688 69.984M324.8 324.8c-2.64 2.64-4.8 7.576-4.8 10.968s41.836 48.032 92.968 99.2L505.936 528l-92.968 93.032C361.836 672.2 320 716.84 320 720.232c0 7.916 7.852 15.768 15.768 15.768 3.392 0 48.032-41.836 99.2-92.968L528 550.064l93.032 92.968C672.2 694.164 716.84 736 720.232 736s8.328-2.16 10.968-4.8c2.64-2.64 4.8-7.576 4.8-10.968s-41.836-48.032-92.968-99.2L550.064 528l92.968-93.032C694.164 383.8 736 339.16 736 335.768c0-7.916-7.852-15.768-15.768-15.768-3.392 0-48.032 41.836-99.2 92.968L528 505.936l-93.032-92.968C383.8 361.836 339.16 320 335.768 320s-8.328 2.16-10.968 4.8" />
    </svg>
  )
}

export default SvgProcessClosed
