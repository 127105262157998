import { TextField } from '@epilot/base-elements'
import { toDashCase } from '@epilot/journey-logic-commons'
import React from 'react'
import {
  Control,
  Controller,
  Path,
  UnpackNestedValue,
  PathValue,
  UseControllerProps
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FieldOption } from './types'

type FieldTextProps<T> = {
  control: Control<T>
  name: Path<T>
  hasError: boolean
  defaultValue?: UnpackNestedValue<PathValue<T, Path<T>>>
  path: string
  rules?: UseControllerProps['rules']
} & FieldOption

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FieldText = <T extends Record<string, any>>(
  props: FieldTextProps<T>
) => {
  const {
    hasError,
    control,
    name,
    label,
    placeholder,
    errorMessage,
    defaultValue,
    required,
    rules,
    disabled,
    path
  } = props

  const { t } = useTranslation()
  const id = `${toDashCase(path)}-${name}`

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <TextField
            InputLabelProps={{ htmlFor: id }}
            disabled={disabled}
            error={hasError && fieldState.invalid}
            fullWidth
            helperText={
              hasError &&
              fieldState.invalid &&
              (errorMessage || t('field_required'))
            }
            id={id}
            label={label || t(name)}
            placeholder={placeholder}
            required={required}
            {...field}
          />
        )
      }}
      rules={{
        required,
        ...rules
      }}
    />
  )
}
