import { StreetSuggestion } from '@epilot/base-modules'
import throttle from 'lodash/throttle'
import React, { useState, useMemo } from 'react'
import {
  Control,
  Path,
  UnpackNestedValue,
  PathValue,
  UseControllerProps
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { apiCall } from '../../../../api'
import { FieldAutocomplete } from '../../../../components/FieldAutocomplete'
import { FieldOption } from '../../../../components/types'
import { Address, FeedBackType } from '../types'
import { isStreetSuggestionData } from '../types.guard'
import { constructStreetUrl, sendFeedback } from '../utils'

export type FieldStreetInputProps<T> = {
  control: Control<T>
  name: Path<T>
  hasError: boolean
  defaultValue?: UnpackNestedValue<PathValue<T, Path<T>>>
  path: string
  rules?: UseControllerProps['rules']
  fieldValues: Address
  acceptSuggestedOnly?: boolean
  freeSolo?: boolean
  disableAddressSuggestions?: boolean
} & FieldOption

const MIN_CHARS = 2

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FieldStreetInput = <T extends Record<string, any>>(
  props: FieldStreetInputProps<T>
) => {
  const {
    label,
    required,
    fieldValues,
    disableAddressSuggestions,
    ...autocompleteProps
  } = props

  const { t } = useTranslation()

  const [options, setOptions] = useState<StreetSuggestion[]>([])

  const streetSuggestions = useMemo(() => {
    return options.map((item) => item.street)
  }, [options])

  const getSuggestions = useMemo(
    () =>
      throttle(async (value: string) => {
        try {
          const countryCode = fieldValues.countryCode
          const city = fieldValues.city
          const zipCode = fieldValues.zipCode

          if (
            disableAddressSuggestions ||
            !value ||
            value.length <= MIN_CHARS ||
            !countryCode ||
            !city ||
            !zipCode
          ) {
            setOptions([])

            return []
          }

          const { data } = await apiCall(
            'GET',
            constructStreetUrl({
              countryCode,
              city,
              zipCode,
              streetName: value
            })
          )

          if (!isStreetSuggestionData(data)) {
            throw Error(
              `Invalid API Response received of ${JSON.stringify(data)}`
            )
          }

          setOptions(data)

          return data
        } catch (e) {
          console.error('Error when fetching StreetOptions', { error: e })

          return []
        }
      }, 500),
    [
      fieldValues.countryCode,
      fieldValues.city,
      fieldValues.zipCode,
      disableAddressSuggestions
    ]
  )

  return (
    <FieldAutocomplete<T, string, StreetSuggestion>
      {...autocompleteProps}
      getSuggestions={getSuggestions}
      label={(label || t('Street')) + (required ? ' *' : '')}
      minChars={MIN_CHARS}
      noOptionsText={t('no_street')}
      onChange={(data, field) => {
        if (typeof data === 'string') {
          const suggestion = options.find((item) => {
            return data === item.street
          })

          sendFeedback(
            FeedBackType.STREETS,
            fieldValues.countryCode,
            suggestion
          )

          return field.onChange(data)
        } else {
          return field.onChange('')
        }
      }}
      options={streetSuggestions}
      required={required}
    />
  )
}
