import { Grid, Hidden } from '@epilot/base-elements'
import { or, rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React, { useState } from 'react'

import { EpilotControlProps } from '../../../utils'
import { includeCommon } from '../../../utils/includeCommon'

import ActionButtons from './ActionButtons'
import ConsentBlocks from './ConsentBlocks'
import { ActionBarControlOptions, Consents } from './types'
import { getRequiredConsents } from './utils'

function ActionBarControl({
  data,
  handleChange,
  path,
  uischema,
  visible,
  errors
}: EpilotControlProps) {
  const { ctaButton, consents } = uischema.options as ActionBarControlOptions
  const [consentsData, setConsentsData] = useState<Consents | undefined>(data)

  const requiredFields = getRequiredConsents(consents)

  const handleOnChange = (data: Consents, _consent: string) => {
    setConsentsData(data)
    let tempErrors = {}

    requiredFields &&
      Object.keys(requiredFields).forEach((k) => {
        if (
          requiredFields?.[k] &&
          (data?.[k] === undefined || data?.[k] === false)
        ) {
          tempErrors = { ...tempErrors, [k]: true }
        }
      })

    if (handleChange) {
      Object.keys(tempErrors).length === 0
        ? handleChange(path, data)
        : handleChange(path, undefined)
    }
  }

  return (
    <Hidden xsUp={!visible}>
      <Grid container spacing={1}>
        <ConsentBlocks
          consents={consents}
          data={consentsData}
          error={errors}
          onChange={handleOnChange}
        />
        <ActionButtons ctaButton={ctaButton} />
      </Grid>
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: ActionBarControl }) as never
)

export const ActionBarControlTester = rankWith(
  5,
  or(uiTypeIs('ActionBarControl'), uiTypeIs('ConsentsControl'))
)
