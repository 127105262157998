import { Typography } from '@epilot/base-elements'
import { EnumControl } from '@epilot/base-modules'
import React from 'react'
import { Control, Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Contact,
  ContactControlOptions
} from '../../../renderers/controls/ContactControl/types'
import { useResetOnChange } from '../../../utils/hooks/forms/useResetOnChange'

type FieldCustomerTypeProps = {
  control: Control<Contact>
  hasError: boolean
  formProps?: Partial<UseFormReturn<Contact>>
} & ContactControlOptions['fields']['customerType']

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FieldCustomerType = (props: FieldCustomerTypeProps) => {
  const {
    hasError,
    control,
    label,
    errorMessage,
    defaultValue,
    options,
    required,
    formProps
  } = props

  const { t } = useTranslation()

  // resets default value if it changes
  useResetOnChange(formProps?.resetField, {
    name: 'customerType',
    watch: defaultValue,
    value: defaultValue
  })

  return (
    <>
      {label && (
        <Typography color="textSecondary" variant="body1">
          {label}
        </Typography>
      )}
      <Controller
        control={control}
        defaultValue={defaultValue}
        name="customerType"
        render={({ field, fieldState }) => {
          return (
            <EnumControl
              errorMessage={
                hasError && fieldState.invalid
                  ? errorMessage || t('field_required')
                  : undefined
              }
              label=""
              options={
                options
                  ? options.map((option) => option.value)
                  : ['Private', 'Business']
              }
              optionsLabels={
                (options &&
                  options.map((option) => {
                    return t(option.label || '')
                  })) || [t('Private'), t('Business')]
              }
              uiType="radio"
              {...field}
            />
          )
        }}
        rules={{
          required
        }}
      />
    </>
  )
}
