import { Divider, Grid, H2 } from '@epilot/base-elements'
import React, { ReactElement } from 'react'

import { ContactFormFields } from '../../../../components/contact/ContactFormFields'
import { FieldText } from '../../../../components/FieldText'
import { isAnyInObject } from '../../../../utils/helper'
import { useFormHandler } from '../../../../utils/hooks/forms/useFormHandler'
import { useResetOnNotEqual } from '../../../../utils/hooks/forms/useResetOnNotEqual'
import { Address, AddressFormProps } from '../types'

import { FieldStreetInput } from './FieldStreetInput'
import { FieldZipCityInput } from './FieldZipCityInput'

export const AddressForm = (props: AddressFormProps): ReactElement => {
  const {
    handleChange,
    path,
    hasError,
    fields,
    data,
    label,
    isRequired,
    acceptSuggestedOnly = true,
    countryAddressSettings: journeyAddressSettings
  } = props

  const addressSuggestionsOnly =
    (journeyAddressSettings?.enableAutoComplete &&
      !journeyAddressSettings.enableFreeText) ??
    acceptSuggestedOnly

  const disableAddressSuggestions = !(
    journeyAddressSettings?.enableAutoComplete ?? true
  )
  const addressFreeText = journeyAddressSettings?.enableFreeText
  const addressCountryCode = journeyAddressSettings?.countryCode

  const { control, fieldValues, setValue, register, resetField } =
    useFormHandler<Address>({
      handleChange,
      fields,
      path,
      data,
      isRequired,
      formProps: {
        defaultValues: {
          countryCode: addressCountryCode || 'DE',
          ...data
        }
      }
    })

  useResetOnNotEqual(resetField, {
    name: 'companyName',
    watch: { name: 'customerType', value: fieldValues.customerType },
    value: fields.companyName?.defaultValue || '',
    match: fields.companyName?.showIf
  })

  useResetOnNotEqual(resetField, {
    name: 'registerNumber',
    watch: { name: 'customerType', value: fieldValues.customerType },
    value: fields.registerNumber?.defaultValue || '',
    match: fields.registerNumber?.showIf
  })

  useResetOnNotEqual(resetField, {
    name: 'registryCourt',
    watch: { name: 'customerType', value: fieldValues.customerType },
    value: fields.registryCourt?.defaultValue || '',
    match: fields.registryCourt?.showIf
  })

  return (
    <>
      {label && <H2>{label}</H2>}
      <Grid container spacing={3}>
        <Grid
          item
          md={fields.zipCity?.layout?.md || 6}
          sm={fields.zipCity?.layout?.sm || 12}
          xs={fields.zipCity?.layout?.xs || 12}
        >
          {fields.zipCity && (
            <FieldZipCityInput
              acceptSuggestedOnly={addressSuggestionsOnly}
              addressCountryCode={addressCountryCode}
              control={control}
              defaultValue={fields.zipCity.defaultValue || ''}
              disableAddressSuggestions={disableAddressSuggestions}
              fieldValues={fieldValues}
              freeSolo={addressFreeText}
              hasError={hasError}
              name="zipCode"
              path={path}
              register={register}
              setValue={setValue}
              {...fields.zipCity}
            />
          )}
        </Grid>
        <Grid
          item
          md={fields.streetName?.layout?.md || 6}
          sm={fields.streetName?.layout?.sm || 12}
          xs={fields.streetName?.layout?.xs || 12}
        >
          {fields.streetName && (
            <FieldStreetInput
              acceptSuggestedOnly={addressSuggestionsOnly}
              control={control}
              defaultValue={fields.streetName.defaultValue || ''}
              disableAddressSuggestions={disableAddressSuggestions}
              fieldValues={fieldValues}
              freeSolo={addressFreeText}
              hasError={hasError}
              name="streetName"
              path={path}
              {...fields.streetName}
            />
          )}
        </Grid>

        {fields.houseNumber && (
          <Grid
            item
            md={fields.houseNumber?.layout?.md || 6}
            sm={fields.houseNumber?.layout?.sm || 12}
            xs={fields.houseNumber?.layout?.xs || 12}
          >
            <FieldText
              control={control}
              defaultValue={fields.houseNumber.defaultValue || ''}
              hasError={hasError}
              name="houseNumber"
              path={path}
              {...fields.houseNumber}
            />
          </Grid>
        )}
        {fields.extention && (
          <Grid
            item
            md={fields.extention?.layout?.md || 6}
            sm={fields.extention?.layout?.sm || 12}
            xs={fields.extention?.layout?.xs || 12}
          >
            <FieldText
              control={control}
              defaultValue={fields.extention.defaultValue || ''}
              hasError={hasError}
              name="extention"
              path={path}
              {...fields.extention}
            />
          </Grid>
        )}
        {isAnyInObject(fields, [
          'salutation',
          'title',
          'firstName',
          'lastName',
          'birthDate',
          'email',
          'telephone'
        ]) && (
          <Grid item style={{ padding: '0 6px' }} xs={12}>
            <Divider
              orientation="horizontal"
              style={{ width: '100%', margin: '4px 0px' }}
            />
          </Grid>
        )}
        <ContactFormFields
          control={control as never}
          fieldValues={fieldValues}
          fields={fields}
          hasError={hasError}
          path={path}
          resetField={resetField}
        />
      </Grid>
    </>
  )
}
