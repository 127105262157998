import * as React from 'react'

function SvgProductHouseElectricity(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="1em"
      viewBox="0 0 1024 1024"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M493.6 63.904c-4.624 1.868-90.124 79.4-190 172.3C34.148 486.816 47.6 472.552 56.168 498.512 63.564 520.92 76.62 528 110.54 528H140l.088 193c.08 179.084.62 194.152 7.472 209 8.936 19.364 21.6 31.552 41.368 39.808C201.864 975.216 242.816 976 512 976s310.136-.784 323.072-6.192c19.768-8.256 32.432-20.444 41.368-39.808 6.852-14.848 7.392-29.916 7.472-209L884 528h27.408c32.172 0 52-10.336 57.188-29.816 6.924-25.996 19.144-13.092-246.228-260.004-100.996-93.972-187.964-172.504-193.26-174.52-11.624-4.42-24.188-4.332-35.508.244m234.68 223.94C843.628 395.556 938 486.008 938 488.844c0 3.696-10.564 5.72-37.336 7.156-30.92 1.656-38.308 3.332-43 9.764-4.7 6.444-5.688 41.496-5.804 206-.156 214.344.308 209.66-22.128 224.452-11.336 7.472-21.984 7.732-316.932 7.756-330.012.032-321.348.576-333.68-21.012-3.896-6.82-5.596-57.392-7.12-211.764-1.564-158.56-3.132-203.936-7.18-208-3.156-3.16-19.604-5.98-42-7.196-26.672-1.448-36.82-3.444-36.82-7.24C86 481.344 504.048 92 512.008 92c3.604 0 100.928 88.128 216.272 195.844m-242.74 176.52c-59.148 61.8-109.052 115.032-110.9 118.292-8.12 14.308.792 24.672 37.516 43.64 27.08 13.988 35.608 20.268 34.168 25.172-1.052 3.592-12.896 48.56-26.32 99.932-22.944 87.828-24.024 93.972-18.112 103 3.976 6.064 10.128 9.6 16.704 9.6 11.448 0 12.108-.624 151.1-142.756C668.98 619.72 667.28 624.156 618 595.192c-17.6-10.348-34.252-20.248-37.004-22-3.832-2.448.604-25.064 19-96.88C613.2 424.784 624 378.56 624 373.596s-2.828-11.852-6.284-15.312c-14.924-14.92-21.152-9.92-132.176 106.08m77.796 26.292c-10.856 43.26-19.2 83.252-18.54 88.868.952 8.092 8.1 14.26 34.36 29.656l33.156 19.436-35.876 38.692C518.608 729.676 439.02 806.672 441.348 798c1.18-4.4 11.388-43.892 22.688-87.764l20.544-79.764-9.064-6.84c-4.988-3.76-21.348-12.78-36.356-20.04-15.012-7.26-26.812-14.512-26.224-16.116C414.104 584.272 579.972 412 581.888 412c.652 0-7.696 35.396-18.552 78.656" />
    </svg>
  )
}

export default SvgProductHouseElectricity
