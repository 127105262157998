import { Hidden, TextField } from '@epilot/base-elements'
import { schemaTypeIs, rankWith } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React, { ChangeEvent } from 'react'

import { EpilotControlProps } from '../../utils'
import { includeCommon } from '../../utils/includeCommon'

function TextFieldControl({
  data,
  handleChange,
  path,
  uischema,
  errors,
  enabled,
  required,
  schema,
  visible
}: EpilotControlProps) {
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    // If value is empty set value to undefined to avoid format validation error
    const value = event.target.value === '' ? undefined : event.target.value

    handleChange(path, value)
  }

  const { label, options } = uischema

  const { errorMessage, examples } = schema

  const placeholder = Array.isArray(examples) && examples[0] ? examples[0] : ''

  return (
    <Hidden xsUp={!visible}>
      <TextField
        disabled={!enabled}
        error={errors ? true : false}
        fullWidth
        helperText={
          errors
            ? errorMessage && typeof errorMessage === 'string'
              ? errorMessage
              : errors
            : ''
        }
        label={label as string}
        onChange={handleOnChange}
        placeholder={placeholder}
        required={required}
        value={data || ''}
        {...options}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: TextFieldControl }) as never
)

export const TextFieldTester = rankWith(3, schemaTypeIs('string'))
