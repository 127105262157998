import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import React from 'react'

import { ButtonStyled, ArrowButtonStyled } from './ButtonStyled'
import { ButtonProps, ArrowButtonProps } from './types'

const PrimaryButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { label, ...rest } = props

    return (
      <ButtonStyled {...rest} color="primary" ref={ref} variant="contained">
        {label}
      </ButtonStyled>
    )
  }
)

PrimaryButton.displayName = 'PrimaryButton'

const SecondaryButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { label, ...rest } = props

    return (
      <ButtonStyled {...rest} color="secondary" ref={ref} variant="outlined">
        {label}
      </ButtonStyled>
    )
  }
)

SecondaryButton.displayName = 'SecondaryButton'

const ArrowLeftButton = (props: ArrowButtonProps) => {
  return (
    <ArrowButtonStyled {...props}>
      <KeyboardArrowLeft />
    </ArrowButtonStyled>
  )
}

const ArrowRightButton = (props: ArrowButtonProps) => {
  return (
    <ArrowButtonStyled {...props}>
      <KeyboardArrowRight />
    </ArrowButtonStyled>
  )
}

export { PrimaryButton, SecondaryButton, ArrowLeftButton, ArrowRightButton }
