import * as React from 'react'

function SvgMessage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 178 148"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#message_svg__clip0)">
        <path
          d="M33.833 106.308v2.917M35.324 107.767h-2.981"
          stroke="#EFF2F3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.188 84.634a1.054 1.054 0 100-2.108 1.054 1.054 0 000 2.108zM35.132 20.701a1.756 1.756 0 100-3.513 1.756 1.756 0 000 3.513zM119.004 3.513a1.756 1.756 0 100-3.512 1.756 1.756 0 000 3.512z"
          fill="#EFF2F3"
        />
        <path
          d="M42.71 0v5.25M45.393 2.624h-5.366M21.581 27.847v2.917M23.072 29.306H20.09"
          stroke="#EFF2F3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          clipRule="evenodd"
          d="M135.601 81.825l.001.008a2.83 2.83 0 002.458 2.45 2.835 2.835 0 00-2.459 2.455v.005a2.832 2.832 0 00-2.458-2.46h-.001a2.829 2.829 0 002.458-2.457l.001-.001zM135.15 3.86l.001.009a2.83 2.83 0 002.458 2.45 2.835 2.835 0 00-2.458 2.455l-.001.005a2.83 2.83 0 00-2.457-2.459h-.002a2.829 2.829 0 002.459-2.458v-.001zM140.501 52.23l.001.008c.158 1.216 1.138 2.172 2.38 2.323-1.243.153-2.222 1.11-2.381 2.326v.005c-.155-1.219-1.136-2.178-2.38-2.33l-.002-.001c1.245-.151 2.227-1.11 2.382-2.33h0z"
          fill="#E5EBEE"
          fillRule="evenodd"
          stroke="#E5EBEE"
          strokeLinejoin="round"
        />
        <path
          clipRule="evenodd"
          d="M155.426 31.942v.005a1.81 1.81 0 001.587 1.549 1.811 1.811 0 00-1.587 1.55v.003a1.81 1.81 0 00-1.587-1.553h-.001c.83-.101 1.484-.741 1.588-1.553v-.001zM37.5 55l.001.008A2.877 2.877 0 0040 57.5a2.881 2.881 0 00-2.5 2.495V60a2.878 2.878 0 00-2.498-2.5H35a2.876 2.876 0 002.5-2.499V55z"
          fill="#EFF2F3"
          fillRule="evenodd"
          stroke="#EFF2F3"
          strokeLinejoin="round"
        />
        <path
          d="M142.486 38.393a.785.785 0 00.794-.777.785.785 0 00-.794-.777.786.786 0 00-.794.777c0 .43.356.777.794.777zM163.206 64.98a.786.786 0 00.794-.776.786.786 0 00-.794-.777.786.786 0 00-.794.777c0 .429.356.776.794.776zM19.5 120a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM151.14 84.725c.876 0 1.587-.696 1.587-1.554 0-.858-.711-1.554-1.587-1.554-.877 0-1.588.696-1.588 1.554 0 .858.711 1.553 1.588 1.553z"
          fill="#EFF2F3"
        />
        <path
          d="M138.996 106.578v4.681M141.338 108.918h-4.684"
          stroke="#EFF2F3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M114.727 5l-58.833.168c-.64.002-1.255.303-1.707.838-.453.534-.706 1.258-.705 2.012l.268 126.934c.001.373.065.743.187 1.087.122.345.301.658.526.922.225.263.492.472.786.614.294.142.608.214.926.213l58.834-.168c.64-.003 1.253-.304 1.704-.838.452-.533.705-1.255.705-2.008L117.15 7.841a3.3 3.3 0 00-.186-1.089 2.92 2.92 0 00-.526-.922 2.436 2.436 0 00-.785-.615 2.11 2.11 0 00-.926-.215z"
          fill="url(#message_svg__paint0_linear)"
        />
        <path
          d="M114.403 5.007L56.2 5.172c-1.238.004-2.24 1.175-2.236 2.615l.265 126.448c.003 1.441 1.01 2.606 2.247 2.602l58.203-.165c1.238-.004 2.239-1.175 2.236-2.615L116.65 7.609c-.003-1.44-1.009-2.606-2.247-2.602z"
          fill="#EFF2F3"
        />
        <path
          d="M115.31 42.028l-.062-30.741c-.002-.738-.255-1.444-.703-1.964-.448-.52-1.055-.812-1.688-.81l-11.669.032c-.178 1.402-.781 2.684-1.7 3.613-.918.929-2.091 1.444-3.307 1.452l-21.691.061c-1.216-.001-2.391-.51-3.314-1.433-.922-.923-1.53-2.201-1.715-3.602l-11.49.032c-.633.002-1.24.297-1.685.82-.447.522-.697 1.23-.695 1.968l.064 30.78"
          fill="#fff"
        />
        <path
          d="M55.59 41.959l.186 88.851c.002.734.255 1.438.702 1.956.447.518 1.053.808 1.684.807l54.774-.155c.631-.002 1.236-.296 1.681-.816.445-.521.695-1.226.693-1.961l-.187-89.107"
          fill="#fff"
        />
        <path d="M101.008 32.001H76.59v4.712h24.418v-4.712z" fill="#C9D5DB" />
        <path
          d="M64.146 34.182c3.538 0 6.405-2.885 6.405-6.444 0-3.56-2.867-6.445-6.405-6.445-3.537 0-6.405 2.885-6.405 6.445 0 3.559 2.868 6.444 6.405 6.444zM110.581 21.054H76.667v2.708h33.914v-2.708zM110.581 26.475H76.667v2.707h33.914v-2.707z"
          fill="#EFF2F3"
        />
        <path
          d="M92.369 9.861l-15.467.233a.382.382 0 00-.376.388l.004.25c.003.212.177.38.388.377l15.466-.232a.382.382 0 00.376-.388l-.004-.25a.382.382 0 00-.387-.378zM96.215 10.82a.61.61 0 10-.019-1.22.61.61 0 00.02 1.22z"
          fill="#C9D5DB"
        />
        <g filter="url(#message_svg__filter0_d)">
          <path
            d="M150.222 45.456H76.856v29.55l6.142-6.104h67.224V45.456z"
            fill="#E5EBEE"
          />
          <path
            d="M144.49 50.926h-41.774v3.978h41.774v-3.978zM144.49 56.894h-41.774v3.979h41.774v-3.979zM89.288 64.957c4.67 0 8.455-3.808 8.455-8.507 0-4.698-3.786-8.506-8.455-8.506-4.669 0-8.454 3.808-8.454 8.506s3.785 8.507 8.454 8.507z"
            fill="#fff"
          />
          <path
            d="M83.549 62.223v0a5.69 5.69 0 015.69-5.69v0a5.69 5.69 0 015.69 5.69v0"
            stroke="#C9D5DB"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            clipRule="evenodd"
            d="M89.239 56.533a3.251 3.251 0 100-6.502 3.251 3.251 0 000 6.502z"
            stroke="#C9D5DB"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g filter="url(#message_svg__filter1_d)">
          <path
            d="M124.631 107.283H76.856v19.243l4-3.976h43.775v-15.267z"
            fill="#E5EBEE"
          />
          <path
            d="M120.898 110.844H93.695v2.59h27.203v-2.59zM120.898 114.73H93.695v2.591h27.203v-2.591zM84.952 119.98c3.04 0 5.505-2.48 5.505-5.54 0-3.059-2.465-5.539-5.505-5.539s-5.506 2.48-5.506 5.539c0 3.06 2.465 5.54 5.506 5.54z"
            fill="#fff"
          />
          <path
            d="M81.215 118.2v0a3.705 3.705 0 013.705-3.705v0a3.705 3.705 0 013.705 3.705v0"
            stroke="#C9D5DB"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            clipRule="evenodd"
            d="M84.92 114.495a2.117 2.117 0 100-4.234 2.117 2.117 0 000 4.234z"
            stroke="#C9D5DB"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g filter="url(#message_svg__filter2_d)">
          <path
            d="M31 80.177h48.046V99.53l-4.023-3.998H31V80.177z"
            fill="#E5EBEE"
          />
          <path
            d="M75.291 83.76H47.935v2.606H75.29V83.76zM75.291 87.668H47.935v2.605H75.29v-2.605zM39.142 92.946c3.058 0 5.536-2.494 5.536-5.57 0-3.077-2.478-5.571-5.536-5.571-3.058 0-5.537 2.494-5.537 5.57 0 3.077 2.48 5.571 5.537 5.571z"
            fill="#fff"
          />
          <path
            d="M35.383 91.158v0a3.726 3.726 0 013.726-3.726v0a3.726 3.726 0 013.726 3.726v0"
            stroke="#C9D5DB"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            clipRule="evenodd"
            d="M39.109 87.432a2.13 2.13 0 100-4.259 2.13 2.13 0 000 4.259z"
            stroke="#C9D5DB"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height={39.55}
          id="message_svg__filter0_d"
          width={83.367}
          x={71.856}
          y={43.456}
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={2.5} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height={27.243}
          id="message_svg__filter1_d"
          width={55.776}
          x={72.856}
          y={106.283}
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height={27.352}
          id="message_svg__filter2_d"
          width={56.046}
          x={27}
          y={79.177}
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="message_svg__paint0_linear"
          x1={35714.6}
          x2={91700.6}
          y1={113732}
          y2={50077.3}
        >
          <stop stopColor="#C9D5DB" />
          <stop offset={1} stopColor="#C9D5DB" />
        </linearGradient>
        <clipPath id="message_svg__clip0">
          <path d="M0 0h178v148H0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgMessage
