type RegExpStatment = {
  [key: string]: {
    name: string
    regex: RegExp
    description: string
  }
}

const regExpStatments: RegExpStatment = {
  email: {
    name: 'email',
    regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    description: 'this pattern is used to check if the email is valid'
  },

  lettersMinimum_3: {
    name: 'lettersMinimum_3',
    regex: /^[a-zA-Z]{3,}$/,
    description:
      'this pattern is used to validate that the input length is at least 3 letter'
  },
  name: {
    name: 'name',
    regex: /^([^0-9!“§$%&/()=?`;:_.,#+*’^°´@{}"[\]<>±|\\]*)$/,
    description:
    'This pattern is used to validate that the input does not contain numbers, and some symbols.'
  },
  numbersMinimum_6: {
    name: 'numbersMinimum_6',
    regex: /^[0-9]{6,}$/,
    description:
      'this pattern is used to validate that the input length is at least 6 numbers'
  },

  numbersTelephone_6: {
    name: 'numbersTelephone_6',
    regex: /^[+]*[0-9]{6,}$/,
    description:
      'this pattern is used to validate that the input length is at least 6 numbers and it could start with + sign'
  },

  numbersExact_11: {
    name: 'numbersExact_11',
    regex: /^[0-9]{11,11}$/,
    description:
      'this pattern is used to validate that the input length exactly 12 numbers'
  },

  numbersMaximum_11: {
    name: 'numbersMaximum_11',
    regex: /^[0-9]{1,11}$/,
    description:
      'this pattern is used to validate that the input length is maximum 11 numbers'
  },

  date_de: {
    name: 'date_de',
    regex: /^[0-3][0-9][.][0-1][0-9][.][1|2][0-9]{3}$/,
    description:
      'this pattern is used to validate that the input is valid german date DD.MM.YYYY'
  },

  telephone_de: {
    name: 'telephone_de',
    regex: /^([+][0-9]{1,3}[ .-]?)?([(]{1}[0-9]{1,6}[)])?([0-9 .-/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$/,
    description: 'this pattern is used to validate that the input is a german telephone'
  }
}

export default regExpStatments
