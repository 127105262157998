import * as React from 'react'

function SvgProductGeneric(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="1em"
      viewBox="0 0 1024 1024"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M414 50.86c-54.36 7.516-98.64 28.868-134.128 64.68-40.036 40.396-57.136 80.736-74.384 175.46-1.26 6.916-4.36 9-13.368 9-38.76 0-89.144 20.58-109.984 44.924C58.752 372.244 50.648 399 50.32 450c-.316 48.936 5.42 67.756 28.732 94.308 22.524 25.656 43.604 32.792 106.872 36.18 90.472 4.84 588.964 4.176 616.34-.824 78.612-14.356 142.3-80.572 148.404-154.288 4.008-48.424-17.608-111.048-50.74-146.992-38.768-42.056-103.852-55.472-174.648-35.996-7.212 1.984-10.196-.504-17.296-14.416-28.248-55.376-77.06-114.18-114.836-138.344C539.684 55.424 477.804 42.036 414 50.86m86.984 34.996C577.912 102.16 631.384 151.184 686.56 256c13.084 24.856 8.32 23.896 67.068 13.492 37.612-6.664 40.972-6.668 66-.112 14.504 3.796 32.996 11.284 41.092 16.632 47.236 31.224 72.016 122.812 47.812 176.752-10.556 23.536-46.052 59.728-71.12 72.516-32.868 16.768-53.316 17.62-378.376 15.772-242.08-1.38-312.068-2.9-323.036-7.016C95.496 528.824 82.164 505.004 82.256 448c.048-31.56 1.636-41.224 9.352-57.024 17.512-35.844 49.06-53.808 101.224-57.636 18.24-1.34 35.42-4.208 38.168-6.376 2.752-2.168 5.024-9.572 5.052-16.452.108-25.652 15.988-88.468 29.624-117.18C306.7 106.944 399.812 64.416 500.984 85.856M504.928 623c-4.38 3.852-32.476 35.4-62.444 70.116C377.752 768.1 376.128 772.768 408 792.38c11 6.768 20 12.86 20 13.532 0 .676-5.4 23.824-12 51.44C399.068 928.2 400.292 940 424.568 940c9.18 0 21.132-11.46 73.2-70.184C542.008 819.92 560 796.8 560 789.84c0-6.848-4.884-13.328-16.26-21.568-8.94-6.48-18.364-12.48-20.932-13.336-3.324-1.108-.752-19.092 8.9-62.224 13.16-58.804 13.352-60.908 6.316-68.688-8.948-9.884-22.544-10.308-33.096-1.024m-11.612 93.484c-5.252 22.268-8.612 43.432-7.468 47.032 1.14 3.604 9.292 11.008 18.116 16.46 8.82 5.452 16.036 11.368 16.036 13.148 0 4.224-72.928 87.284-74.9 85.308-.828-.828 2.28-16.344 6.912-34.48 10.992-43.056 9.676-55.22-7.204-66.556-7.256-4.872-15.332-9.572-17.948-10.444-3.668-1.22 70.236-90.952 74.916-90.952.596 0-3.212 18.22-8.46 40.484" />
    </svg>
  )
}

export default SvgProductGeneric
