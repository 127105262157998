import I18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import de from './de.json'
import en from './en.json'

export const instance18n = I18n.createInstance().use(LanguageDetector)

instance18n.init({
  resources: {
    en: {
      translation: en
    },
    de: {
      translation: de
    }
  },
  detection: {
    order: ['querystring', 'localStorage', 'navigator'],
    caches: ['localStorage'],
    lookupQuerystring: 'lng',
    lookupLocalStorage: 'locale'
  },
  lng: process.env.REACT_APP_IS_JEST ? 'cimode' : 'de',
  fallbackLng: process.env.REACT_APP_IS_JEST ? 'cimode' : 'en',
  interpolation: {
    escapeValue: false
  },
  react: {
    wait: true
  }
})

export const getString = (name: string, params = {}) => {
  return instance18n.t(name, params)
}

export const changeLanguage = (lang: string) => {
  instance18n.changeLanguage(lang)
}
