import { useEffect } from 'react'
import {
  UseFormResetField,
  Path,
  UnpackNestedValue,
  PathValue
} from 'react-hook-form'

type UseResetFormOnChangeParams<T> = {
  name: Path<T>
  watch: UnpackNestedValue<PathValue<T, Path<T>>> | undefined
  value: UnpackNestedValue<PathValue<T, Path<T>>> | undefined
}
/**
 * Watches a given value, and resets it to the passed value for a fieldname
 * @param resetField function from react-hook-form
 * @param params.name field name to reset
 * @param params.watch value to watch
 * @param params.value value to set if watched value changes
 *
 */
export const useResetOnChange = <T>(
  resetField: UseFormResetField<T> | undefined,
  params: UseResetFormOnChangeParams<T>
) => {
  const { name, watch, value } = params

  useEffect(() => {
    if (!resetField) {
      return
    }

    resetField(name, {
      defaultValue: value
    })
  }, [watch])
}
