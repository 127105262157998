import regExpStatments from './reg_exps'
import { ErrorObject } from 'ajv'
import { parseDateDE, validateDate } from './validators'

type SchemaValidateFunction = {
  (schema: any, data: any):
    | boolean
    | Promise<any>
  errors?: Partial<ErrorObject>[]
}

type SchemaKeyword = {
  keyword: string
  callback: (schema: any, data: any) => boolean
  errorMessage: {
    [locale: string]: string
  }
}

export const schemaKeywords: SchemaKeyword[] = [
  {
    keyword: 'email',
    callback: (schema: boolean, data: string) =>
      schema ? regExpStatments.email.regex.test(data) : false,
    errorMessage: {
      en: 'Please enter a valid email address.',
      de: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.'
    }
  },
  {
    keyword: 'onlyLetters',
    callback: (schema: boolean, data: string) =>
      schema ? /^[a-zA-Z]+$/.test(data) : false,
    errorMessage: {
      en: 'Only letters allowed.',
      de: 'Nur Buchstaben erlaubt.'
    }
  },
  {
    keyword: 'onlyNumbers',
    callback: (schema: boolean, data: string) =>
      schema ? /^[0-9]+$/.test(data) : false,
    errorMessage: {
      en: 'Only numbers allowed.',
      de: 'Nur Zahlen erlaubt.'
    }
  },
  {
    keyword: 'dateDE',
    callback: (schema: boolean, data: string) =>
      schema
        ? regExpStatments.date_de.regex.test(data)
        : false,
    errorMessage: {
      en: 'Date should have the format DD.MM.YYYY.',
      de: 'Das Datum sollte das Format TT.MM.JJJJ haben.'
    }
  },
  {
    keyword: 'minNumbersTelephone',
    callback: (schema: number, data: string) =>
    schema && typeof schema === 'number'
        ? new RegExp(`^[+]*[0-9]{${schema},}$`).test(data)
        : false,
    errorMessage: {
      en: 'Please enter a valid telephone number.',
      de: 'Bitte geben Sie eine gültige Telefonnummer ein.'
    }
  },
  {
    keyword: 'validDateDE',
    callback: (schema: boolean, data: string ) =>
      schema? validateDate(data): false,
      errorMessage: {
        en: 'Please enter a valid date.',
        de: 'Bitte geben Sie ein gültiges datum ein.'
      }
  },
  {
    keyword: 'futureDateDE',
    callback: (schema: number, data: string) =>
      schema && typeof schema === 'number'
        ? parseDateDE(data).getTime() > new Date().getTime()+(schema*24*60*60*1000)
        : false,
    errorMessage: {
      en: 'The date must be at least {schema} days in the future.',
      de: 'Das Datum muss mind. {schema} Tage in der Zukunft liegen.'
    }
  },
  {
    keyword: 'rangeDateDE',
    callback: (schema: number[], data: string) => {
      return schema && Array.isArray(schema) && schema.length === 2
        ? parseDateDE(data).getTime() >= new Date().getTime()+(schema[0]*24*60*60*1000) && parseDateDE(data).getTime() <= new Date().getTime()+(schema[1]*24*60*60*1000)
        : false
    },
    errorMessage: {
      en: 'Date must be between {minDate} and {maxDate}.',
      de: 'Datum muss zwischen {minDate} und {maxDate} liegen.'
    }
  },
  {
    keyword: 'birthdateDE',
    callback: (schema: boolean, data: string) =>
      schema
        ? parseDateDE(data).getTime() < new Date().getTime()
        : false,
    errorMessage: {
      en: 'Please enter a valid date of birth.',
      de: 'Bitte geben Sie ein gültiges Geburtsdatum ein.'
    }
  }
]

function convertDate(date: Date, separator = '.') {
  function pad(s: number) { return (s < 10) ? '0' + s : s; }
  return [pad(date.getDate()), pad(date.getMonth()+1), date.getFullYear()].join(separator)
}

export function validateSchemaKeyword(
  schemaKeyword: SchemaKeyword,
  locale?: string
): SchemaValidateFunction {
  const { keyword, callback, errorMessage } = schemaKeyword

  const validate: SchemaValidateFunction = (schema: any, data: any) => {
    switch(keyword) {
      case 'rangeDateDE': {
        const [minDays, maxDays] = schema
        const minDate = new Date()
        minDate.setDate(minDate.getDate() + minDays)
        const maxDate = new Date()
        maxDate.setDate(maxDate.getDate() + maxDays)

        validate.errors = [
          {
            keyword,
            message: errorMessage[locale || 'de'].replace('{minDate}', convertDate(minDate)).replace('{maxDate}', convertDate(maxDate)),
            params: { keyword }
          }
        ]
        break
      }
      default:
        validate.errors = [
          {
            keyword,
            message: errorMessage
              ? errorMessage[locale || 'de'].replace('{schema}', schema)
              : locale === 'en'
              ? 'The entered format is invalid.'
              : 'Das eingegebene Format ist ungültig.',
            params: { keyword }
          }
        ]
        break
    }

    return callback(schema, data)
  }

  return validate
}
