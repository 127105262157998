import { useEffect } from 'react'

import { EventDetailType } from '..'

const useListener = (
  eventName: string,
  listenerFunction: (e: CustomEvent<EventDetailType>) => void
) => {
  useEffect(() => {
    document.addEventListener(eventName, listenerFunction as EventListener)

    return () =>
      document.removeEventListener(eventName, listenerFunction as EventListener)
  }, [eventName, listenerFunction])
}

export default useListener
