import { FormHelperText, Box, SmallText } from '@epilot/base-elements'
import React, { ReactNode, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useIsOverflowRequired } from '../hooks/useIsOverflowRequired'
import { useEnumButtonStyles } from '../styles/useEnumButtonStyles'
import { EnumButtonProps } from '../types'

import { Button } from './Button'

export const EnumButton = (props: EnumButtonProps) => {
  const { handleChange, path, data, options, hasError, label, isRequired } =
    props

  const { t } = useTranslation()
  const refs = useRef<(HTMLButtonElement | null)[]>([])
  const isOverflowRequired = useIsOverflowRequired(refs)
  const { buttonStyles, buttonActiveStyles, buttonWrapStyles } =
    useEnumButtonStyles()

  const handleOnChange = (value: string | undefined) => {
    handleChange(path, value)
  }

  // should be called when ref unmounts
  const deleteRef = (index: number) => {
    refs.current.splice(index, 1)
  }

  const buttons: ReactNode[] = []

  // @todo: move to .map inline in return once moved away from TSDX
  options.forEach(
    (
      option:
        | string
        | {
            value: string
            label: string
          },
      index: number
    ) => {
      const label = typeof option === 'string' ? option : option.label
      const value = typeof option === 'string' ? option : option.value

      buttons.push(
        <Button
          className={`${buttonStyles} ${
            data === value ? buttonActiveStyles : ''
          }`}
          deleteRef={() => deleteRef(index)}
          key={value}
          onClick={() => {
            handleOnChange(value === data ? undefined : value)
          }}
          ref={(el) => {
            return (refs.current[index] = el)
          }}
          size="large"
          variant="outlined"
        >
          {label}
        </Button>
      )
    }
  )

  return (
    <Box>
      {label && (
        <SmallText color="textSecondary">
          {' '}
          {`${label}${isRequired ? '*' : ''}`}
        </SmallText>
      )}
      <Box
        className={buttonWrapStyles}
        display="flex"
        flexDirection={isOverflowRequired ? 'column' : 'row'}
        m={-1.5}
      >
        {buttons}
      </Box>

      {hasError && (
        <FormHelperText error>
          {t('Please select one of the options')}
        </FormHelperText>
      )}
    </Box>
  )
}
