import { StreetSuggestion } from '@epilot/base-modules'

import { ADDRESS_API_URL } from '../../../utils/services/config'

import { Address, FeedBackType, ZipCitySuggestion } from './types'

export const URL_RAW_STREET =
  ADDRESS_API_URL +
  '/v1/integration/location/%country%/streets?postal_code=%zip%&city=%city%&street=%street%'

export const URL_RAW_ZIP_CITY =
  ADDRESS_API_URL +
  '/v1/integration/location/%country%/postalcode-cities?postalcode_city=%postalcode_city%'

export const URL_RAW_FEEDBACK =
  ADDRESS_API_URL +
  '/v1/integration/location/%country%/%type%/send-feedback?uuid=%uuid%'

export function constructStreetUrl(address?: Address) {
  return URL_RAW_STREET.replace(
    '%country%',
    address && address.countryCode ? address.countryCode : 'de'
  )
    .replace(
      '%street%',
      address && address.streetName ? address.streetName : ''
    )
    .replace('%city%', address && address.city ? address.city : '')
    .replace('%zip%', address && address.zipCode ? address.zipCode : '')
    .replace(
      '%postalcode_city%',
      address && address.zipCode
        ? address.zipCode
        : address && address.city
        ? address.city
        : ''
    )
}

export function constructZipCityUrl(country: string, zipcity: string) {
  return URL_RAW_ZIP_CITY?.replace('%country%', country).replace(
    '%postalcode_city%',
    zipcity
  )
}

export function constructFeedbackURL(
  country: string,
  feedbackType: FeedBackType,
  uuid?: string
) {
  return URL_RAW_FEEDBACK?.replace('%type%', feedbackType)
    .replace('%country%', country.toLowerCase())
    .replace('%uuid%', uuid ? uuid : '')
}

export function sendFeedback(
  feedbackType: FeedBackType,
  country: string,
  suggestion: StreetSuggestion | ZipCitySuggestion | undefined
) {
  if (suggestion?.uuid) {
    const url = constructFeedbackURL(country, feedbackType, suggestion.uuid)

    fetch(url)
  }
}

export const regexZipCodeCityByCountry = (countryCode: string) => {
  switch (countryCode.toUpperCase()) {
    case 'DE':
      return /^[0-9]{5}[ ][^0-9!“§$%=?`;:_#+*^°´@{}"[\]<>±|\\]{1,}$/
    default:
      return /^[0-9]{4}[ ][^0-9!“§$%=?`;:_#+*^°´@{}"[\]<>±|\\]{1,}$/
  }
}

export const zipCodeExamplesByCountry = (countryCode: string) => {
  switch (countryCode.toUpperCase()) {
    case 'DE':
      return '12345'
    default:
      return '1234'
  }
}
