import { JsonFormsRendererRegistryEntry } from '@jsonforms/core'

import ActionBarControl, {
  ActionBarControlTester
} from './renderers/controls/ActionBarControl'
import AddressControl, {
  AddressControlTester,
  AddressControlOptions,
  mapToAddressOptions
} from './renderers/controls/AddressControl'
import AddressControlLegacy, {
  AddressControlLegacyTester
} from './renderers/controls/AddressControlLegacy'
import {
  default as BooleanCheckboxControl,
  CheckboxTester
} from './renderers/controls/BooleanCheckboxControl'
import {
  default as BooleanSwitchControl,
  SwitchTester
} from './renderers/controls/BooleanSwitchControl'
import ButtonControl, { ButtonTester } from './renderers/controls/ButtonControl'
import ConfirmationMessageControl, {
  ConfirmationMessageControlTester
} from './renderers/controls/ConfirmationMessageControl'
import ConsumptionCalculatorControl, {
  ConsumptionCalculatorTester
} from './renderers/controls/ConsumptionCalculatorControl'
import ContactControl, {
  ContactControlTester,
  ContactControlOptions,
  mapToContactOptions
} from './renderers/controls/ContactControl'
import CountryInputControl, {
  CountryInputControlTester
} from './renderers/controls/CountryInputControl'
import CrossSellProductSelectionControl, {
  CrossSellProductSelectionControlTester
} from './renderers/controls/CrossSellProductSelectionControl'
import DatePickerControl, {
  DatePickerControlTester,
  DatePickerControlOptions
} from './renderers/controls/DatePickerControl'
import DigitalSignatureControl, {
  DigitalSignatureControlTester
} from './renderers/controls/DigitalSignatureControl'
import DisplayProductControl, {
  DisplayProductTester
} from './renderers/controls/DisplayProductControl'
import DisplayProductSelectionControl, {
  ProductSelectionTester
} from './renderers/controls/DisplayProductSelectionControl'
import {
  default as EnumAutocompleteControl,
  EnumAutocompleteTester
} from './renderers/controls/EnumAutocompleteControl'
import EnumButtonControl, {
  EnumButtonControlTester
} from './renderers/controls/EnumButtonControl'
import {
  default as EnumRadioControl,
  RadioTester
} from './renderers/controls/EnumRadioControl'
import {
  default as EnumSelectControl,
  SelectTester
} from './renderers/controls/EnumSelectControl'
import EnumTabsControl, {
  TabsControlTester
} from './renderers/controls/EnumTabsControl'
import {
  default as EnumToggleControl,
  EnumToggleTester
} from './renderers/controls/EnumToggleControl'
import IbanControl, {
  IbanControlTester
} from './renderers/controls/IbanControl'
import NumberInputControl, {
  NumberInputControlOptions,
  NumberInputControlTester
} from './renderers/controls/NumberInputControl'
import PaymentControl, {
  PaymentControlTester
} from './renderers/controls/PaymentControl'
import PersonalInformationControl, {
  PersonalInformationControlTester
} from './renderers/controls/PersonalInformationControl'
import PreviousProviderControl, {
  PreviousProviderControlTester
} from './renderers/controls/PreviousProviderControl'
import ProductControl, {
  ProductControlTester
} from './renderers/controls/ProductControl'
import ProductSelectionControl, {
  ProductSelectionControlTester
} from './renderers/controls/ProductSelectionControl'
import ShopCartControl, {
  ShopCartControlTester
} from './renderers/controls/ShopCartControl'
import ShoppingCartLayout, {
  ShoppingCartLayoutTester
} from './renderers/controls/ShoppingCartLayout'
import StepperInputControl, {
  StepperInputTester
} from './renderers/controls/StepperInputControl'
import SummaryControlRenderer, {
  SummaryControlTester
} from './renderers/controls/SummaryControl'
import TextFieldControl, {
  TextFieldTester
} from './renderers/controls/TextFieldControl'
import UploadPanelControl, {
  UploadPanelControlTester
} from './renderers/controls/UploadPanelControl'
import CardLayout, { CardLayoutTester } from './renderers/display/CardLayout'
import CollapseLayout, {
  CollapseLayoutTester
} from './renderers/display/CollapseLayout'
import EIconRenderer, {
  EIconRendererTester
} from './renderers/display/EIconRenderer'
import GridLayout, { GridLayoutTester } from './renderers/display/GridLayout'
import IconRenderer, {
  IconRendererTester
} from './renderers/display/IconRenderer'
import InlineSpacingLayout, {
  InlineSpacingLayoutTester
} from './renderers/display/InlineSpacingLayout'
import LabelRenderer, {
  LabelRendererTester
} from './renderers/display/LabelRenderer'
import ListLayout, { ListLayoutTester } from './renderers/display/ListLayout'
import MainContentCartLayout, {
  MainContentCartLayoutTester,
  MainContentCartZones
} from './renderers/display/MainContentCartLayout'
import MainLinearLayout, {
  MainLinearLayoutTester
} from './renderers/display/MainLinearLayout'
import StackSpacingLayout, {
  StackSpacingLayoutTester
} from './renderers/display/StackSpacingLayout'
import TabsLayout, { TabsLayoutTester } from './renderers/display/TabsLayout'

export {
  MainContentCartZones,
  MainContentCartLayout,
  MainContentCartLayoutTester,
  MainLinearLayout,
  MainLinearLayoutTester,
  ActionBarControl,
  ActionBarControlTester,
  ConfirmationMessageControl,
  ConfirmationMessageControlTester,
  PersonalInformationControl,
  PersonalInformationControlTester,
  ContactControl,
  ContactControlOptions,
  ContactControlTester,
  mapToContactOptions,
  CollapseLayoutTester,
  CollapseLayout,
  CardLayoutTester,
  CardLayout,
  LabelRendererTester,
  LabelRenderer,
  IconRendererTester,
  IconRenderer,
  GridLayout,
  GridLayoutTester,
  InlineSpacingLayoutTester,
  InlineSpacingLayout,
  ListLayout,
  ListLayoutTester,
  StackSpacingLayoutTester,
  StackSpacingLayout,
  TabsLayout,
  TabsLayoutTester,
  EIconRendererTester,
  EIconRenderer,
  TextFieldTester,
  StepperInputTester,
  TextFieldControl,
  StepperInputControl,
  EnumToggleTester,
  EnumToggleControl,
  TabsControlTester,
  EnumTabsControl,
  SelectTester,
  EnumSelectControl,
  RadioTester,
  EnumRadioControl,
  EnumAutocompleteControl,
  EnumAutocompleteTester,
  EnumButtonControl,
  EnumButtonControlTester,
  DisplayProductTester,
  DisplayProductControl,
  ProductSelectionTester,
  DisplayProductSelectionControl,
  ButtonTester,
  SwitchTester,
  ButtonControl,
  BooleanSwitchControl,
  CheckboxTester,
  BooleanCheckboxControl,
  ShoppingCartLayoutTester,
  ProductControlTester,
  ProductSelectionControlTester,
  CrossSellProductSelectionControlTester,
  ShoppingCartLayout,
  ProductControl,
  ProductSelectionControl,
  CrossSellProductSelectionControl,
  PreviousProviderControlTester,
  PreviousProviderControl,
  PaymentControlTester,
  PaymentControl,
  IbanControlTester,
  IbanControl,
  DigitalSignatureControlTester,
  DigitalSignatureControl,
  CountryInputControlTester,
  CountryInputControl,
  ConsumptionCalculatorTester,
  ConsumptionCalculatorControl,
  AddressControlLegacy,
  AddressControlLegacyTester,
  AddressControl,
  AddressControlTester,
  AddressControlOptions,
  mapToAddressOptions,
  UploadPanelControl,
  UploadPanelControlTester,
  SummaryControlTester,
  SummaryControlRenderer,
  ShopCartControl,
  ShopCartControlTester,
  DatePickerControl,
  DatePickerControlTester,
  DatePickerControlOptions,
  NumberInputControl,
  NumberInputControlOptions,
  NumberInputControlTester
}

// export i18n instance
export * from './locales/i18n'

// exporting types and the other needed utils
export * from './utils'
export { FieldOptionDate } from './components/FieldDatePicker/types'
export { ActionBarControlOptions } from './renderers/controls/ActionBarControl/types'
export { PersonalInformationControlOptions } from './renderers/controls/PersonalInformationControl/PersonalInformationControl'
export * from './components/PersonalInformation'

// exporting the controls
export const renderers: (JsonFormsRendererRegistryEntry & {
  featureFlag?: boolean
})[] = [
  // controls
  { tester: TextFieldTester, renderer: TextFieldControl },
  { tester: ButtonTester, renderer: ButtonControl },
  { tester: CheckboxTester, renderer: BooleanCheckboxControl },
  { tester: SwitchTester, renderer: BooleanSwitchControl },
  { tester: EnumButtonControlTester, renderer: EnumButtonControl },
  { tester: SelectTester, renderer: EnumSelectControl },
  { tester: EnumToggleTester, renderer: EnumToggleControl },
  { tester: EnumAutocompleteTester, renderer: EnumAutocompleteControl },
  { tester: RadioTester, renderer: EnumRadioControl },
  { tester: DisplayProductTester, renderer: DisplayProductControl },
  { tester: ProductSelectionTester, renderer: DisplayProductSelectionControl },
  {
    tester: CrossSellProductSelectionControlTester,
    renderer: CrossSellProductSelectionControl
  },
  { tester: TabsControlTester, renderer: EnumTabsControl },
  { tester: StepperInputTester, renderer: StepperInputControl },
  { tester: IbanControlTester, renderer: IbanControl },
  { tester: CountryInputControlTester, renderer: CountryInputControl },
  { tester: PaymentControlTester, renderer: PaymentControl },
  { tester: AddressControlLegacyTester, renderer: AddressControlLegacy },
  { tester: AddressControlTester, renderer: AddressControl },
  { tester: UploadPanelControlTester, renderer: UploadPanelControl },
  { tester: ProductControlTester, renderer: ProductControl },
  { tester: ProductSelectionControlTester, renderer: ProductSelectionControl },
  {
    tester: ConsumptionCalculatorTester,
    renderer: ConsumptionCalculatorControl
  },
  { tester: DigitalSignatureControlTester, renderer: DigitalSignatureControl },
  { tester: PreviousProviderControlTester, renderer: PreviousProviderControl },
  {
    tester: PersonalInformationControlTester,
    renderer: PersonalInformationControl
  },
  {
    tester: SummaryControlTester,
    renderer: SummaryControlRenderer
  },
  {
    tester: ConfirmationMessageControlTester,
    renderer: ConfirmationMessageControl
  },
  {
    tester: ActionBarControlTester,
    renderer: ActionBarControl
  },
  {
    tester: ShopCartControlTester,
    renderer: ShopCartControl
  },
  {
    tester: DatePickerControlTester,
    renderer: DatePickerControl
  },
  {
    tester: NumberInputControlTester,
    renderer: NumberInputControl
  },

  // layouts
  { tester: GridLayoutTester, renderer: GridLayout },
  { tester: CardLayoutTester, renderer: CardLayout },
  { tester: InlineSpacingLayoutTester, renderer: InlineSpacingLayout },
  { tester: StackSpacingLayoutTester, renderer: StackSpacingLayout },
  { tester: CollapseLayoutTester, renderer: CollapseLayout },
  { tester: ListLayoutTester, renderer: ListLayout },
  { tester: TabsLayoutTester, renderer: TabsLayout },
  { tester: ShoppingCartLayoutTester, renderer: ShoppingCartLayout },
  { tester: MainContentCartLayoutTester, renderer: MainContentCartLayout },
  { tester: MainLinearLayoutTester, renderer: MainLinearLayout },

  // display
  { tester: LabelRendererTester, renderer: LabelRenderer },
  { tester: IconRendererTester, renderer: IconRenderer },
  { tester: EIconRendererTester, renderer: EIconRenderer },
  {
    tester: ContactControlTester,
    renderer: ContactControl
  }
]
