import { Link, Typography } from '@epilot/base-elements'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { TransformOptions } from 'react-markdown/src/ast-to-react'

const Base64Test = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/

/**
 * String to HTML
 * @param str the source string
 * @returns either a string or a ReactComponent for the MD
 */
export function stringToHTML(str: string, options?: StringToHtmlOptions) {
  if (options?.isPlainText) {
    return str
  }

  // if not plain text -> validate and apply base64 transformations
  if (Base64Test.test(str)) {
    try {
      const decoded = fromBinary(str)

      return <>{mdToHTML(decoded, options)}</>
    } catch {
      return str
    }
  }

  return str
}

export function mdToHTML(str: string, options?: StringToHtmlOptions) {
  return (
    <ReactMarkdown components={getComponentsMapping(options)}>{str}</ReactMarkdown>
  )
}

// convert a Unicode string to a string in which
// each 16-bit unit occupies only one byte
export function toBinary(str: string) {
  const codeUnits = new Uint16Array(str.length)

  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = str.charCodeAt(i)
  }

  return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)))
}

export function fromBinary(encoded: string) {
  const binary = atob(encoded)
  const bytes = new Uint8Array(binary.length)

  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i)
  }

  return String.fromCharCode(...new Uint16Array(bytes.buffer))
}

export type StringToHtmlOptions = {
  allowParagraphs?: boolean,
  isPlainText?: boolean
}

function getComponentsMapping(options = {allowParagraphs: false} as StringToHtmlOptions): TransformOptions['components'] {
  return {
    ...(options.allowParagraphs ? {} : { p: React.Fragment }),
    a: ({ node, ...props }) => <Link target="_blank" {...props as never} />,
    h1: ({ node, ...props }) => <Typography variant="h1" {...props as never} />,
    h2: ({ node, ...props }) => <Typography variant="h2" {...props as never} />,
    h3: ({ node, ...props }) => <Typography variant="h3" {...props as never} />,
    h4: ({ node, ...props }) => <Typography variant="h4" {...props as never} />,
    h5: ({ node, ...props }) => <Typography variant="h5" {...props as never} />,
    h6: ({ node, ...props }) => <Typography variant="h6" {...props as never} />
  }
}

