import { TFunction } from 'i18next'

import { PersonalInformationControlOptions } from '../PersonalInformationControl/PersonalInformationControl'

import { ContactControlOptions, ContactControlOptionsDto } from './types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isLegacyDto = (obj: any): obj is PersonalInformationControlOptions =>
  obj && obj['fieldsOrder']

export const mapToContactOptions = (
  dto: ContactControlOptionsDto,
  translate?: TFunction,
  skipFields?: string[]
): ContactControlOptions | { error: string } => {
  const fields: ContactControlOptions['fields'] = {}
  const t = translate || ((value: string) => value)

  if (!dto) return { error: 'Incoming DTO is not set' }

  if (!isLegacyDto(dto)) return dto

  const fieldsDto =
    dto.fieldsOrder && Array.isArray(dto.fieldsOrder) ? dto.fieldsOrder : []

  try {
    fieldsDto.forEach((item) => {
      const fieldName = item.name

      if (!fieldName) throw 'DTO Mapping -> field name does not exist'

      if (skipFields && skipFields.includes(fieldName)) return

      let placeholder: string | undefined = undefined
      let showIf: { customerType: 'Business' } | undefined = undefined
      let genderType: 'GENDER3' | undefined = undefined
      let defaultValue = undefined
      let options = undefined

      const label = dto.labels?.[fieldName]
      const errorMessage = dto.errorMessages?.[fieldName]
      const required = dto.requiredFields?.[fieldName]

      if (
        fieldName !== 'salutation' &&
        fieldName !== 'title' &&
        fieldName !== 'agreement'
      ) {
        placeholder = dto.placeHolders ? dto.placeHolders[fieldName] : undefined
      }

      if (
        fieldName === 'customerType' ||
        fieldName === 'companyName' ||
        fieldName === 'registryCourt' ||
        fieldName === 'registerNumber'
      ) {
        showIf =
          dto.showCondition?.[fieldName] &&
          dto.showCondition[fieldName] !== 'Always'
            ? { customerType: 'Business' }
            : undefined
      }

      if (
        fieldName === 'salutation' &&
        dto.salutationType === 'Mr/Mrs/Diverse'
      ) {
        genderType = 'GENDER3'
      }

      // check if there is a custom default selection, or custom labels
      if (fieldName === 'customerType') {
        if (dto.defaultCustomerTypeAndLabelOptions) {
          if (dto.defaultCustomerTypeAndLabelOptions.defaultCustomerSelection) {
            defaultValue =
              dto.defaultCustomerTypeAndLabelOptions.defaultCustomerSelection
          }
          if (dto.defaultCustomerTypeAndLabelOptions.customerTypeLabelOptions) {
            const { customerTypeLabelOptions } =
              dto.defaultCustomerTypeAndLabelOptions

            if (
              customerTypeLabelOptions.businessLabel ||
              customerTypeLabelOptions.privateLabel
            ) {
              options = [
                {
                  value: 'Private',
                  label: customerTypeLabelOptions.privateLabel || t('Private')
                },
                {
                  value: 'Business',
                  label: customerTypeLabelOptions.businessLabel || t('Business')
                }
              ]
            }
          }
        }
      }

      Object.assign(fields, {
        [fieldName]: {
          ...(label && { label }),
          ...(errorMessage && { errorMessage }),
          ...(placeholder && { placeholder }),
          ...(required && { required }),
          ...(showIf && { showIf }),
          ...(genderType && { genderType }),
          ...(defaultValue && { defaultValue }),
          ...(options && { options })
        }
      })
    })
  } catch (error) {
    // if any error in mapping, return it here
    if (error instanceof Error) {
      return { error: error.message }
    } else {
      return { error }
    }
  }

  return { fields }
}
