import * as React from 'react'

function SvgHome(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M23.452 9.907L12.956.271a1.196 1.196 0 00-1.517 0L.943 9.907c-.467.482-.583 1.205-.117 1.687.234.24.583.36.817.36h1.166v10.841c0 .723.466 1.205 1.166 1.205h16.328c.7 0 1.166-.482 1.166-1.204V11.955h1.167c.7 0 1.166-.482 1.166-1.205 0-.361-.117-.602-.35-.843zm-7.814 11.684H8.64v-3.614c0-2.047 1.516-3.613 3.5-3.613 1.982 0 3.498 1.566 3.498 3.613v3.614z" />
    </svg>
  )
}

export default SvgHome
