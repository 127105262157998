import * as React from 'react'

function SvgInvoice(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 178 148"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#invoice_svg__clip0)">
        <path
          d="M85.304 24.12L34.268 37.831l18.488 65.733 51.036-13.71L85.304 24.12z"
          fill="#F9FAFB"
        />
        <path
          d="M85.344 24.042L34.19 37.784l7.047 25.054 51.154-13.743-7.047-25.053z"
          fill="#E5EBEE"
        />
        <path
          d="M69.025 67.427l-13.877 3.728.291 1.034 13.877-3.728-.291-1.034z"
          fill="#F5F5F5"
        />
        <path
          d="M70.75 38.037l-13.876 3.728.29 1.034 13.877-3.728-.29-1.034zM79.864 38.06L57.52 44.062l.291 1.035 22.344-6.003-.291-1.034z"
          fill="#fff"
        />
        <path
          d="M75.974 42.32l-12.88 3.461a.503.503 0 00-.361.62l.05.177c.075.268.359.426.633.352l12.882-3.46a.503.503 0 00.36-.62l-.05-.177a.518.518 0 00-.634-.352z"
          fill="#C9D5DB"
        />
        <path
          d="M49.066 72.233c1.364-.367 2.166-1.745 1.79-3.078-.374-1.334-1.784-2.117-3.148-1.75-1.365.366-2.167 1.744-1.792 3.077.375 1.333 1.785 2.117 3.15 1.75z"
          fill="#69F0AE"
        />
        <path
          d="M70.675 73.287l-13.876 3.728.29 1.034 13.877-3.728-.291-1.034z"
          fill="#BDBDBD"
        />
        <path
          d="M71.837 77.426l-13.876 3.728.29 1.034 13.877-3.728-.29-1.034z"
          fill="#F5F5F5"
        />
        <path
          d="M93.6 69.355l-36.22 9.73.29 1.034 36.22-9.73-.29-1.034zM51.877 82.232c1.364-.366 2.166-1.744 1.791-3.078-.375-1.333-1.785-2.117-3.149-1.75-1.364.366-2.166 1.744-1.791 3.078.375 1.333 1.785 2.117 3.15 1.75z"
          fill="#E0E0E0"
        />
        <path
          d="M73.487 83.285L59.61 87.013l.291 1.034 13.876-3.728-.29-1.034z"
          fill="#BDBDBD"
        />
        <path
          d="M74.65 87.423L60.773 91.15l.291 1.034 13.877-3.727-.291-1.034z"
          fill="#F5F5F5"
        />
        <path
          d="M96.41 79.352l-36.219 9.73.291 1.034 36.22-9.73-.291-1.034zM54.69 92.23c1.365-.366 2.167-1.744 1.792-3.078-.375-1.333-1.785-2.117-3.15-1.75-1.364.366-2.166 1.744-1.79 3.078.374 1.333 1.784 2.117 3.149 1.75z"
          fill="#E0E0E0"
        />
        <g filter="url(#invoice_svg__filter0_d)">
          <path
            d="M140.3 42.752l-52.804-3.441-4.107 68.066 52.804 3.441 4.107-68.066z"
            fill="#F9FAFB"
          />
        </g>
        <path
          d="M140.364 42.694l-52.925-3.45-1.566 25.943 52.926 3.45 1.565-25.943z"
          fill="#E5EBEE"
        />
        <path
          d="M118.79 76.33l-14.311 1.483.116 1.066 14.311-1.482-.116-1.066z"
          fill="#BDBDBD"
        />
        <path
          d="M119.252 80.596l-14.311 1.483.116 1.066 14.311-1.482-.116-1.067z"
          fill="#F5F5F5"
        />
        <path
          d="M134.064 75.95l-37.546.717.025 1.072 37.547-.717-.026-1.073z"
          fill="#EFF2F3"
        />
        <path
          d="M121.955 51.231l-14.357-.935-.065 1.07 14.357.936.065-1.07zM130.57 54.183l-23.117-1.507-.064 1.071 23.117 1.507.064-1.071z"
          fill="#fff"
        />
        <path
          d="M125.489 56.956l-13.328-.868a.507.507 0 00-.545.47l-.011.182c-.017.278.2.519.485.537l13.327.869a.507.507 0 00.545-.47l.011-.183a.513.513 0 00-.484-.537z"
          fill="#C9D5DB"
        />
        <path
          d="M98.76 82.18c1.406-.146 2.426-1.379 2.277-2.754-.149-1.375-1.41-2.371-2.818-2.225-1.406.145-2.426 1.378-2.277 2.753.149 1.375 1.41 2.372 2.818 2.226z"
          fill="#69F0AE"
        />
        <path
          d="M119.909 86.642l-14.31 1.483.115 1.066 14.311-1.483-.116-1.066z"
          fill="#BDBDBD"
        />
        <path
          d="M120.372 90.907l-14.31 1.483.115 1.066 14.311-1.482-.116-1.067z"
          fill="#F5F5F5"
        />
        <path
          d="M134.313 86.319l-37.546.717.025 1.073 37.547-.718-.026-1.072z"
          fill="#EFF2F3"
        />
        <path
          d="M99.879 92.492c1.407-.146 2.426-1.379 2.277-2.754-.149-1.375-1.41-2.372-2.817-2.226-1.407.146-2.427 1.379-2.278 2.754.15 1.375 1.41 2.371 2.818 2.226z"
          fill="#E0E0E0"
        />
        <path
          d="M121.027 96.951l-14.311 1.483.115 1.066 14.311-1.482-.115-1.067z"
          fill="#BDBDBD"
        />
        <path
          d="M121.489 101.22l-14.31 1.482.115 1.067 14.311-1.483-.116-1.066z"
          fill="#F5F5F5"
        />
        <path
          d="M134.56 96.688l-37.546.717.025 1.073 37.547-.718-.026-1.072z"
          fill="#EFF2F3"
        />
        <path
          d="M100.996 102.803c1.407-.146 2.427-1.379 2.277-2.754-.149-1.375-1.41-2.372-2.817-2.226-1.407.146-2.427 1.379-2.278 2.754.15 1.375 1.41 2.371 2.818 2.226z"
          fill="#E0E0E0"
        />
        <path
          d="M93.568 58.02a7.047 7.047 0 001.462 3.53 7.132 7.132 0 003.097 2.264 7.185 7.185 0 007.284-1.345 7.069 7.069 0 002.068-3.217c.38-1.24.409-2.56.085-3.816a7.073 7.073 0 00-1.925-3.304 7.153 7.153 0 00-3.375-1.832l.216 1.811c.071.629-.11 1.26-.506 1.757a2.425 2.425 0 01-1.604.896 2.438 2.438 0 01-1.778-.5 2.4 2.4 0 01-.905-1.599l-.216-1.811a7.113 7.113 0 00-3.095 2.97 7.034 7.034 0 00-.808 4.197z"
          fill="url(#invoice_svg__paint0_linear)"
        />
        <path
          d="M107.746 55.904a6.875 6.875 0 00-1.498-3.55 7.485 7.485 0 00-3.232-2.3 7.929 7.929 0 00-4.028-.383 7.706 7.706 0 00-3.653 1.646 7.068 7.068 0 00-2.217 3.197 6.706 6.706 0 00-.136 3.819 6.994 6.994 0 001.983 3.33 7.636 7.636 0 003.528 1.875l-.204-1.815c-.07-.633.13-1.266.553-1.76a2.595 2.595 0 011.708-.88 2.657 2.657 0 011.865.522c.524.4.86.979.933 1.611l.204 1.816a7.353 7.353 0 003.291-2.938 6.77 6.77 0 00.903-4.19z"
          fill="#6C63FF"
        />
        <path
          d="M100.681 58.294c1.49-.154 2.571-1.46 2.413-2.917-.158-1.456-1.495-2.512-2.985-2.358-1.49.155-2.57 1.46-2.413 2.917.158 1.457 1.495 2.513 2.985 2.358zM101.043 59.136a2.634 2.634 0 00-1.702.831 2.112 2.112 0 00-.551 1.663l.203 1.715a8.316 8.316 0 005.04-.475l-.203-1.715c-.072-.598-.406-1.146-.928-1.524a2.752 2.752 0 00-1.859-.495z"
          fill="#fff"
          stroke="#fff"
          strokeMiterlimit={10}
        />
        <path
          d="M97.702 55.448a2.636 2.636 0 01-.427-1.322 1.826 1.826 0 01.462-1.288c.352-.376.867-.55 1.371-.645.505-.095 1.018-.118 1.512-.284a3.194 3.194 0 001.105-.614c.324-.276.591-.614.785-.995.091.387.129.785.113 1.183a1.464 1.464 0 01-.504 1.043c.384-.233.387.214.33.49.225.223.717.211.776.525.075.31.075.634 0 .944-.092.304.094.805-.217.86"
          fill="#fff"
        />
        <path
          d="M97.702 55.448a2.636 2.636 0 01-.427-1.322 1.826 1.826 0 01.462-1.288c.352-.376.867-.55 1.371-.645.505-.095 1.018-.118 1.512-.284a3.194 3.194 0 001.105-.614c.324-.276.591-.614.785-.995.091.387.129.785.113 1.183a1.464 1.464 0 01-.504 1.043c.384-.233.387.214.33.49.225.223.717.211.776.525.075.31.075.634 0 .944-.092.304.094.805-.217.86"
          stroke="#fff"
          strokeMiterlimit={10}
        />
        <path d="M102.531 53.242s2.44-.027 1.1 2.206l-1.1-2.206z" fill="#fff" />
        <g filter="url(#invoice_svg__filter1_d)">
          <path d="M117.559 15.735h-52.57v67.658h52.57V15.735z" fill="#fff" />
        </g>
        <path d="M118.31 15.735H64.99v25.74h53.32v-25.74z" fill="#E5EBEE" />
        <path d="M90.523 48.83h-14.27v.735h14.27v-.736z" fill="#D2DEE4" />
        <path d="M90.523 53.241h-14.27v1.471h14.27v-1.47z" fill="#F5F5F5" />
        <path d="M113.803 51.035h-37.55v.736h37.55v-.736z" fill="#EFF2F3" />
        <path
          d="M99.534 25.296H86.017v.735h13.517v-.735zM108.546 27.502h-22.53v.735h22.53v-.735z"
          fill="#fff"
        />
        <path
          d="M103.529 30.443H90.283c-.282 0-.511.28-.511.623v.226c0 .343.229.622.511.622h13.246c.282 0 .511-.279.511-.622v-.226c0-.344-.229-.623-.511-.623z"
          fill="#C9D5DB"
        />
        <path
          d="M69.87 52.505c1.452 0 2.63-1.152 2.63-2.574 0-1.421-1.178-2.574-2.63-2.574-1.451 0-2.628 1.153-2.628 2.574 0 1.422 1.177 2.574 2.629 2.574z"
          fill="#EFF2F3"
        />
        <path d="M90.523 59.124h-14.27v.735h14.27v-.735z" fill="#D2DEE4" />
        <path d="M96.523 63.538h-14.27v1.47h14.27v-1.47z" fill="#F5F5F5" />
        <path
          d="M113.803 61.331h-37.55v.735h37.55v-.735zM69.87 62.802c1.452 0 2.63-1.152 2.63-2.574 0-1.421-1.178-2.574-2.63-2.574-1.451 0-2.628 1.153-2.628 2.574 0 1.422 1.177 2.574 2.629 2.574z"
          fill="#EFF2F3"
        />
        <path d="M96.523 69.42h-14.27v1.471h14.27v-1.47z" fill="#BDBDBD" />
        <path d="M96.523 73.833h-14.27v1.47h14.27v-1.47z" fill="#F5F5F5" />
        <path
          d="M119.803 71.627h-37.55v.735h37.55v-.735zM75.87 73.097c1.452 0 2.63-.987 2.63-2.206 0-1.218-1.178-2.206-2.63-2.206-1.451 0-2.628.988-2.628 2.206 0 1.219 1.177 2.206 2.629 2.206z"
          fill="#E0E0E0"
        />
        <path d="M37 64v69.934h101.267V64h-73.36" fill="#E5EBEE" />
        <path d="M37.84 62.338v69.935h101.268V62.338h-73.36" fill="#F9FAFB" />
        <path d="M65.772 63.3l-11.704-7.894H37.865V63.3" fill="#C9D5DB" />
        <path d="M99.365 106.395H77.932v1.715h21.433v-1.715z" fill="#D2DEE4" />
        <path
          d="M111.687 109.827H64.852v1.716h46.835v-1.716zM111.687 113.259H64.852v1.716h46.835v-1.716z"
          fill="#E5EBEE"
        />
        <path
          d="M70.778 28.145l2.908 2.7 6.139-5.7"
          stroke="#C9D5DB"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <path
          d="M89.283 99.257c-2.012-.008-3.77-.549-5.275-1.622-1.504-1.073-2.514-2.518-3.03-4.336h-1.315v-1.67h.99a8.624 8.624 0 01-.024-.72c0-.297.016-.657.048-1.082h-1.014v-1.67h1.34c.531-1.785 1.545-3.21 3.042-4.275 1.496-1.066 3.242-1.602 5.238-1.61 2.897 0 5.098 1.225 6.602 3.676l-2.45 1.273a4.755 4.755 0 00-1.738-1.718c-.748-.448-1.63-.672-2.643-.672-1.014 0-1.98.3-2.897.9a5.423 5.423 0 00-2.04 2.427h6.626v1.67h-7.097c-.032.424-.048.784-.048 1.08 0 .297.016.537.048.721h7.097v1.67h-6.65c.442 1.049 1.122 1.878 2.04 2.486.925.609 1.895.913 2.909.913 1.013 0 1.899-.224 2.655-.672.756-.449 1.336-1.021 1.738-1.718l2.474 1.225c-1.585 2.483-3.794 3.724-6.626 3.724z"
          fill="#C9D5DB"
        />
        <path
          d="M28.822 120.163v2.722M30.213 121.526h-2.781"
          stroke="#EFF2F3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          clipRule="evenodd"
          d="M154.6 60.595l.001.008c.166 1.277 1.196 2.282 2.502 2.441-1.306.16-2.336 1.166-2.502 2.445l-.001.005c-.163-1.28-1.193-2.29-2.502-2.45h-.001c1.309-.158 2.34-1.167 2.503-2.448v-.001zM76.503 5l.001.008C76.67 6.286 77.7 7.29 79.007 7.45c-1.307.16-2.337 1.166-2.503 2.444v.005C76.34 8.62 75.31 7.61 74 7.45H74c1.309-.158 2.34-1.167 2.503-2.448V5z"
          fill="#E5EBEE"
          fillRule="evenodd"
          stroke="#E5EBEE"
          strokeLinejoin="round"
        />
        <path
          clipRule="evenodd"
          d="M170.287 39.269l.001.005a1.9 1.9 0 001.668 1.628 1.904 1.904 0 00-1.669 1.63v.003a1.902 1.902 0 00-1.668-1.633h-.001a1.9 1.9 0 001.669-1.632v-.001z"
          fill="#EFF2F3"
          fillRule="evenodd"
          stroke="#EFF2F3"
          strokeLinejoin="round"
        />
        <path
          d="M143.334 17.942v4.9M145.838 20.391h-5.007"
          stroke="#EFF2F3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M156.686 46.05a.825.825 0 00.834-.817.825.825 0 00-.834-.817.825.825 0 00-.834.817c0 .45.373.816.834.816zM141.834 101.633a.826.826 0 00.835-.816.826.826 0 00-.835-.817.826.826 0 00-.834.817c0 .45.374.816.834.816zM19.254 99.734c.461 0 .835-.365.835-.816a.826.826 0 00-.835-.817.826.826 0 00-.834.817c0 .45.373.816.834.816zM134.777 45.454c.342 0 .619-.225.619-.503s-.277-.504-.619-.504c-.342 0-.619.226-.619.504s.277.503.619.503zM30.464 40.423c.922 0 1.669-.731 1.669-1.633s-.748-1.633-1.67-1.633c-.92 0-1.668.73-1.668 1.633 0 .902.747 1.633 1.669 1.633z"
          fill="#EFF2F3"
        />
        <path
          d="M36.304 16.472v4.899M38.807 18.92h-5.006M149.503 114v4.899M152.007 116.449H147M17.39 46.957v2.722M18.781 48.32H16"
          stroke="#EFF2F3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          clipRule="evenodd"
          d="M26.291 70.907l.001.008c.166 1.278 1.196 2.283 2.503 2.442-1.307.16-2.337 1.166-2.503 2.444v.005c-.164-1.28-1.194-2.289-2.502-2.449h-.002c1.309-.159 2.34-1.168 2.503-2.448v-.002z"
          fill="#E5EBEE"
          fillRule="evenodd"
          stroke="#E5EBEE"
          strokeLinejoin="round"
        />
        <path
          d="M165.781 94.749c.922 0 1.669-.731 1.669-1.633s-.747-1.633-1.669-1.633-1.669.731-1.669 1.633.747 1.633 1.669 1.633z"
          fill="#EFF2F3"
        />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height={85.507}
          id="invoice_svg__filter0_d"
          width={70.911}
          x={76.389}
          y={34.311}
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={3.5} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height={75.658}
          id="invoice_svg__filter1_d"
          width={60.569}
          x={58.989}
          y={13.735}
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx={-2} dy={2} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="invoice_svg__paint0_linear"
          x1={-9116.28}
          x2={-9297.71}
          y1={-3478.9}
          y2={-4619.56}
        >
          <stop stopOpacity={0.12} />
          <stop offset={0.55} stopOpacity={0.09} />
          <stop offset={1} stopOpacity={0.02} />
        </linearGradient>
        <clipPath id="invoice_svg__clip0">
          <path d="M0 0h178v148H0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgInvoice
