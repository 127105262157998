import { Hidden } from '@epilot/base-elements'
import { rankWith, isEnumControl, and, optionIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'

import { EpilotControlProps } from '../../..'
import { includeCommon } from '../../../utils/includeCommon'

import { EnumButton } from './components/EnumButton'
import {
  isEnumButtonControlData,
  isEnumButtonControlOptions,
  isEnumOptions
} from './types.guard'

function EnumButtonControl({
  data,
  handleChange,
  path,
  uischema,
  errors,
  schema,
  visible,
  required
}: EpilotControlProps) {
  const { enum: options } = schema
  const { label } = uischema || {}
  const hasError = !!errors

  if (!isEnumOptions(options)) {
    console.error(
      'EnumButtonControl -> Invalid enum values detected. Passed values are:',
      { options }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  if (!isEnumButtonControlOptions(uischema.options)) {
    console.error(
      'EnumButtonControl -> Invalid uischema.options detected. Passed options are:',
      { options: uischema.options }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  if (!isEnumButtonControlData(data)) {
    console.error(
      'EnumButtonControl -> Invalid data detected. Current data is:',
      { data }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  const optionsLabels = uischema.options.optionsLabels

  const items = options
    ? optionsLabels
      ? options.map((option, index) => ({
          value: option,
          label: optionsLabels[index]
        }))
      : options
    : []

  return (
    <Hidden xsUp={!visible}>
      <EnumButton
        data={data}
        handleChange={handleChange}
        hasError={hasError}
        isRequired={!!required}
        label={typeof label === 'string' ? label : ''}
        options={items}
        path={path}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({
    component: EnumButtonControl
  }) as never
)

export const EnumButtonControlTester = rankWith(
  4,
  and(isEnumControl, optionIs('button', true))
)
