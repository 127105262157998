import { Hidden } from '@epilot/base-elements'
import { rankWith, and, schemaTypeIs, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'

import { EpilotControlProps } from '../../..'
import { CONTROL_NAME } from '../../../utils'
import { includeCommon } from '../../../utils/includeCommon'

import { NumberInputForm } from './components/NumberInputForm'
import { NumberInputControlData } from './types'
import {
  isNumberInputControlData,
  isNumberInputControlOptions
} from './types.guard'
import { convertNumberInputData, parseNumberInputData } from './utils'

function NumberInputControl({
  data,
  handleChange,
  path,
  uischema,
  errors,
  visible,
  required
}: EpilotControlProps) {
  const options = uischema.options
  const hasError = !!errors

  if (!isNumberInputControlOptions(options)) {
    console.error(
      'NumberInputControl -> Invalid uischema.options detected. Passed options are:',
      { options }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  if (!isNumberInputControlData(data)) {
    console.error(
      'NumberInputControl -> Invalid data detected. Current data is:',
      {
        data
      }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  const handleFormChange = (
    fieldPath: string,
    data: NumberInputControlData
  ) => {
    handleChange(fieldPath, convertNumberInputData(data, options))
  }

  return (
    <Hidden xsUp={!visible}>
      <NumberInputForm
        data={parseNumberInputData(data, options)}
        handleChange={handleFormChange}
        hasError={hasError}
        isRequired={!!required}
        path={path}
        {...options}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({
    component: NumberInputControl
  }) as never
)

export const NumberInputControlTester = rankWith(
  2,
  and(schemaTypeIs('object'), uiTypeIs(CONTROL_NAME.NUMBER_INPUT_CONTROL))
)
