import { Hidden } from '@epilot/base-elements'
import { EnumControl, EnumControlProps } from '@epilot/base-modules'
import { rankWith, isEnumControl, and, optionIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'

import { EpilotControlProps, stringToHTML } from '../../utils'
import { includeCommon } from '../../utils/includeCommon'

export type EnumRadioControlOptions = EnumControlProps & {
  clearPathOnChange?: string[]
  optionsValue?: string[]
}

function EnumRadioControl({
  data,
  handleChange,
  path,
  uischema,
  errors,
  enabled,
  required,
  schema,
  visible
}: EpilotControlProps) {
  const { enum: options = [], errorMessage, examples } = schema
  const { label } = uischema || {}
  const {
    optionsLabels = options,
    clearPathOnChange = [],
    optionsValue
  } = (uischema.options as EnumRadioControlOptions) || {}

  const placeholder =
    Array.isArray(examples) && examples[0] ? examples[0] : label

  const handleOnChange = (value: string) => {
    if (clearPathOnChange && clearPathOnChange.length > 0) {
      clearPathOnChange.forEach((path) => {
        handleChange(path, undefined)
      })
    }
    handleChange(path, value)
  }

  const newLabels = optionsLabels.map((item) =>
    stringToHTML(item, {
      isPlainText: true // for now the enum radio control is only used for plain text options
    })
  )

  return (
    <Hidden xsUp={!visible}>
      <EnumControl
        defaultSelection={''}
        disabled={!enabled}
        errorMessage={
          errors
            ? errorMessage && typeof errorMessage
              ? errorMessage
              : errors
            : ''
        }
        label={label as string}
        onChange={handleOnChange}
        options={optionsValue ? optionsValue : options}
        optionsLabels={newLabels}
        placeholder={placeholder}
        required={required}
        uiType="radio"
        value={data || ''}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: EnumRadioControl }) as never
)

export const RadioTester = rankWith(
  5,
  and(isEnumControl, optionIs('radio', true))
)
