import { Button } from '@material-ui/core'

import styled from '../styled'
import { getPrefix } from '../utils'

const ButtonStyled = styled(Button)`
  &.${getPrefix}-MuiButton-containedPrimary {
    box-shadow: none;
  }
  &.${getPrefix}-MuiButton-containedPrimary:hover {
    background-color: ${(props) => props.theme.palette?.primary};
    box-shadow: none;
    opacity: 0.7;
  }
  &.${getPrefix}-MuiButton-containedPrimary:active {
    background-color: ${(props) => props.theme.palette?.primary};
    opacity: 0.7;
  }

  &.${getPrefix}-MuiButton-outlinedSecondary {
    color: ${(props) => props.theme.typography?.main_color};
    border: 1px solid ${(props) => props.theme.palette?.disable};
  }
  &.${getPrefix}-MuiButton-outlinedSecondary:hover {
    background-color: ${(props) => props.theme.palette?.disable};
    border-color: ${(props) => props.theme.palette?.disable};
  }
  &.${getPrefix}-MuiButton-outlinedSecondary:active {
    background-color: ${(props) => props.theme.palette?.disable};
    border-color: ${(props) => props.theme.palette?.disable};
  }

  &.${getPrefix}-MuiButton-root {
    padding: 4px 16px;
    font-weight: ${(props) => props.theme.typography?.font_weight_bold};
    text-transform: none;
  }
`

const ArrowButtonStyled = styled(Button)`
  &.${getPrefix}-MuiButton-root {
    width: 24px;
    min-width: 24px;
    height: 24px;
    padding: 0px !important;
    border-radius: 50%;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  }
  &.${getPrefix}-MuiButton-root:hover {
    background-color: #eff2f3;
  }
  &.${getPrefix}-MuiButton-root:active {
    background-color: #d2dee4;
  }
  .${getPrefix}-MuiButton-label > svg {
    width: 12px;
    height: 12px;
  }
`

export { ButtonStyled, ArrowButtonStyled }
