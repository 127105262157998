import {
  Hidden,
  List,
  ListItem,
  ListItemContent,
  ListItemPrefix,
  ListProps,
  Typography
} from '@epilot/base-elements'
import { LayoutProps, Layout, rankWith, uiTypeIs, or } from '@jsonforms/core'
import { withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'

export type ListLayoutProps = Layout & {
  items: {
    head: string
    content: string
  }[]
}
export type ListLayoutOptions = ListProps & {
  headMinWidth?: number
}

const ListLayout = ({ uischema, visible }: LayoutProps) => {
  const layout = uischema as ListLayoutProps
  const { headMinWidth = 150, ...rest } =
    (uischema.options as ListLayoutOptions) || {}

  return (
    <Hidden xsUp={!visible}>
      <List {...rest}>
        {layout.items.map((child, index) => (
          <ListItem key={index}>
            <ListItemPrefix style={{ minWidth: headMinWidth }}>
              <Typography style={{ whiteSpace: 'pre-wrap' }} variant="body1">
                {child.head}
              </Typography>
            </ListItemPrefix>
            <ListItemContent>
              <Typography style={{ whiteSpace: 'pre-wrap' }} variant="body1">
                {child.content}
              </Typography>
            </ListItemContent>
          </ListItem>
        ))}
      </List>
    </Hidden>
  )
}

export const ListLayoutTester = rankWith(2, or(uiTypeIs('ListLayout')))

export default withJsonFormsLayoutProps(ListLayout)
