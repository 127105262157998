import { StreetSuggestion as StreetSuggestionDto } from '@epilot/base-modules'
import { UseControllerProps } from 'react-hook-form'

import { FieldOption, LayoutOptions } from '../../../components/types'
import { AddressControlOptions as AddressControlOptionsLegacy } from '../AddressControl'
import { Contact, ContactControlOptions } from '../ContactControl/types'

export type Address = {
  zipCode?: string
  city?: string
  streetName?: string
  houseNumber?: string
  extention?: string
  countryCode: string
} & Contact

/** @see {isAddressControlData} ts-auto-guard:type-guard */
export type AddressControlData = Address | undefined | null

export type AddressControlOptionsDto =
  | AddressControlOptions
  | AddressControlOptionsLegacy

// applyOptions()
/** @see {isAddressControlOptions} ts-auto-guard:type-guard */
export type AddressControlOptions = {
  label?: string
  acceptSuggestedOnly?: boolean
  fields: {
    zipCity?: FieldOption & { layout?: LayoutOptions }
    streetName?: FieldOption & { layout?: LayoutOptions }
    houseNumber?: FieldOption & { layout?: LayoutOptions }
    extention?: FieldOption & { layout?: LayoutOptions }
  } & ContactControlOptions['fields']
  countryAddressSettings?: CountryAddressSettings // for now only used by old P&G Journeys
}

export type CountryAddressSettings = {
  countryCode?: string
  enableAutoComplete?: boolean
  enableFreeText?: boolean
}

export type AddressFormProps = AddressControlOptions & {
  path: string
  data: AddressControlData
  hasError: boolean
  isRequired: boolean
  handleChange: (path: string, value: Address | undefined | null) => void
}

export type ZipCitySuggestion = {
  postal_code: string
  city: string
  uuid?: string
}

/** @see {isZipCitySuggestionData} ts-auto-guard:type-guard */
export type ZipCitySuggestionData = ZipCitySuggestion[]

/** @see {isStreetSuggestionData} ts-auto-guard:type-guard */
export type StreetSuggestionData = StreetSuggestionDto[]

export enum FeedBackType {
  STREETS = 'streets',
  POSTAL_CODE_OR_CITY = 'postalcode-cities'
}

export type CustomValidationRules = {
  rules?: UseControllerProps['rules']
  errorMessages?: Record<string, string>
}
