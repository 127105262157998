import { DigitalSignature, DigitalSignatureProps } from '@epilot/base-modules'
import {
  rankWith,
  and,
  or,
  optionIs,
  isStringControl,
  uiTypeIs
} from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'

import { EpilotControlProps } from '../../utils'
import { includeCommon } from '../../utils/includeCommon'

export type DigitalSignatureControlOptions = DigitalSignatureProps

function DigitalSignatureControl({
  handleChange,
  path,
  uischema,
  errors,
  enabled,
  /**
   * @todo required is needed
   */
  //required,
  schema
}: EpilotControlProps) {
  const handleOnChange = (value: string) => {
    handleChange(path, value)
  }

  const { label = '' } = uischema || {}

  const { errorMessage } = schema

  const { ...props } = uischema.options as DigitalSignatureControlOptions

  return (
    <DigitalSignature
      disabled={!enabled}
      error={
        errors
          ? errorMessage && typeof errorMessage
            ? errorMessage
            : errors
          : undefined
      }
      onSave={handleOnChange}
      title={label as string}
      {...props}
    />
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: DigitalSignatureControl }) as never
)

export const DigitalSignatureControlTester = rankWith(
  5,
  or(
    and(isStringControl, optionIs('digital_signature', true)),
    uiTypeIs('DigitalSignatureControl')
  )
)
