/* eslint-disable @typescript-eslint/no-explicit-any */
import { TFunction } from 'i18next'

import { AddressControlOptions as AddressControlOptionsLegacy } from '../AddressControlLegacy'
import { mapToContactOptions } from '../ContactControl'

import { AddressControlOptions, AddressControlOptionsDto } from './types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isLegacyDto = (obj: any): obj is AddressControlOptionsLegacy =>
  obj && obj['fieldsOrder']

const isFieldInMapping = (key: any): key is keyof typeof FIELD_MAPPING => {
  if (typeof key === 'string') {
    if (key in FIELD_MAPPING) return true

    return false
  }

  return false
}

const FIELD_MAPPING = {
  ZIP_CITY: {
    label: 'zipCityLabel' as const,
    placeholder: 'zipCityPlaceholder' as const,
    name: 'zipCity' as const,
    targetName: 'zipCity' as const
  },
  STREET: {
    label: 'streetLabel' as const,
    placeholder: 'streetPlaceholder' as const,
    name: 'streetName' as const,
    targetName: 'streetName' as const
  },
  HOUSE: {
    label: 'housenumberLabel' as const,
    placeholder: 'housenumberPlaceholder' as const,
    name: 'housenumber' as const,
    targetName: 'houseNumber' as const
  },
  EXTENTION: {
    label: 'extentionLabel' as const,
    placeholder: 'extentionPlaceholder' as const,
    name: 'extention' as const,
    targetName: 'extention' as const
  }
}

export const mapToAddressOptions = (
  dto: AddressControlOptionsDto,
  translate?: TFunction
): AddressControlOptions | { error: string } => {
  const fields: AddressControlOptions['fields'] = {}
  const t = translate || ((value: string) => value)

  if (!dto) return { error: 'Incoming DTO is not set' }

  if (!isLegacyDto(dto)) return dto

  const fieldsDto =
    dto.fieldsOrder && Array.isArray(dto.fieldsOrder) ? dto.fieldsOrder : []
  const fieldsProcessed: string[] = []

  try {
    fieldsDto.forEach((item) => {
      const fieldName = item.name

      if (!fieldName) throw 'DTO Mapping -> field name does not exist'

      // do not include unsused fields
      if (!isFieldInMapping(fieldName)) return

      let placeholder: string | undefined = undefined
      const key = FIELD_MAPPING[fieldName]['name']
      const keyLabel = FIELD_MAPPING[fieldName]['label']
      const keyPlaceholder = FIELD_MAPPING[fieldName]['placeholder']

      const label = dto.labels?.[keyLabel]
      const required =
        dto.requiredFields?.[key] || // if key is streetName, check also for key street
        (key === 'streetName' ? dto.requiredFields?.['street'] : undefined)
      const disabled =
        dto.disabledFields?.[key] || // if key is streetName, check also for key street
        (key === 'streetName' ? dto.disabledFields?.['street'] : undefined)

      const layout = item.gridItemProps

      placeholder = dto.placeholders
        ? dto.placeholders[keyPlaceholder]
        : undefined

      Object.assign(fields, {
        [FIELD_MAPPING[fieldName]['targetName']]: {
          ...(label && { label }),
          ...(placeholder && { placeholder }),
          ...(required && { required }),
          ...(disabled && { disabled }),
          ...(layout && { layout })
        }
      })

      // add to processed fields
      fieldsProcessed.push(fieldName)
    })

    // map contact related fields. If error, return, else merge contact fields
    const data = mapToContactOptions(dto, t, fieldsProcessed)

    if ('error' in data) {
      return {
        error: data.error
      }
    }

    Object.assign(fields, { ...data.fields })
  } catch (error) {
    // if any error in mapping, return it here
    if (error instanceof Error) {
      return { error: error.message }
    } else {
      return { error }
    }
  }

  return {
    fields,
    acceptSuggestedOnly: dto.acceptOnlySuggestedAddresses,
    countryAddressSettings: dto.countryAddressSettings
  }
}
