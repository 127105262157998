import * as React from 'react'

function SvgPower(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 16 16"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.698 4.327l-.034-.033a.995.995 0 00-1.375 0l-2.517 2.51-2.617-2.61 2.516-2.512a.99.99 0 000-1.372l-.067-.034a.995.995 0 00-1.375 0L7.712 2.787 6.403 1.481a.995.995 0 00-1.376 0l-.067.067a.99.99 0 000 1.373l8.188 8.17a.995.995 0 001.376 0l.067-.068a.99.99 0 000-1.373l-1.41-1.406 2.517-2.51a.97.97 0 000-1.407zm-11.207-.97l-.101-.101L1.739 5.9c-2.08 2.042-2.282 5.256-.671 7.566l-.738.737a.99.99 0 000 1.372l.067.067a.995.995 0 001.376 0l.738-.736c2.315 1.64 5.536 1.44 7.583-.636l2.651-2.645-.1-.1-8.154-8.17zM1.907 7.54c.134-.234.436-.335.67-.2.236.133.336.435.202.669-.537 1.004-.637 2.176-.335 3.315.067.267-.067.535-.336.602-.033 0-.1.034-.134.034-.201 0-.436-.134-.47-.369-.403-1.372-.268-2.812.403-4.05z"
        fill={props.fill ? props.fill : '#172B4D'}
      />
    </svg>
  )
}

export default SvgPower
