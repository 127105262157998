import { Hidden } from '@epilot/base-elements'
import { schemaTypeIs, rankWith, and, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EpilotControlProps } from '../../../utils'
import { includeCommon } from '../../../utils/includeCommon'

import { AddressForm } from './components/AddressForm'
import { mapToAddressOptions } from './mapToAddressOptions'
import { isAddressControlData, isAddressControlOptions } from './types.guard'

function AddressControl({
  data,
  handleChange,
  path,
  errors,
  uischema,
  required,
  visible
}: EpilotControlProps) {
  const hasError = !!errors

  const { t } = useTranslation()
  // map configurator dto to props the ContactForm can understand easily
  const [addressOptions, setAddressOptions] = useState(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    () => mapToAddressOptions(uischema.options as any, t)
  )

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setAddressOptions(mapToAddressOptions(uischema.options as any))
  }, [uischema.options, t, path])

  if (!isAddressControlOptions(addressOptions)) {
    console.error(
      'AddressControl -> Invalid uischema.options detected. Passed options are:',
      { options: addressOptions }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  if (!isAddressControlData(data)) {
    console.error('AddressControl -> Invalid data detected. Current data is:', {
      data
    })

    return <div>Invalid Block. Check console for more details</div>
  }

  return (
    <Hidden xsUp={!visible}>
      <AddressForm
        data={data}
        handleChange={handleChange}
        hasError={hasError}
        isRequired={!!required}
        path={path}
        {...addressOptions}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: AddressControl }) as never
)

export const AddressControlTester = rankWith(
  5,
  and(schemaTypeIs('object'), uiTypeIs('AddressControl'))
)
