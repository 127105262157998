import { Hidden } from '@epilot/base-elements'
import { ShoppingCartV2, ShoppingCartProps } from '@epilot/base-modules'
import { rankWith, uiTypeIs, LayoutProps, Layout } from '@jsonforms/core'
import {
  withJsonFormsLayoutProps,
  ResolvedJsonFormsDispatch
} from '@jsonforms/react'
import React from 'react'

export type ShoppingCartLayoutOptions = ShoppingCartProps & {}
function ShoppingCartLayout({
  uischema,
  path,
  enabled,
  visible,
  renderers,
  schema
}: LayoutProps) {
  const { options, elements } = (uischema as Layout) || {}
  const { ...props } = options as ShoppingCartLayoutOptions

  return (
    <Hidden xsUp={!visible}>
      <ShoppingCartV2
        {...props}
        bottomComponent={elements.map((e, index) => (
          <ResolvedJsonFormsDispatch
            enabled={enabled}
            key={index}
            path={path}
            renderers={renderers}
            schema={schema}
            uischema={e}
          />
        ))}
      />
    </Hidden>
  )
}

export default withJsonFormsLayoutProps(ShoppingCartLayout as never)

export const ShoppingCartLayoutTester = rankWith(
  2,
  uiTypeIs('ShoppingCartLayout')
)
