import * as React from 'react'

function SvgProductMicroPlant(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="1em"
      viewBox="0 0 1024 1024"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M308.8 20.8c-3.3 3.3-4.8 23.288-4.8 64V144h-59.2c-40.712 0-60.7 1.5-64 4.8-6.964 6.964-6.964 847.436 0 854.4 6.952 6.952 655.448 6.952 662.4 0 6.964-6.964 6.964-847.436 0-854.4-3.3-3.3-23.264-4.8-63.896-4.8h-59.096l-1.104-60.568c-.96-52.596-2.024-61.152-8.104-64.98-5.1-3.216-8.9-3.216-14 0-6.08 3.828-7.144 12.384-8.104 64.98L687.792 144H336V84.8c0-60.424-1.948-68.8-16-68.8-3.52 0-8.56 2.16-11.2 4.8M816 224v48H208v-96h608v48m0 352v272H208V304h608v272m-331.96-74.656c-28.62 38.14-52.04 71.98-52.04 75.2 0 13.14 9.112 15.456 60.852 15.456h51.256l-40.056 53.348c-22.028 29.34-40.052 55.98-40.052 59.2 0 7.988 8.092 15.452 16.76 15.452C488.612 720 592 585.66 592 575.456c0-13.14-9.112-15.456-60.852-15.456h-51.256l40.056-53.348c22.028-29.34 40.052-55.98 40.052-59.2 0-7.988-8.092-15.452-16.76-15.452-4.6 0-25.744 24.768-59.2 69.344M816 928v48H208v-96h608v48" />
    </svg>
  )
}

export default SvgProductMicroPlant
