import { Font } from "types";
export const EpilotFonts: Font[] = [
  {
    name: "ProximaNova-Bold",
    fontUrl:
      "https://go.epilot.cloud/elements-static/fonts/ProximaNova-Bold.woff",
    fontStyle: "normal",
    fontWeight: "700",
    cssUrl: "https://go.epilot.cloud/elements-static/css/ProximaNova-Bold.css",
    formart: "woff",
    cssContent: `@font-face {
    font-family: 'ProximaNova-Bold';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/ProximaNova-Bold.woff');
}`
  },
  {
    name: "ProximaNova-Regular",
    fontUrl:
      "https://go.epilot.cloud/elements-static/fonts/ProximaNova-Regular.woff",
    fontStyle: "normal",
    fontWeight: "400",
    cssUrl:
      "https://go.epilot.cloud/elements-static/css/ProximaNova-Regular.css",
    formart: "woff",
    cssContent: `@font-face {
    font-family: 'ProximaNova-Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/ProximaNova-Regular.woff');
}`
  },
  {
    name: "ProximaNova-Semibold",
    fontUrl:
      "https://go.epilot.cloud/elements-static/fonts/ProximaNova-Semibold.woff",
    fontStyle: "normal",
    fontWeight: "700",
    cssUrl:
      "https://go.epilot.cloud/elements-static/css/ProximaNova-Semibold.css",
    formart: "woff",
    cssContent: `@font-face {
    font-family: 'ProximaNova-Semibold';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/ProximaNova-Semibold.woff');
}`
  },
  {
    name: "Roboto-Black",
    fontUrl: "https://go.epilot.cloud/elements-static/fonts/Roboto-Black.ttf",
    fontStyle: "normal",
    fontWeight: "400",
    cssUrl: "https://go.epilot.cloud/elements-static/css/Roboto-Black.css",
    formart: "ttf",
    cssContent: `@font-face {
    font-family: 'Roboto-Black';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-Black.ttf');
}`
  },
  {
    name: "Roboto-BlackItalic",
    fontUrl:
      "https://go.epilot.cloud/elements-static/fonts/Roboto-BlackItalic.ttf",
    fontStyle: "italic",
    fontWeight: "400",
    cssUrl:
      "https://go.epilot.cloud/elements-static/css/Roboto-BlackItalic.css",
    formart: "ttf",
    cssContent: `@font-face {
    font-family: 'Roboto-BlackItalic';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-BlackItalic.ttf');
}`
  },
  {
    name: "Roboto-Bold",
    fontUrl: "https://go.epilot.cloud/elements-static/fonts/Roboto-Bold.ttf",
    fontStyle: "normal",
    fontWeight: "700",
    cssUrl: "https://go.epilot.cloud/elements-static/css/Roboto-Bold.css",
    formart: "ttf",
    cssContent: `@font-face {
    font-family: 'Roboto-Bold';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-Bold.ttf');
}`
  },
  {
    name: "Roboto-BoldItalic",
    fontUrl:
      "https://go.epilot.cloud/elements-static/fonts/Roboto-BoldItalic.ttf",
    fontStyle: "italic",
    fontWeight: "700",
    cssUrl: "https://go.epilot.cloud/elements-static/css/Roboto-BoldItalic.css",
    formart: "ttf",
    cssContent: `@font-face {
    font-family: 'Roboto-BoldItalic';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-BoldItalic.ttf');
}`
  },
  {
    name: "Roboto-Italic",
    fontUrl: "https://go.epilot.cloud/elements-static/fonts/Roboto-Italic.ttf",
    fontStyle: "italic",
    fontWeight: "400",
    cssUrl: "https://go.epilot.cloud/elements-static/css/Roboto-Italic.css",
    formart: "ttf",
    cssContent: `@font-face {
    font-family: 'Roboto-Italic';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-Italic.ttf');
}`
  },
  {
    name: "Roboto-Light",
    fontUrl: "https://go.epilot.cloud/elements-static/fonts/Roboto-Light.ttf",
    fontStyle: "normal",
    fontWeight: "400",
    cssUrl: "https://go.epilot.cloud/elements-static/css/Roboto-Light.css",
    formart: "ttf",
    cssContent: `@font-face {
    font-family: 'Roboto-Light';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-Light.ttf');
}`
  },
  {
    name: "Roboto-LightItalic",
    fontUrl:
      "https://go.epilot.cloud/elements-static/fonts/Roboto-LightItalic.ttf",
    fontStyle: "italic",
    fontWeight: "400",
    cssUrl:
      "https://go.epilot.cloud/elements-static/css/Roboto-LightItalic.css",
    formart: "ttf",
    cssContent: `@font-face {
    font-family: 'Roboto-LightItalic';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-LightItalic.ttf');
}`
  },
  {
    name: "Roboto-Medium",
    fontUrl: "https://go.epilot.cloud/elements-static/fonts/Roboto-Medium.ttf",
    fontStyle: "normal",
    fontWeight: "400",
    cssUrl: "https://go.epilot.cloud/elements-static/css/Roboto-Medium.css",
    formart: "ttf",
    cssContent: `@font-face {
    font-family: 'Roboto-Medium';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-Medium.ttf');
}`
  },
  {
    name: "Roboto-MediumItalic",
    fontUrl:
      "https://go.epilot.cloud/elements-static/fonts/Roboto-MediumItalic.ttf",
    fontStyle: "italic",
    fontWeight: "400",
    cssUrl:
      "https://go.epilot.cloud/elements-static/css/Roboto-MediumItalic.css",
    formart: "ttf",
    cssContent: `@font-face {
    font-family: 'Roboto-MediumItalic';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-MediumItalic.ttf');
}`
  },
  {
    name: "Roboto-Regular",
    fontUrl: "https://go.epilot.cloud/elements-static/fonts/Roboto-Regular.ttf",
    fontStyle: "normal",
    fontWeight: "400",
    cssUrl: "https://go.epilot.cloud/elements-static/css/Roboto-Regular.css",
    formart: "ttf",
    cssContent: `@font-face {
    font-family: 'Roboto-Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-Regular.ttf');
}`
  },
  {
    name: "Roboto-Thin",
    fontUrl: "https://go.epilot.cloud/elements-static/fonts/Roboto-Thin.ttf",
    fontStyle: "normal",
    fontWeight: "400",
    cssUrl: "https://go.epilot.cloud/elements-static/css/Roboto-Thin.css",
    formart: "ttf",
    cssContent: `@font-face {
    font-family: 'Roboto-Thin';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-Thin.ttf');
}`
  },
  {
    name: "Roboto-ThinItalic",
    fontUrl:
      "https://go.epilot.cloud/elements-static/fonts/Roboto-ThinItalic.ttf",
    fontStyle: "italic",
    fontWeight: "400",
    cssUrl: "https://go.epilot.cloud/elements-static/css/Roboto-ThinItalic.css",
    formart: "ttf",
    cssContent: `@font-face {
    font-family: 'Roboto-ThinItalic';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-ThinItalic.ttf');
}`
  }
];
export const AllFontsCss = `@font-face {
    font-family: 'ProximaNova-Bold';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/ProximaNova-Bold.woff');
}
@font-face {
    font-family: 'ProximaNova-Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/ProximaNova-Regular.woff');
}
@font-face {
    font-family: 'ProximaNova-Semibold';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/ProximaNova-Semibold.woff');
}
@font-face {
    font-family: 'Roboto-Black';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-Black.ttf');
}
@font-face {
    font-family: 'Roboto-BlackItalic';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-BlackItalic.ttf');
}
@font-face {
    font-family: 'Roboto-Bold';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-Bold.ttf');
}
@font-face {
    font-family: 'Roboto-BoldItalic';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-BoldItalic.ttf');
}
@font-face {
    font-family: 'Roboto-Italic';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-Italic.ttf');
}
@font-face {
    font-family: 'Roboto-Light';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-Light.ttf');
}
@font-face {
    font-family: 'Roboto-LightItalic';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-LightItalic.ttf');
}
@font-face {
    font-family: 'Roboto-Medium';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-Medium.ttf');
}
@font-face {
    font-family: 'Roboto-MediumItalic';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-MediumItalic.ttf');
}
@font-face {
    font-family: 'Roboto-Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-Regular.ttf');
}
@font-face {
    font-family: 'Roboto-Thin';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-Thin.ttf');
}
@font-face {
    font-family: 'Roboto-ThinItalic';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('https://go.epilot.cloud/elements-static/fonts/Roboto-ThinItalic.ttf');
}`;
