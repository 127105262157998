import * as React from 'react'

function SvgProductPower(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="1em"
      viewBox="0 0 1024 1024"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M678.912 79.284c-200.208 180.944-428.5 391.828-431.644 398.724-2.792 6.128-2.788 11.42.016 16.656 2.452 4.576 34.116 21.216 78.412 41.2C366.564 554.3 400 571.072 400 573.14c0 2.064-24.396 85.536-54.212 185.492-33.564 112.528-53.236 184.808-51.652 189.8 4.092 12.884 19.6 17.972 31.34 10.28 22.804-14.944 445.028-396.864 450.24-407.264 5.256-10.488 5.012-12.16-3.028-20.712-4.808-5.12-40.228-24.304-78.716-42.636C655.488 469.772 624 452.648 624 450.052s23.4-85.076 52-183.288c28.6-98.212 52-181.184 52-184.38C728 73.436 716.516 64 705.624 64c-5.744 0-16.804 6.328-26.712 15.284m-43.316 211.82c-26.82 92.444-47.676 170.916-46.348 174.384 1.332 3.468 35.02 22.624 74.868 42.568 39.844 19.948 71.868 37.912 71.164 39.92-1.58 4.512-396.788 359.428-398.544 357.916-.696-.6 21.352-76.864 49-169.48C413.38 643.796 436 565.596 436 562.632c0-9.652-12.988-17.456-80-48.072-36.3-16.584-66-31.748-66-33.696 0-4.432 390.36-361.84 392.672-359.524.932.928-20.252 77.324-47.076 169.764" />
    </svg>
  )
}

export default SvgProductPower
