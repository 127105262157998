import { Hidden, Typography, TypographyProps } from '@epilot/base-elements'
import {
  LayoutProps,
  rankWith,
  uiTypeIs,
  or,
  LabelElement
} from '@jsonforms/core'
import { withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'

import { stringToHTML } from '../../utils'

export type LabelRendererOptions = TypographyProps & {}

const LabelRenderer = ({ uischema, visible }: LayoutProps) => {
  const element = uischema as LabelElement
  const {
    color: colorProp,
    variant,
    ...rest
  } = (uischema.options as LabelRendererOptions) || {}

  // determine color. If passed via prop, use it.
  // If not, check if variant is a heading and use textPrimary. Else use default
  const color = colorProp
    ? colorProp
    : variant && ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(variant)
    ? 'textPrimary'
    : undefined

  return (
    <Hidden xsUp={!visible}>
      <Typography color={color} variant={variant} {...rest}>
        {element.text
          ? stringToHTML(element.text, { allowParagraphs: true })
          : ''}
      </Typography>
    </Hidden>
  )
}

export const LabelRendererTester = rankWith(2, or(uiTypeIs('Label')))

export default withJsonFormsLayoutProps(LabelRenderer)
