/*
 * Generated type guards for "types.ts".
 * WARNING: Do not manually change this file.
 */
import {
  AddressControlData,
  AddressControlOptions,
  ZipCitySuggestionData,
  StreetSuggestionData
} from './types'

export function isAddressControlData(
  obj: any,
  _argumentName?: string
): obj is AddressControlData {
  return (
    typeof obj === 'undefined' ||
    obj === null ||
    (((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
      (typeof obj.zipCode === 'undefined' || typeof obj.zipCode === 'string') &&
      (typeof obj.city === 'undefined' || typeof obj.city === 'string') &&
      (typeof obj.streetName === 'undefined' ||
        typeof obj.streetName === 'string') &&
      (typeof obj.houseNumber === 'undefined' ||
        typeof obj.houseNumber === 'string') &&
      (typeof obj.extention === 'undefined' ||
        typeof obj.extention === 'string') &&
      typeof obj.countryCode === 'string') ||
    (((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
      (typeof obj.salutation === 'undefined' ||
        obj.salutation === null ||
        typeof obj.salutation === 'string') &&
      (typeof obj.title === 'undefined' ||
        obj.title === null ||
        typeof obj.title === 'string') &&
      (typeof obj.firstName === 'undefined' ||
        typeof obj.firstName === 'string') &&
      (typeof obj.lastName === 'undefined' ||
        typeof obj.lastName === 'string') &&
      (typeof obj.birthDate === 'undefined' ||
        obj.birthDate === null ||
        obj.birthDate instanceof Date) &&
      (typeof obj.email === 'undefined' || typeof obj.email === 'string') &&
      (typeof obj.telephone === 'undefined' ||
        typeof obj.telephone === 'string') &&
      (typeof obj.customerType === 'undefined' ||
        obj.customerType === 'Private' ||
        obj.customerType === 'Business') &&
      (typeof obj.companyName === 'undefined' ||
        typeof obj.companyName === 'string') &&
      (typeof obj.registryCourt === 'undefined' ||
        typeof obj.registryCourt === 'string') &&
      (typeof obj.registerNumber === 'undefined' ||
        typeof obj.registerNumber === 'string'))
  )
}

export function isAddressControlOptions(
  obj: any,
  _argumentName?: string
): obj is AddressControlOptions {
  return (
    ((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
    (typeof obj.label === 'undefined' || typeof obj.label === 'string') &&
    (typeof obj.acceptSuggestedOnly === 'undefined' ||
      obj.acceptSuggestedOnly === false ||
      obj.acceptSuggestedOnly === true) &&
    ((((obj.fields !== null && typeof obj.fields === 'object') ||
      typeof obj.fields === 'function') &&
      (typeof obj.fields.zipCity === 'undefined' ||
        (((obj.fields.zipCity !== null &&
          typeof obj.fields.zipCity === 'object') ||
          typeof obj.fields.zipCity === 'function') &&
          (typeof obj.fields.zipCity.label === 'undefined' ||
            typeof obj.fields.zipCity.label === 'string') &&
          (typeof obj.fields.zipCity.errorMessage === 'undefined' ||
            typeof obj.fields.zipCity.errorMessage === 'string') &&
          (typeof obj.fields.zipCity.placeholder === 'undefined' ||
            typeof obj.fields.zipCity.placeholder === 'string') &&
          (typeof obj.fields.zipCity.required === 'undefined' ||
            obj.fields.zipCity.required === false ||
            obj.fields.zipCity.required === true) &&
          (typeof obj.fields.zipCity.disabled === 'undefined' ||
            obj.fields.zipCity.disabled === false ||
            obj.fields.zipCity.disabled === true) &&
          (typeof obj.fields.zipCity.defaultValue === 'undefined' ||
            typeof obj.fields.zipCity.defaultValue === 'string')) ||
        (((obj.fields.zipCity !== null &&
          typeof obj.fields.zipCity === 'object') ||
          typeof obj.fields.zipCity === 'function') &&
          (typeof obj.fields.zipCity.layout === 'undefined' ||
            (((obj.fields.zipCity.layout !== null &&
              typeof obj.fields.zipCity.layout === 'object') ||
              typeof obj.fields.zipCity.layout === 'function') &&
              (typeof obj.fields.zipCity.layout.xs === 'undefined' ||
                obj.fields.zipCity.layout.xs === false ||
                obj.fields.zipCity.layout.xs === true ||
                obj.fields.zipCity.layout.xs === 'auto' ||
                obj.fields.zipCity.layout.xs === 1 ||
                obj.fields.zipCity.layout.xs === 2 ||
                obj.fields.zipCity.layout.xs === 3 ||
                obj.fields.zipCity.layout.xs === 4 ||
                obj.fields.zipCity.layout.xs === 5 ||
                obj.fields.zipCity.layout.xs === 6 ||
                obj.fields.zipCity.layout.xs === 7 ||
                obj.fields.zipCity.layout.xs === 8 ||
                obj.fields.zipCity.layout.xs === 9 ||
                obj.fields.zipCity.layout.xs === 10 ||
                obj.fields.zipCity.layout.xs === 11 ||
                obj.fields.zipCity.layout.xs === 12) &&
              (typeof obj.fields.zipCity.layout.sm === 'undefined' ||
                obj.fields.zipCity.layout.sm === false ||
                obj.fields.zipCity.layout.sm === true ||
                obj.fields.zipCity.layout.sm === 'auto' ||
                obj.fields.zipCity.layout.sm === 1 ||
                obj.fields.zipCity.layout.sm === 2 ||
                obj.fields.zipCity.layout.sm === 3 ||
                obj.fields.zipCity.layout.sm === 4 ||
                obj.fields.zipCity.layout.sm === 5 ||
                obj.fields.zipCity.layout.sm === 6 ||
                obj.fields.zipCity.layout.sm === 7 ||
                obj.fields.zipCity.layout.sm === 8 ||
                obj.fields.zipCity.layout.sm === 9 ||
                obj.fields.zipCity.layout.sm === 10 ||
                obj.fields.zipCity.layout.sm === 11 ||
                obj.fields.zipCity.layout.sm === 12) &&
              (typeof obj.fields.zipCity.layout.md === 'undefined' ||
                obj.fields.zipCity.layout.md === false ||
                obj.fields.zipCity.layout.md === true ||
                obj.fields.zipCity.layout.md === 'auto' ||
                obj.fields.zipCity.layout.md === 1 ||
                obj.fields.zipCity.layout.md === 2 ||
                obj.fields.zipCity.layout.md === 3 ||
                obj.fields.zipCity.layout.md === 4 ||
                obj.fields.zipCity.layout.md === 5 ||
                obj.fields.zipCity.layout.md === 6 ||
                obj.fields.zipCity.layout.md === 7 ||
                obj.fields.zipCity.layout.md === 8 ||
                obj.fields.zipCity.layout.md === 9 ||
                obj.fields.zipCity.layout.md === 10 ||
                obj.fields.zipCity.layout.md === 11 ||
                obj.fields.zipCity.layout.md === 12) &&
              (typeof obj.fields.zipCity.layout.lg === 'undefined' ||
                obj.fields.zipCity.layout.lg === false ||
                obj.fields.zipCity.layout.lg === true ||
                obj.fields.zipCity.layout.lg === 'auto' ||
                obj.fields.zipCity.layout.lg === 1 ||
                obj.fields.zipCity.layout.lg === 2 ||
                obj.fields.zipCity.layout.lg === 3 ||
                obj.fields.zipCity.layout.lg === 4 ||
                obj.fields.zipCity.layout.lg === 5 ||
                obj.fields.zipCity.layout.lg === 6 ||
                obj.fields.zipCity.layout.lg === 7 ||
                obj.fields.zipCity.layout.lg === 8 ||
                obj.fields.zipCity.layout.lg === 9 ||
                obj.fields.zipCity.layout.lg === 10 ||
                obj.fields.zipCity.layout.lg === 11 ||
                obj.fields.zipCity.layout.lg === 12) &&
              (typeof obj.fields.zipCity.layout.xl === 'undefined' ||
                obj.fields.zipCity.layout.xl === false ||
                obj.fields.zipCity.layout.xl === true ||
                obj.fields.zipCity.layout.xl === 'auto' ||
                obj.fields.zipCity.layout.xl === 1 ||
                obj.fields.zipCity.layout.xl === 2 ||
                obj.fields.zipCity.layout.xl === 3 ||
                obj.fields.zipCity.layout.xl === 4 ||
                obj.fields.zipCity.layout.xl === 5 ||
                obj.fields.zipCity.layout.xl === 6 ||
                obj.fields.zipCity.layout.xl === 7 ||
                obj.fields.zipCity.layout.xl === 8 ||
                obj.fields.zipCity.layout.xl === 9 ||
                obj.fields.zipCity.layout.xl === 10 ||
                obj.fields.zipCity.layout.xl === 11 ||
                obj.fields.zipCity.layout.xl === 12))))) &&
      (typeof obj.fields.streetName === 'undefined' ||
        (((obj.fields.streetName !== null &&
          typeof obj.fields.streetName === 'object') ||
          typeof obj.fields.streetName === 'function') &&
          (typeof obj.fields.streetName.label === 'undefined' ||
            typeof obj.fields.streetName.label === 'string') &&
          (typeof obj.fields.streetName.errorMessage === 'undefined' ||
            typeof obj.fields.streetName.errorMessage === 'string') &&
          (typeof obj.fields.streetName.placeholder === 'undefined' ||
            typeof obj.fields.streetName.placeholder === 'string') &&
          (typeof obj.fields.streetName.required === 'undefined' ||
            obj.fields.streetName.required === false ||
            obj.fields.streetName.required === true) &&
          (typeof obj.fields.streetName.disabled === 'undefined' ||
            obj.fields.streetName.disabled === false ||
            obj.fields.streetName.disabled === true) &&
          (typeof obj.fields.streetName.defaultValue === 'undefined' ||
            typeof obj.fields.streetName.defaultValue === 'string')) ||
        (((obj.fields.streetName !== null &&
          typeof obj.fields.streetName === 'object') ||
          typeof obj.fields.streetName === 'function') &&
          (typeof obj.fields.streetName.layout === 'undefined' ||
            (((obj.fields.streetName.layout !== null &&
              typeof obj.fields.streetName.layout === 'object') ||
              typeof obj.fields.streetName.layout === 'function') &&
              (typeof obj.fields.streetName.layout.xs === 'undefined' ||
                obj.fields.streetName.layout.xs === false ||
                obj.fields.streetName.layout.xs === true ||
                obj.fields.streetName.layout.xs === 'auto' ||
                obj.fields.streetName.layout.xs === 1 ||
                obj.fields.streetName.layout.xs === 2 ||
                obj.fields.streetName.layout.xs === 3 ||
                obj.fields.streetName.layout.xs === 4 ||
                obj.fields.streetName.layout.xs === 5 ||
                obj.fields.streetName.layout.xs === 6 ||
                obj.fields.streetName.layout.xs === 7 ||
                obj.fields.streetName.layout.xs === 8 ||
                obj.fields.streetName.layout.xs === 9 ||
                obj.fields.streetName.layout.xs === 10 ||
                obj.fields.streetName.layout.xs === 11 ||
                obj.fields.streetName.layout.xs === 12) &&
              (typeof obj.fields.streetName.layout.sm === 'undefined' ||
                obj.fields.streetName.layout.sm === false ||
                obj.fields.streetName.layout.sm === true ||
                obj.fields.streetName.layout.sm === 'auto' ||
                obj.fields.streetName.layout.sm === 1 ||
                obj.fields.streetName.layout.sm === 2 ||
                obj.fields.streetName.layout.sm === 3 ||
                obj.fields.streetName.layout.sm === 4 ||
                obj.fields.streetName.layout.sm === 5 ||
                obj.fields.streetName.layout.sm === 6 ||
                obj.fields.streetName.layout.sm === 7 ||
                obj.fields.streetName.layout.sm === 8 ||
                obj.fields.streetName.layout.sm === 9 ||
                obj.fields.streetName.layout.sm === 10 ||
                obj.fields.streetName.layout.sm === 11 ||
                obj.fields.streetName.layout.sm === 12) &&
              (typeof obj.fields.streetName.layout.md === 'undefined' ||
                obj.fields.streetName.layout.md === false ||
                obj.fields.streetName.layout.md === true ||
                obj.fields.streetName.layout.md === 'auto' ||
                obj.fields.streetName.layout.md === 1 ||
                obj.fields.streetName.layout.md === 2 ||
                obj.fields.streetName.layout.md === 3 ||
                obj.fields.streetName.layout.md === 4 ||
                obj.fields.streetName.layout.md === 5 ||
                obj.fields.streetName.layout.md === 6 ||
                obj.fields.streetName.layout.md === 7 ||
                obj.fields.streetName.layout.md === 8 ||
                obj.fields.streetName.layout.md === 9 ||
                obj.fields.streetName.layout.md === 10 ||
                obj.fields.streetName.layout.md === 11 ||
                obj.fields.streetName.layout.md === 12) &&
              (typeof obj.fields.streetName.layout.lg === 'undefined' ||
                obj.fields.streetName.layout.lg === false ||
                obj.fields.streetName.layout.lg === true ||
                obj.fields.streetName.layout.lg === 'auto' ||
                obj.fields.streetName.layout.lg === 1 ||
                obj.fields.streetName.layout.lg === 2 ||
                obj.fields.streetName.layout.lg === 3 ||
                obj.fields.streetName.layout.lg === 4 ||
                obj.fields.streetName.layout.lg === 5 ||
                obj.fields.streetName.layout.lg === 6 ||
                obj.fields.streetName.layout.lg === 7 ||
                obj.fields.streetName.layout.lg === 8 ||
                obj.fields.streetName.layout.lg === 9 ||
                obj.fields.streetName.layout.lg === 10 ||
                obj.fields.streetName.layout.lg === 11 ||
                obj.fields.streetName.layout.lg === 12) &&
              (typeof obj.fields.streetName.layout.xl === 'undefined' ||
                obj.fields.streetName.layout.xl === false ||
                obj.fields.streetName.layout.xl === true ||
                obj.fields.streetName.layout.xl === 'auto' ||
                obj.fields.streetName.layout.xl === 1 ||
                obj.fields.streetName.layout.xl === 2 ||
                obj.fields.streetName.layout.xl === 3 ||
                obj.fields.streetName.layout.xl === 4 ||
                obj.fields.streetName.layout.xl === 5 ||
                obj.fields.streetName.layout.xl === 6 ||
                obj.fields.streetName.layout.xl === 7 ||
                obj.fields.streetName.layout.xl === 8 ||
                obj.fields.streetName.layout.xl === 9 ||
                obj.fields.streetName.layout.xl === 10 ||
                obj.fields.streetName.layout.xl === 11 ||
                obj.fields.streetName.layout.xl === 12))))) &&
      (typeof obj.fields.houseNumber === 'undefined' ||
        (((obj.fields.houseNumber !== null &&
          typeof obj.fields.houseNumber === 'object') ||
          typeof obj.fields.houseNumber === 'function') &&
          (typeof obj.fields.houseNumber.label === 'undefined' ||
            typeof obj.fields.houseNumber.label === 'string') &&
          (typeof obj.fields.houseNumber.errorMessage === 'undefined' ||
            typeof obj.fields.houseNumber.errorMessage === 'string') &&
          (typeof obj.fields.houseNumber.placeholder === 'undefined' ||
            typeof obj.fields.houseNumber.placeholder === 'string') &&
          (typeof obj.fields.houseNumber.required === 'undefined' ||
            obj.fields.houseNumber.required === false ||
            obj.fields.houseNumber.required === true) &&
          (typeof obj.fields.houseNumber.disabled === 'undefined' ||
            obj.fields.houseNumber.disabled === false ||
            obj.fields.houseNumber.disabled === true) &&
          (typeof obj.fields.houseNumber.defaultValue === 'undefined' ||
            typeof obj.fields.houseNumber.defaultValue === 'string')) ||
        (((obj.fields.houseNumber !== null &&
          typeof obj.fields.houseNumber === 'object') ||
          typeof obj.fields.houseNumber === 'function') &&
          (typeof obj.fields.houseNumber.layout === 'undefined' ||
            (((obj.fields.houseNumber.layout !== null &&
              typeof obj.fields.houseNumber.layout === 'object') ||
              typeof obj.fields.houseNumber.layout === 'function') &&
              (typeof obj.fields.houseNumber.layout.xs === 'undefined' ||
                obj.fields.houseNumber.layout.xs === false ||
                obj.fields.houseNumber.layout.xs === true ||
                obj.fields.houseNumber.layout.xs === 'auto' ||
                obj.fields.houseNumber.layout.xs === 1 ||
                obj.fields.houseNumber.layout.xs === 2 ||
                obj.fields.houseNumber.layout.xs === 3 ||
                obj.fields.houseNumber.layout.xs === 4 ||
                obj.fields.houseNumber.layout.xs === 5 ||
                obj.fields.houseNumber.layout.xs === 6 ||
                obj.fields.houseNumber.layout.xs === 7 ||
                obj.fields.houseNumber.layout.xs === 8 ||
                obj.fields.houseNumber.layout.xs === 9 ||
                obj.fields.houseNumber.layout.xs === 10 ||
                obj.fields.houseNumber.layout.xs === 11 ||
                obj.fields.houseNumber.layout.xs === 12) &&
              (typeof obj.fields.houseNumber.layout.sm === 'undefined' ||
                obj.fields.houseNumber.layout.sm === false ||
                obj.fields.houseNumber.layout.sm === true ||
                obj.fields.houseNumber.layout.sm === 'auto' ||
                obj.fields.houseNumber.layout.sm === 1 ||
                obj.fields.houseNumber.layout.sm === 2 ||
                obj.fields.houseNumber.layout.sm === 3 ||
                obj.fields.houseNumber.layout.sm === 4 ||
                obj.fields.houseNumber.layout.sm === 5 ||
                obj.fields.houseNumber.layout.sm === 6 ||
                obj.fields.houseNumber.layout.sm === 7 ||
                obj.fields.houseNumber.layout.sm === 8 ||
                obj.fields.houseNumber.layout.sm === 9 ||
                obj.fields.houseNumber.layout.sm === 10 ||
                obj.fields.houseNumber.layout.sm === 11 ||
                obj.fields.houseNumber.layout.sm === 12) &&
              (typeof obj.fields.houseNumber.layout.md === 'undefined' ||
                obj.fields.houseNumber.layout.md === false ||
                obj.fields.houseNumber.layout.md === true ||
                obj.fields.houseNumber.layout.md === 'auto' ||
                obj.fields.houseNumber.layout.md === 1 ||
                obj.fields.houseNumber.layout.md === 2 ||
                obj.fields.houseNumber.layout.md === 3 ||
                obj.fields.houseNumber.layout.md === 4 ||
                obj.fields.houseNumber.layout.md === 5 ||
                obj.fields.houseNumber.layout.md === 6 ||
                obj.fields.houseNumber.layout.md === 7 ||
                obj.fields.houseNumber.layout.md === 8 ||
                obj.fields.houseNumber.layout.md === 9 ||
                obj.fields.houseNumber.layout.md === 10 ||
                obj.fields.houseNumber.layout.md === 11 ||
                obj.fields.houseNumber.layout.md === 12) &&
              (typeof obj.fields.houseNumber.layout.lg === 'undefined' ||
                obj.fields.houseNumber.layout.lg === false ||
                obj.fields.houseNumber.layout.lg === true ||
                obj.fields.houseNumber.layout.lg === 'auto' ||
                obj.fields.houseNumber.layout.lg === 1 ||
                obj.fields.houseNumber.layout.lg === 2 ||
                obj.fields.houseNumber.layout.lg === 3 ||
                obj.fields.houseNumber.layout.lg === 4 ||
                obj.fields.houseNumber.layout.lg === 5 ||
                obj.fields.houseNumber.layout.lg === 6 ||
                obj.fields.houseNumber.layout.lg === 7 ||
                obj.fields.houseNumber.layout.lg === 8 ||
                obj.fields.houseNumber.layout.lg === 9 ||
                obj.fields.houseNumber.layout.lg === 10 ||
                obj.fields.houseNumber.layout.lg === 11 ||
                obj.fields.houseNumber.layout.lg === 12) &&
              (typeof obj.fields.houseNumber.layout.xl === 'undefined' ||
                obj.fields.houseNumber.layout.xl === false ||
                obj.fields.houseNumber.layout.xl === true ||
                obj.fields.houseNumber.layout.xl === 'auto' ||
                obj.fields.houseNumber.layout.xl === 1 ||
                obj.fields.houseNumber.layout.xl === 2 ||
                obj.fields.houseNumber.layout.xl === 3 ||
                obj.fields.houseNumber.layout.xl === 4 ||
                obj.fields.houseNumber.layout.xl === 5 ||
                obj.fields.houseNumber.layout.xl === 6 ||
                obj.fields.houseNumber.layout.xl === 7 ||
                obj.fields.houseNumber.layout.xl === 8 ||
                obj.fields.houseNumber.layout.xl === 9 ||
                obj.fields.houseNumber.layout.xl === 10 ||
                obj.fields.houseNumber.layout.xl === 11 ||
                obj.fields.houseNumber.layout.xl === 12))))) &&
      (typeof obj.fields.extention === 'undefined' ||
        (((obj.fields.extention !== null &&
          typeof obj.fields.extention === 'object') ||
          typeof obj.fields.extention === 'function') &&
          (typeof obj.fields.extention.label === 'undefined' ||
            typeof obj.fields.extention.label === 'string') &&
          (typeof obj.fields.extention.errorMessage === 'undefined' ||
            typeof obj.fields.extention.errorMessage === 'string') &&
          (typeof obj.fields.extention.placeholder === 'undefined' ||
            typeof obj.fields.extention.placeholder === 'string') &&
          (typeof obj.fields.extention.required === 'undefined' ||
            obj.fields.extention.required === false ||
            obj.fields.extention.required === true) &&
          (typeof obj.fields.extention.disabled === 'undefined' ||
            obj.fields.extention.disabled === false ||
            obj.fields.extention.disabled === true) &&
          (typeof obj.fields.extention.defaultValue === 'undefined' ||
            typeof obj.fields.extention.defaultValue === 'string')) ||
        (((obj.fields.extention !== null &&
          typeof obj.fields.extention === 'object') ||
          typeof obj.fields.extention === 'function') &&
          (typeof obj.fields.extention.layout === 'undefined' ||
            (((obj.fields.extention.layout !== null &&
              typeof obj.fields.extention.layout === 'object') ||
              typeof obj.fields.extention.layout === 'function') &&
              (typeof obj.fields.extention.layout.xs === 'undefined' ||
                obj.fields.extention.layout.xs === false ||
                obj.fields.extention.layout.xs === true ||
                obj.fields.extention.layout.xs === 'auto' ||
                obj.fields.extention.layout.xs === 1 ||
                obj.fields.extention.layout.xs === 2 ||
                obj.fields.extention.layout.xs === 3 ||
                obj.fields.extention.layout.xs === 4 ||
                obj.fields.extention.layout.xs === 5 ||
                obj.fields.extention.layout.xs === 6 ||
                obj.fields.extention.layout.xs === 7 ||
                obj.fields.extention.layout.xs === 8 ||
                obj.fields.extention.layout.xs === 9 ||
                obj.fields.extention.layout.xs === 10 ||
                obj.fields.extention.layout.xs === 11 ||
                obj.fields.extention.layout.xs === 12) &&
              (typeof obj.fields.extention.layout.sm === 'undefined' ||
                obj.fields.extention.layout.sm === false ||
                obj.fields.extention.layout.sm === true ||
                obj.fields.extention.layout.sm === 'auto' ||
                obj.fields.extention.layout.sm === 1 ||
                obj.fields.extention.layout.sm === 2 ||
                obj.fields.extention.layout.sm === 3 ||
                obj.fields.extention.layout.sm === 4 ||
                obj.fields.extention.layout.sm === 5 ||
                obj.fields.extention.layout.sm === 6 ||
                obj.fields.extention.layout.sm === 7 ||
                obj.fields.extention.layout.sm === 8 ||
                obj.fields.extention.layout.sm === 9 ||
                obj.fields.extention.layout.sm === 10 ||
                obj.fields.extention.layout.sm === 11 ||
                obj.fields.extention.layout.sm === 12) &&
              (typeof obj.fields.extention.layout.md === 'undefined' ||
                obj.fields.extention.layout.md === false ||
                obj.fields.extention.layout.md === true ||
                obj.fields.extention.layout.md === 'auto' ||
                obj.fields.extention.layout.md === 1 ||
                obj.fields.extention.layout.md === 2 ||
                obj.fields.extention.layout.md === 3 ||
                obj.fields.extention.layout.md === 4 ||
                obj.fields.extention.layout.md === 5 ||
                obj.fields.extention.layout.md === 6 ||
                obj.fields.extention.layout.md === 7 ||
                obj.fields.extention.layout.md === 8 ||
                obj.fields.extention.layout.md === 9 ||
                obj.fields.extention.layout.md === 10 ||
                obj.fields.extention.layout.md === 11 ||
                obj.fields.extention.layout.md === 12) &&
              (typeof obj.fields.extention.layout.lg === 'undefined' ||
                obj.fields.extention.layout.lg === false ||
                obj.fields.extention.layout.lg === true ||
                obj.fields.extention.layout.lg === 'auto' ||
                obj.fields.extention.layout.lg === 1 ||
                obj.fields.extention.layout.lg === 2 ||
                obj.fields.extention.layout.lg === 3 ||
                obj.fields.extention.layout.lg === 4 ||
                obj.fields.extention.layout.lg === 5 ||
                obj.fields.extention.layout.lg === 6 ||
                obj.fields.extention.layout.lg === 7 ||
                obj.fields.extention.layout.lg === 8 ||
                obj.fields.extention.layout.lg === 9 ||
                obj.fields.extention.layout.lg === 10 ||
                obj.fields.extention.layout.lg === 11 ||
                obj.fields.extention.layout.lg === 12) &&
              (typeof obj.fields.extention.layout.xl === 'undefined' ||
                obj.fields.extention.layout.xl === false ||
                obj.fields.extention.layout.xl === true ||
                obj.fields.extention.layout.xl === 'auto' ||
                obj.fields.extention.layout.xl === 1 ||
                obj.fields.extention.layout.xl === 2 ||
                obj.fields.extention.layout.xl === 3 ||
                obj.fields.extention.layout.xl === 4 ||
                obj.fields.extention.layout.xl === 5 ||
                obj.fields.extention.layout.xl === 6 ||
                obj.fields.extention.layout.xl === 7 ||
                obj.fields.extention.layout.xl === 8 ||
                obj.fields.extention.layout.xl === 9 ||
                obj.fields.extention.layout.xl === 10 ||
                obj.fields.extention.layout.xl === 11 ||
                obj.fields.extention.layout.xl === 12)))))) ||
      (((obj.fields !== null && typeof obj.fields === 'object') ||
        typeof obj.fields === 'function') &&
        (typeof obj.fields.salutation === 'undefined' ||
          (((obj.fields.salutation !== null &&
            typeof obj.fields.salutation === 'object') ||
            typeof obj.fields.salutation === 'function') &&
            (typeof obj.fields.salutation.label === 'undefined' ||
              typeof obj.fields.salutation.label === 'string') &&
            (typeof obj.fields.salutation.errorMessage === 'undefined' ||
              typeof obj.fields.salutation.errorMessage === 'string') &&
            (typeof obj.fields.salutation.placeholder === 'undefined' ||
              typeof obj.fields.salutation.placeholder === 'string') &&
            (typeof obj.fields.salutation.required === 'undefined' ||
              obj.fields.salutation.required === false ||
              obj.fields.salutation.required === true) &&
            (typeof obj.fields.salutation.disabled === 'undefined' ||
              obj.fields.salutation.disabled === false ||
              obj.fields.salutation.disabled === true) &&
            (typeof obj.fields.salutation.defaultValue === 'undefined' ||
              typeof obj.fields.salutation.defaultValue === 'string')) ||
          (((obj.fields.salutation !== null &&
            typeof obj.fields.salutation === 'object') ||
            typeof obj.fields.salutation === 'function') &&
            (typeof obj.fields.salutation.genderType === 'undefined' ||
              obj.fields.salutation.genderType === 'GENDER3'))) &&
        (typeof obj.fields.title === 'undefined' ||
          (((obj.fields.title !== null &&
            typeof obj.fields.title === 'object') ||
            typeof obj.fields.title === 'function') &&
            (typeof obj.fields.title.label === 'undefined' ||
              typeof obj.fields.title.label === 'string') &&
            (typeof obj.fields.title.errorMessage === 'undefined' ||
              typeof obj.fields.title.errorMessage === 'string') &&
            (typeof obj.fields.title.placeholder === 'undefined' ||
              typeof obj.fields.title.placeholder === 'string') &&
            (typeof obj.fields.title.required === 'undefined' ||
              obj.fields.title.required === false ||
              obj.fields.title.required === true) &&
            (typeof obj.fields.title.disabled === 'undefined' ||
              obj.fields.title.disabled === false ||
              obj.fields.title.disabled === true) &&
            (typeof obj.fields.title.defaultValue === 'undefined' ||
              typeof obj.fields.title.defaultValue === 'string'))) &&
        (typeof obj.fields.firstName === 'undefined' ||
          (((obj.fields.firstName !== null &&
            typeof obj.fields.firstName === 'object') ||
            typeof obj.fields.firstName === 'function') &&
            (typeof obj.fields.firstName.label === 'undefined' ||
              typeof obj.fields.firstName.label === 'string') &&
            (typeof obj.fields.firstName.errorMessage === 'undefined' ||
              typeof obj.fields.firstName.errorMessage === 'string') &&
            (typeof obj.fields.firstName.placeholder === 'undefined' ||
              typeof obj.fields.firstName.placeholder === 'string') &&
            (typeof obj.fields.firstName.required === 'undefined' ||
              obj.fields.firstName.required === false ||
              obj.fields.firstName.required === true) &&
            (typeof obj.fields.firstName.disabled === 'undefined' ||
              obj.fields.firstName.disabled === false ||
              obj.fields.firstName.disabled === true) &&
            (typeof obj.fields.firstName.defaultValue === 'undefined' ||
              typeof obj.fields.firstName.defaultValue === 'string'))) &&
        (typeof obj.fields.lastName === 'undefined' ||
          (((obj.fields.lastName !== null &&
            typeof obj.fields.lastName === 'object') ||
            typeof obj.fields.lastName === 'function') &&
            (typeof obj.fields.lastName.label === 'undefined' ||
              typeof obj.fields.lastName.label === 'string') &&
            (typeof obj.fields.lastName.errorMessage === 'undefined' ||
              typeof obj.fields.lastName.errorMessage === 'string') &&
            (typeof obj.fields.lastName.placeholder === 'undefined' ||
              typeof obj.fields.lastName.placeholder === 'string') &&
            (typeof obj.fields.lastName.required === 'undefined' ||
              obj.fields.lastName.required === false ||
              obj.fields.lastName.required === true) &&
            (typeof obj.fields.lastName.disabled === 'undefined' ||
              obj.fields.lastName.disabled === false ||
              obj.fields.lastName.disabled === true) &&
            (typeof obj.fields.lastName.defaultValue === 'undefined' ||
              typeof obj.fields.lastName.defaultValue === 'string'))) &&
        (typeof obj.fields.birthDate === 'undefined' ||
          (((obj.fields.birthDate !== null &&
            typeof obj.fields.birthDate === 'object') ||
            typeof obj.fields.birthDate === 'function') &&
            (typeof obj.fields.birthDate.label === 'undefined' ||
              typeof obj.fields.birthDate.label === 'string') &&
            (typeof obj.fields.birthDate.errorMessage === 'undefined' ||
              typeof obj.fields.birthDate.errorMessage === 'string') &&
            (typeof obj.fields.birthDate.placeholder === 'undefined' ||
              typeof obj.fields.birthDate.placeholder === 'string') &&
            (typeof obj.fields.birthDate.required === 'undefined' ||
              obj.fields.birthDate.required === false ||
              obj.fields.birthDate.required === true) &&
            (typeof obj.fields.birthDate.disabled === 'undefined' ||
              obj.fields.birthDate.disabled === false ||
              obj.fields.birthDate.disabled === true) &&
            (typeof obj.fields.birthDate.defaultValue === 'undefined' ||
              obj.fields.birthDate.defaultValue instanceof Date))) &&
        (typeof obj.fields.email === 'undefined' ||
          (((obj.fields.email !== null &&
            typeof obj.fields.email === 'object') ||
            typeof obj.fields.email === 'function') &&
            (typeof obj.fields.email.label === 'undefined' ||
              typeof obj.fields.email.label === 'string') &&
            (typeof obj.fields.email.errorMessage === 'undefined' ||
              typeof obj.fields.email.errorMessage === 'string') &&
            (typeof obj.fields.email.placeholder === 'undefined' ||
              typeof obj.fields.email.placeholder === 'string') &&
            (typeof obj.fields.email.required === 'undefined' ||
              obj.fields.email.required === false ||
              obj.fields.email.required === true) &&
            (typeof obj.fields.email.disabled === 'undefined' ||
              obj.fields.email.disabled === false ||
              obj.fields.email.disabled === true) &&
            (typeof obj.fields.email.defaultValue === 'undefined' ||
              typeof obj.fields.email.defaultValue === 'string'))) &&
        (typeof obj.fields.telephone === 'undefined' ||
          (((obj.fields.telephone !== null &&
            typeof obj.fields.telephone === 'object') ||
            typeof obj.fields.telephone === 'function') &&
            (typeof obj.fields.telephone.label === 'undefined' ||
              typeof obj.fields.telephone.label === 'string') &&
            (typeof obj.fields.telephone.errorMessage === 'undefined' ||
              typeof obj.fields.telephone.errorMessage === 'string') &&
            (typeof obj.fields.telephone.placeholder === 'undefined' ||
              typeof obj.fields.telephone.placeholder === 'string') &&
            (typeof obj.fields.telephone.required === 'undefined' ||
              obj.fields.telephone.required === false ||
              obj.fields.telephone.required === true) &&
            (typeof obj.fields.telephone.disabled === 'undefined' ||
              obj.fields.telephone.disabled === false ||
              obj.fields.telephone.disabled === true) &&
            (typeof obj.fields.telephone.defaultValue === 'undefined' ||
              typeof obj.fields.telephone.defaultValue === 'string'))) &&
        (typeof obj.fields.customerType === 'undefined' ||
          (((obj.fields.customerType !== null &&
            typeof obj.fields.customerType === 'object') ||
            typeof obj.fields.customerType === 'function') &&
            (typeof obj.fields.customerType.label === 'undefined' ||
              typeof obj.fields.customerType.label === 'string') &&
            (typeof obj.fields.customerType.errorMessage === 'undefined' ||
              typeof obj.fields.customerType.errorMessage === 'string') &&
            (typeof obj.fields.customerType.placeholder === 'undefined' ||
              typeof obj.fields.customerType.placeholder === 'string') &&
            (typeof obj.fields.customerType.required === 'undefined' ||
              obj.fields.customerType.required === false ||
              obj.fields.customerType.required === true) &&
            (typeof obj.fields.customerType.disabled === 'undefined' ||
              obj.fields.customerType.disabled === false ||
              obj.fields.customerType.disabled === true) &&
            (typeof obj.fields.customerType.defaultValue === 'undefined' ||
              obj.fields.customerType.defaultValue === 'Private' ||
              obj.fields.customerType.defaultValue === 'Business')) ||
          (((obj.fields.customerType !== null &&
            typeof obj.fields.customerType === 'object') ||
            typeof obj.fields.customerType === 'function') &&
            (typeof obj.fields.customerType.options === 'undefined' ||
              (Array.isArray(obj.fields.customerType.options) &&
                obj.fields.customerType.options.every(
                  (e: any) =>
                    ((e !== null && typeof e === 'object') ||
                      typeof e === 'function') &&
                    (e.value === 'Private' || e.value === 'Business') &&
                    (typeof e.label === 'undefined' ||
                      typeof e.label === 'string')
                ))))) &&
        (typeof obj.fields.companyName === 'undefined' ||
          (((obj.fields.companyName !== null &&
            typeof obj.fields.companyName === 'object') ||
            typeof obj.fields.companyName === 'function') &&
            (typeof obj.fields.companyName.label === 'undefined' ||
              typeof obj.fields.companyName.label === 'string') &&
            (typeof obj.fields.companyName.errorMessage === 'undefined' ||
              typeof obj.fields.companyName.errorMessage === 'string') &&
            (typeof obj.fields.companyName.placeholder === 'undefined' ||
              typeof obj.fields.companyName.placeholder === 'string') &&
            (typeof obj.fields.companyName.required === 'undefined' ||
              obj.fields.companyName.required === false ||
              obj.fields.companyName.required === true) &&
            (typeof obj.fields.companyName.disabled === 'undefined' ||
              obj.fields.companyName.disabled === false ||
              obj.fields.companyName.disabled === true) &&
            (typeof obj.fields.companyName.defaultValue === 'undefined' ||
              typeof obj.fields.companyName.defaultValue === 'string')) ||
          (((obj.fields.companyName !== null &&
            typeof obj.fields.companyName === 'object') ||
            typeof obj.fields.companyName === 'function') &&
            (typeof obj.fields.companyName.showIf === 'undefined' ||
              (((obj.fields.companyName.showIf !== null &&
                typeof obj.fields.companyName.showIf === 'object') ||
                typeof obj.fields.companyName.showIf === 'function') &&
                obj.fields.companyName.showIf.customerType === 'Business')))) &&
        (typeof obj.fields.registryCourt === 'undefined' ||
          (((obj.fields.registryCourt !== null &&
            typeof obj.fields.registryCourt === 'object') ||
            typeof obj.fields.registryCourt === 'function') &&
            (typeof obj.fields.registryCourt.label === 'undefined' ||
              typeof obj.fields.registryCourt.label === 'string') &&
            (typeof obj.fields.registryCourt.errorMessage === 'undefined' ||
              typeof obj.fields.registryCourt.errorMessage === 'string') &&
            (typeof obj.fields.registryCourt.placeholder === 'undefined' ||
              typeof obj.fields.registryCourt.placeholder === 'string') &&
            (typeof obj.fields.registryCourt.required === 'undefined' ||
              obj.fields.registryCourt.required === false ||
              obj.fields.registryCourt.required === true) &&
            (typeof obj.fields.registryCourt.disabled === 'undefined' ||
              obj.fields.registryCourt.disabled === false ||
              obj.fields.registryCourt.disabled === true) &&
            (typeof obj.fields.registryCourt.defaultValue === 'undefined' ||
              typeof obj.fields.registryCourt.defaultValue === 'string')) ||
          (((obj.fields.registryCourt !== null &&
            typeof obj.fields.registryCourt === 'object') ||
            typeof obj.fields.registryCourt === 'function') &&
            (typeof obj.fields.registryCourt.showIf === 'undefined' ||
              (((obj.fields.registryCourt.showIf !== null &&
                typeof obj.fields.registryCourt.showIf === 'object') ||
                typeof obj.fields.registryCourt.showIf === 'function') &&
                obj.fields.registryCourt.showIf.customerType ===
                  'Business')))) &&
        (typeof obj.fields.registerNumber === 'undefined' ||
          (((obj.fields.registerNumber !== null &&
            typeof obj.fields.registerNumber === 'object') ||
            typeof obj.fields.registerNumber === 'function') &&
            (typeof obj.fields.registerNumber.label === 'undefined' ||
              typeof obj.fields.registerNumber.label === 'string') &&
            (typeof obj.fields.registerNumber.errorMessage === 'undefined' ||
              typeof obj.fields.registerNumber.errorMessage === 'string') &&
            (typeof obj.fields.registerNumber.placeholder === 'undefined' ||
              typeof obj.fields.registerNumber.placeholder === 'string') &&
            (typeof obj.fields.registerNumber.required === 'undefined' ||
              obj.fields.registerNumber.required === false ||
              obj.fields.registerNumber.required === true) &&
            (typeof obj.fields.registerNumber.disabled === 'undefined' ||
              obj.fields.registerNumber.disabled === false ||
              obj.fields.registerNumber.disabled === true) &&
            (typeof obj.fields.registerNumber.defaultValue === 'undefined' ||
              typeof obj.fields.registerNumber.defaultValue === 'string')) ||
          (((obj.fields.registerNumber !== null &&
            typeof obj.fields.registerNumber === 'object') ||
            typeof obj.fields.registerNumber === 'function') &&
            (typeof obj.fields.registerNumber.showIf === 'undefined' ||
              (((obj.fields.registerNumber.showIf !== null &&
                typeof obj.fields.registerNumber.showIf === 'object') ||
                typeof obj.fields.registerNumber.showIf === 'function') &&
                obj.fields.registerNumber.showIf.customerType ===
                  'Business')))))) &&
    (typeof obj.countryAddressSettings === 'undefined' ||
      (((obj.countryAddressSettings !== null &&
        typeof obj.countryAddressSettings === 'object') ||
        typeof obj.countryAddressSettings === 'function') &&
        (typeof obj.countryAddressSettings.countryCode === 'undefined' ||
          typeof obj.countryAddressSettings.countryCode === 'string') &&
        (typeof obj.countryAddressSettings.enableAutoComplete === 'undefined' ||
          obj.countryAddressSettings.enableAutoComplete === false ||
          obj.countryAddressSettings.enableAutoComplete === true) &&
        (typeof obj.countryAddressSettings.enableFreeText === 'undefined' ||
          obj.countryAddressSettings.enableFreeText === false ||
          obj.countryAddressSettings.enableFreeText === true)))
  )
}

export function isZipCitySuggestionData(
  obj: any,
  _argumentName?: string
): obj is ZipCitySuggestionData {
  return (
    Array.isArray(obj) &&
    obj.every(
      (e: any) =>
        ((e !== null && typeof e === 'object') || typeof e === 'function') &&
        typeof e.postal_code === 'string' &&
        typeof e.city === 'string' &&
        (typeof e.uuid === 'undefined' || typeof e.uuid === 'string')
    )
  )
}

export function isStreetSuggestionData(
  obj: any,
  _argumentName?: string
): obj is StreetSuggestionData {
  return (
    Array.isArray(obj) &&
    obj.every(
      (e: any) =>
        ((e !== null && typeof e === 'object') || typeof e === 'function') &&
        (typeof e.uuid === 'undefined' || typeof e.uuid === 'string') &&
        (typeof e.postal_code === 'undefined' ||
          typeof e.postal_code === 'string') &&
        (typeof e.city === 'undefined' || typeof e.city === 'string') &&
        typeof e.street === 'string' &&
        (typeof e.house_number === 'undefined' ||
          typeof e.house_number === 'string')
    )
  )
}
