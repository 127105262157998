import * as React from 'react'

function SvgProcessApproval(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="1em"
      viewBox="0 0 1024 1024"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M502 35.52c-36.988 9.764-68.792 38.732-80.188 73.044L415.356 128H372.48c-28.02 0-44.544 1.664-47.68 4.8-6.752 6.752-6.752 143.648 0 150.4 3.604 3.604 54.224 4.8 203.2 4.8s199.596-1.196 203.2-4.8c6.752-6.752 6.752-143.648 0-150.4-3.136-3.136-19.66-4.8-47.68-4.8h-42.876l-6.456-19.436c-8.424-25.364-30.224-50.42-54.62-62.784C558.484 35.096 521.92 30.26 502 35.52m68.124 41.788c19.388 13.176 33.06 34 36.316 55.324C610.52 159.36 611.784 160 660.488 160H704v96H352v-96h43.512c48.704 0 49.968-.64 54.048-27.368 4.304-28.188 30.32-58.524 56.54-65.932 16.864-4.76 49.316.616 64.024 10.608m-437.22 87.388c-7.06 7.06-7.16 847.448-.104 854.504 6.96 6.96 783.44 6.96 790.4 0 3.672-3.672 4.8-104.068 4.8-427.512 0-355.604-.88-423.44-5.548-427.316-3.748-3.108-23.416-4.24-60.572-3.488-47.296.96-55.64 2.1-59.432 8.116-3.208 5.096-3.208 8.904 0 14 3.688 5.86 11.656 7.184 48.98 8.132L896 192.264V992H160V192.264l44.572-1.132c37.324-.948 45.292-2.272 48.98-8.132 3.208-5.096 3.208-8.904 0-14-3.796-6.024-12.156-7.152-60.076-8.104-39.904-.792-57.056.284-60.572 3.8M589.016 604.96l-92.96 92.964-45.092-44.964c-24.8-24.728-47.852-44.96-51.228-44.96-7.876 0-15.736 7.864-15.736 15.748C384 631.7 487.984 736 495.916 736 503.92 736 704 535.836 704 527.828c0-7.984-7.836-15.828-15.812-15.828-3.416 0-48.044 41.832-99.172 92.96" />
    </svg>
  )
}

export default SvgProcessApproval
