import * as React from 'react'

function SvgPencil2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 17 17"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13.858 4.176l-.921-.921a1.376 1.376 0 00-1.93 0L9.872 4.41l2.831 2.83 1.155-1.149a1.375 1.375 0 000-1.915zm-11.605 7.85v2.83h2.831l6.911-6.909-2.83-2.83-6.912 6.908z" />
    </svg>
  )
}

export default SvgPencil2
