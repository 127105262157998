import { rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'

import { EpilotControlProps } from '../../../utils'

import {
  ConfirmationMessageComponent,
  ConfirmationMessageComponentProps
} from './components/ConfirmationMessageComponent'

export type ConfirmationMessageControlOptions =
  ConfirmationMessageComponentProps

function ConfirmationMessageControl({ uischema }: EpilotControlProps) {
  const props = (uischema?.options || {}) as ConfirmationMessageControlOptions

  return <ConfirmationMessageComponent {...props} />
}

export default withJsonFormsControlProps(ConfirmationMessageControl as never)

export const ConfirmationMessageControlTester = rankWith(
  5,
  uiTypeIs('ConfirmationMessageControl')
)
