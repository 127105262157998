import * as React from 'react'

function SvgProcessLead(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="1em"
      viewBox="0 0 1024 1024"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M516.8 36.8c-3.6 3.6-4.8 52.836-4.8 196.956v192.16l-69.04-68.96C404.984 319.032 371.144 288 367.76 288c-7.932 0-15.76 7.864-15.76 15.836C352 311.828 520.08 480 528.068 480 536.008 480 704 311.716 704 303.764c0-7.908-7.852-15.764-15.76-15.764-3.384 0-37.224 31.032-75.2 68.956L544 425.916v-192.16C544 35.148 543.752 32 528 32c-3.52 0-8.56 2.16-11.2 4.8M197.024 356.868c-2.3 2.824-40.372 70.26-84.604 149.864L32 651.464v165.468c0 123.364 1.22 166.688 4.8 170.268 6.972 6.972 975.428 6.972 982.4 0 3.58-3.58 4.8-46.904 4.8-170.268V651.464l-80.42-144.732c-44.232-79.604-82.304-147.04-84.604-149.864-3.012-3.704-15.304-4.82-44.152-4-32.932.932-40.748 2.364-44.38 8.132-3.204 5.092-3.204 8.908 0 14 3.476 5.532 11.092 7.24 36.3 8.152l31.9 1.148L979.556 638l-148.428 1.048c-101.436.72-150.12 2.456-153.776 5.492-3.868 3.208-5.352 16.5-5.352 47.948V736H384v-43.512c0-31.448-1.484-44.74-5.352-47.948-3.656-3.036-52.34-4.772-153.776-5.492L76.444 638l140.912-253.7 31.9-1.148c25.208-.912 32.824-2.62 36.3-8.152 3.204-5.092 3.204-8.908 0-14-3.632-5.768-11.448-7.2-44.38-8.132-28.848-.82-41.14.296-44.152 4M352 715.2c0 28.268 1.66 44.86 4.8 48 6.9 6.9 335.5 6.9 342.4 0 3.14-3.14 4.8-19.732 4.8-48V672h288v288H64V672h288v43.2" />
    </svg>
  )
}

export default SvgProcessLead
