import {
  Hidden,
  SpacingProps,
  StackSpacing,
  Grid,
  GridProps
} from '@epilot/base-elements'
import {
  LayoutProps,
  Layout,
  rankWith,
  uiTypeIs,
  UISchemaElement
} from '@jsonforms/core'
import {
  ResolvedJsonFormsDispatch,
  withJsonFormsLayoutProps
} from '@jsonforms/react'
import React, { useMemo } from 'react'

import { IsMobile } from '../../utils/helper'

import {
  getStickyItems,
  stickySorter,
  StickyZone,
  useWrapperStyles
} from './MainContentCartLayout'

export type MainLinearLayoutOptions = SpacingProps & {
  spacing?: GridProps['spacing']
}

const MainLinearLayout = ({
  uischema,
  renderers,
  schema,
  path,
  enabled,
  visible
}: LayoutProps) => {
  const { wrapperStyles } = useWrapperStyles()

  const layout = uischema as Layout
  const {
    scale = 3,
    spacing = 4,
    alignItems = 'stretch',
    ...rest
  } = (uischema.options as MainLinearLayoutOptions) || {}

  const sticky: UISchemaElement[] = useMemo(() => {
    const items = getStickyItems(layout.elements)

    items.sort(stickySorter)

    return items
  }, [layout.elements])

  const mobile = IsMobile()

  return (
    <Hidden xsUp={!visible}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          flex: 1
        }}
      >
        <div className={wrapperStyles}>
          <Grid container spacing={spacing} {...rest}>
            <Grid item xs={12}>
              <StackSpacing alignItems={alignItems} scale={scale} {...rest}>
                {layout.elements.map((child, index) => {
                  if (child.options?.stickyOnMobile && mobile) {
                    return <></>
                  } else {
                    return (
                      <ResolvedJsonFormsDispatch
                        enabled={enabled}
                        key={index}
                        path={path}
                        renderers={renderers}
                        schema={schema}
                        uischema={child}
                      />
                    )
                  }
                })}
              </StackSpacing>
            </Grid>
          </Grid>
        </div>
        {mobile && sticky.length > 0 && (
          <StickyZone>
            {sticky.map((child: any, index: number) => (
              <ResolvedJsonFormsDispatch
                enabled={enabled}
                key={'footer' + index}
                path={path}
                renderers={renderers}
                schema={schema}
                uischema={child}
              />
            ))}
          </StickyZone>
        )}
      </div>
    </Hidden>
  )
}

export const MainLinearLayoutTester = rankWith(2, uiTypeIs('MainLinearLayout'))
export default withJsonFormsLayoutProps(MainLinearLayout)
