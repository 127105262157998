import { rankWith, uiTypeIs } from '@jsonforms/core'

import CrossSellProductSelectionControl from './CrossSellProductSelectionControl'

export const CrossSellProductSelectionControlTester = rankWith(
  4,
  uiTypeIs('CrossSellProductSelectionControl')
)

export { CrossSellProductSelectionControl as default }
