import { CountryInputProps, CountryInput } from '@epilot/base-modules'
import { schemaTypeIs, rankWith, and, optionIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'

import { EpilotControlProps } from '../../utils'

export type CountryInputOptions = Omit<
  CountryInputProps,
  'label' | 'onChange'
> & {}
function CountryInputControl({
  data,
  handleChange,
  path,
  uischema,
  errors,
  enabled,
  /**
   * @todo required is needed
   */
  //required,
  schema
}: EpilotControlProps) {
  const handleOnChange = (value: string) => {
    handleChange(path, value)
  }

  const { label = '' } = uischema || {}

  const { errorMessage } = schema

  const { ...props } = uischema.options as CountryInputOptions

  return (
    <CountryInput
      disabled={!enabled}
      error={
        errors
          ? errorMessage && typeof errorMessage
            ? errorMessage
            : errors
          : ''
      }
      initialCountryCode={data}
      label={label as string}
      onChange={handleOnChange as never}
      {...props}
    />
  )
}

export default withJsonFormsControlProps(CountryInputControl as never)

export const CountryInputControlTester = rankWith(
  4,
  and(schemaTypeIs('string'), optionIs('country_input', true))
)
