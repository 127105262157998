import * as React from 'react'

function SvgGas(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 12 16"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.547 16.006a.327.327 0 01-.183-.596c.9-.615 1.817-2.026 1.83-3.794.008-1.26-.432-3.161-2.467-5.312.104 2.68-1.022 4.939-1.076 5.043a.324.324 0 01-.356.173.328.328 0 01-.262-.299c0-.012-.08-1.066-.79-1.944-.17.56-.361 1.007-.532 1.412-.328.769-.585 1.376-.585 2.413 0 .793.642 1.724 1.26 2.215a.327.327 0 01-.16.613H4.22a.363.363 0 01-.107-.017c-2.502-.864-3.88-2.614-3.88-4.928 0-1.967.902-3.554 1.855-5.234C2.948 4.24 3.921 2.525 4.4.252A.326.326 0 014.94.081c4.528 4.181 6.825 7.011 6.825 10.866 0 2.537-1.792 4.71-4.171 5.056-.016.003-.033.003-.048.003z"
        fill={props.fill ? props.fill : '#172B4D'}
      />
    </svg>
  )
}

export default SvgGas
