import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { STAGE } from '../utils/services/config'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

export const withQueryProvider = function QueryContainer<T>(
  /* eslint-disable @typescript-eslint/no-explicit-any */
  Component: React.FunctionComponent<any>
) {
  return function QueryWrapper(props: T) {
    return (
      <QueryClientProvider client={queryClient}>
        <Component {...props} />
        {STAGE === 'dev' && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    )
  }
}
