import { PersonalInformationComponentProps } from './types'

export enum GENDER_TYPE {
  GENDER3 = 'Mr/Mrs/Diverse',
  GENDER2 = 'Mr/Mrs'
}

export const DefaultPersonalInformationComponentProps: Partial<PersonalInformationComponentProps> =
  {
    fieldsOrder: [
      {
        name: 'salutation',
        groupName: 'identification'
      },
      {
        name: 'title',
        groupName: 'identification'
      },
      {
        name: 'firstName',
        groupName: 'identification'
      },
      {
        name: 'lastName',
        groupName: 'identification'
      },
      {
        name: 'birthDate',
        groupName: 'contactInfo'
      },
      {
        name: 'email',
        groupName: 'contactInfo'
      },
      {
        name: 'telephone',
        groupName: 'contactInfo'
      },
      {
        name: 'customerType',
        gridItemProps: { sm: 12 },
        groupName: 'customerType'
      },
      {
        name: 'companyName',
        groupName: 'customerType'
      },
      {
        name: 'registryCourt',
        groupName: 'customerType'
      },
      {
        name: 'registerNumber',
        groupName: 'customerType'
      },
      {
        name: 'agreement',
        gridItemProps: { sm: 12 },
        groupName: 'customerType'
      }
    ],
    placeHolders: {
      birthDate: 'TT.MM.JJJJ'
    },
    requiredFields: {
      agreement: true,
      firstName: true,
      title: true,
      salutation: true,
      lastName: true,

      companyName: true,
      registerNumber: true,
      registryCourt: true,
      email: true,
      birthDate: true
    },
    defaultCustomerTypeAndLabelOptions: {
      defaultCustomerSelection: 'Private',
      customerTypeLabelOptions: {
        privateLabel: 'Private',
        businessLabel: 'Business'
      }
    },
    salutationType: GENDER_TYPE.GENDER3
  }
export const CUSTOMER_TYPE_FIELDS = [
  'companyName',
  'registryCourt',
  'registerNumber'
]

export enum CUSTOMER_TYPE {
  PRIVATE = 'Private',
  BUSINESS = 'Business'
}

export enum OPTIONS_VALUE_FOR_COMPANY_NAME_CONDITION {
  CONDITIONAL = 'Only when end-users select is "Business"',
  ALWAYS = 'Always'
}

export const PERSONAL_INFO_FIELDS = [
  'salutation',
  'title',
  'firstName',
  'lastName',
  'birthDate',
  'email',
  'telephone',
  'customerType',
  'companyName',
  'registryCourt',
  'registerNumber',
  'agreement'
]
