import {
  Button,
  CardContent,
  Drawer,
  Hidden,
  Typography
} from '@epilot/base-elements'
import {
  EnumProductControlData,
  Product,
  ShoppingCart
} from '@epilot/base-modules'
import { Layout, LayoutProps, rankWith, uiTypeIs } from '@jsonforms/core'
import {
  ResolvedJsonFormsDispatch,
  withJsonFormsControlProps
} from '@jsonforms/react'
import React, { useState } from 'react'

import { IsMobile } from '../../utils/helper'

const generateProductPackage = (productInfo: EnumProductControlData[]) => {
  const productPackage: Product[] = []

  productInfo &&
    productInfo.forEach((item) => {
      const quantity = item?.quantity
      const product = item?.product

      if (product && quantity) {
        const productImages = (product as any)?.selectionMetadata
          ?.selectedProduct?.product_images
        const productImage = productImages
          ? productImages[0]?.public_url
          : undefined

        for (let i = 1; i <= quantity; i++) {
          productPackage.push({ ...product, productImage: productImage })
        }
      }
    })

  return productPackage
}

export type ShopCartControlOption = {
  cartTitle: string
  cartFootnote: string
  stickyOnMobile?: boolean
  stickyOnMobileIndex?: number
  collapsedString?: string
  collapsedCloseString?: string
}
function ShopCartControl({
  visible,
  uischema,
  enabled,
  renderers,
  data,
  path,
  schema
}: LayoutProps) {
  const [expanded, setExpanded] = useState(false)
  const { elements } = (uischema as Layout) || {}
  const {
    cartTitle,
    stickyOnMobile = false,
    collapsedString = 'Produkte im Warenkorb ansehen (%s)',
    collapsedCloseString = 'Schließen',
    cartFootnote
  } = (uischema?.options as ShopCartControlOption) || {}
  let selectedProducts: Product[] = []

  if (data) {
    for (const key in data) {
      const products = data[key]
      const productsArray = Array.isArray(products) ? products : [products]

      selectedProducts = selectedProducts.concat(
        generateProductPackage(productsArray)
      )
    }
  }

  const mobile = IsMobile()

  const cart = (
    <ShoppingCart
      bottomComponent={elements?.map((e, index) => (
        <ResolvedJsonFormsDispatch
          enabled={enabled}
          key={index}
          path={path}
          renderers={renderers}
          schema={schema}
          uischema={e}
        />
      ))}
      dense
      footnote={cartFootnote}
      products={selectedProducts}
      title={`${cartTitle || 'Produkte im Warenkorb'} (${
        selectedProducts.length
      })`}
    />
  )

  const collapse = (
    <>
      <Button fullWidth onClick={() => setExpanded(true)} size="large">
        {collapsedString.replace('%s', '' + selectedProducts.length)}
      </Button>
      <Drawer
        anchor="bottom"
        onClose={() => setExpanded(false)}
        open={expanded}
      >
        <CardContent>{cart}</CardContent>
        <Button fullWidth onClick={() => setExpanded(false)} size="large">
          <Typography
            color="primary"
            style={{ textDecorationLine: 'underline' }}
          >
            <b>{collapsedCloseString}</b>
          </Typography>
        </Button>
      </Drawer>
    </>
  )

  return (
    <Hidden xsUp={!visible}>
      {mobile && stickyOnMobile ? collapse : cart}
    </Hidden>
  )
}

export default withJsonFormsControlProps(ShopCartControl as never)

export const ShopCartControlTester = rankWith(4, uiTypeIs('ShopCartControl'))
