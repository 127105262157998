import { rankWith, uiTypeIs } from '@jsonforms/core'

import ProductSelectionControl from './ProductSelectionControl'

export const ProductSelectionControlTester = rankWith(
  4,
  uiTypeIs('ProductSelectionControl')
)

export { ProductSelectionControl as default }
