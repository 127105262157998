import { Grid, Button, CircularProgress, useTheme } from '@epilot/base-elements'
import React, { useRef } from 'react'

import { JOURNEY_SUBMISSION_EVENT } from '../../../utils/constants/EventConstant'
import { EventDetailType } from '../../../utils/constants/types'
import { useResize } from '../../../utils/hooks/useResize'

import useLoadingListener from './hooks'
import { ActionButton } from './types'

type ActionButtonsProps = {
  ctaButton?: ActionButton
}

const ActionButtons = ({ ctaButton }: ActionButtonsProps) => {
  const loading = useLoadingListener()

  // used to position ctaButton depending on containers width.
  // I.e. will be fullWidth by default in the sidebar
  const ref = useRef<null | HTMLDivElement>(null)
  const { width: containerWidth } = useResize(ref)
  const { breakpoints } = useTheme()

  return (
    <div ref={ref} style={{ width: '100%' }}>
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="flex-end"
        spacing={1}
      >
        {ctaButton && ctaButton.isVisible && (
          <Grid container item justifyContent="flex-end" xs={12}>
            <Button
              color="primary"
              disabled={loading === 'buttonSubmit'}
              endIcon={
                loading === 'buttonSubmit' ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null
              }
              onClick={() => {
                const event = new CustomEvent<EventDetailType>(
                  JOURNEY_SUBMISSION_EVENT,
                  {
                    detail: {
                      eventCaller: 'ActionBar',
                      payload: {
                        actionType: ctaButton.actionType,
                        targetStepId: ctaButton.targetStepId
                      }
                    }
                  }
                )

                document.dispatchEvent(event)
              }}
              size="large" // TODO: Add logic to cta button
              // using minWidth here, so button can auto extend if text larger than original space
              style={{
                minWidth:
                  containerWidth < breakpoints.values.sm
                    ? '100%'
                    : `${(4 / 12) * 100}%`
              }}
              variant="contained"
            >
              {ctaButton.label}
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default ActionButtons
