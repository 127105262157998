import { useLayoutEffect, useRef, useState, MutableRefObject } from 'react'

export const checkElementRequiresOverflow = (
  e: HTMLElement | null,
  elementsAmount: number
) => {
  if (!e || !e.parentElement) {
    console.error('useIsOverflowRequired hook requires parent node to be found')

    return false
  }
  const stylesElement = getComputedStyle(e)
  const stylesElementParent = getComputedStyle(e.parentElement)

  const elementInner = e.firstChild as HTMLElement

  if (!elementInner || elementInner.nodeName !== 'SPAN') {
    console.error(
      'useIsOverflowRequired hook requires first child of ref element to be <span>. Instead received:',
      elementInner ? elementInner.nodeName : elementInner
    )

    return false
  }

  const spaceAvailable =
    e.parentElement.clientWidth -
    parseFloat(stylesElementParent.paddingLeft) -
    parseFloat(stylesElementParent.paddingRight)

  const spacePerElement = spaceAvailable / elementsAmount

  const labelWidth =
    elementInner.offsetWidth +
    parseFloat(stylesElement.paddingLeft) +
    parseFloat(stylesElement.paddingRight) +
    parseFloat(stylesElement.marginLeft) +
    parseFloat(stylesElement.marginRight)

  return labelWidth > spacePerElement
}

export const useIsOverflowRequired = (
  refs: MutableRefObject<(HTMLElement | null)[]>
) => {
  const [isOverflowRequired, setIsOverflowRequired] = useState(false)
  const throttleRef = useRef(false)
  const amountRefs = refs.current.length

  useLayoutEffect(() => {
    const handleResize = () => {
      if (refs.current && !throttleRef.current) {
        throttleRef.current = true
        const elementsRequireOverflow = refs.current.some((e) =>
          checkElementRequiresOverflow(e, refs.current.length)
        )

        setIsOverflowRequired(elementsRequireOverflow)

        setTimeout(function () {
          throttleRef.current = false
        }, 500)
      }
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [refs, amountRefs])

  return isOverflowRequired
}
