export type JourneyEventCaller =
  | 'SummaryBlock'
  | 'ShoppingCart'
  | 'ActionBar'
  | 'JournyBuilder'
  | 'ThankYouBlock'

export type JourneyNavagationEventPayload = {
  stepIndex: number
  blockName?: string
}

export type LoadingEventPayload = {
  item: 'buttonSubmit'
  value: boolean
}

export function isLoadingEventPayload(obj: any): obj is LoadingEventPayload {
  return (
    obj['item'] &&
    obj.item === 'buttonSubmit' &&
    typeof obj['value'] === 'boolean'
  )
}

export type EventDetailType = {
  eventCaller: JourneyEventCaller
  payload?: JourneyNavagationEventPayload | LoadingEventPayload | any
}
