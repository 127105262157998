import { Hidden, SpacingProps, StackSpacing } from '@epilot/base-elements'
import { LayoutProps, Layout, rankWith, uiTypeIs } from '@jsonforms/core'
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'

export type StackSpacingLayoutOptions = SpacingProps & {}

const StackSpacingLayout = ({
  uischema,
  renderers,
  schema,
  path,
  enabled,
  visible
}: LayoutProps) => {
  const layout = uischema as Layout
  const {
    scale = 2,
    alignItems = 'stretch',
    ...rest
  } = (uischema.options as StackSpacingLayoutOptions) || {}

  return (
    <Hidden xsUp={!visible}>
      <StackSpacing alignItems={alignItems} scale={scale} {...rest}>
        {layout.elements.map((child, index) => (
          <JsonFormsDispatch
            enabled={enabled}
            key={index}
            path={path}
            renderers={renderers}
            schema={schema}
            uischema={child}
          />
        ))}
      </StackSpacing>
    </Hidden>
  )
}

export const StackSpacingLayoutTester = rankWith(
  2,
  uiTypeIs('StackSpacingLayout')
)

export default withJsonFormsLayoutProps(StackSpacingLayout)
