import { Collapse, CollapseProps } from '@epilot/base-elements'
import { LayoutProps, Layout, rankWith, uiTypeIs, or } from '@jsonforms/core'
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'

export type CollapseLayoutProps = CollapseProps & {}

const CollapseLayout = ({
  uischema,
  renderers,
  schema,
  path,
  enabled,
  visible
}: LayoutProps) => {
  const layout = uischema as Layout
  const { ...rest } = uischema.options as CollapseLayoutProps

  return (
    <Collapse in={visible} timeout="auto" unmountOnExit {...rest}>
      {layout.elements.map((child, index) => (
        <JsonFormsDispatch
          enabled={enabled}
          key={index}
          path={path}
          renderers={renderers}
          schema={schema}
          uischema={child}
        />
      ))}
    </Collapse>
  )
}

export const CollapseLayoutTester = rankWith(2, or(uiTypeIs('CollapseLayout')))

export default withJsonFormsLayoutProps(CollapseLayout)
