import {
  DateFnsUtils,
  Grid,
  MuiPickersUtilsProvider
} from '@epilot/base-elements'
import { yupResolver } from '@hookform/resolvers/yup'
import { add } from 'date-fns'
import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en-US'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  FieldDatePicker,
  findNextAvailableDay
} from '../../../../components/FieldDatePicker'
import { addDate, getLocaleDateString } from '../../../../utils/helper'
import { useFormHandler } from '../../../../utils/hooks/forms/useFormHandler'
import { useResetOnChange } from '../../../../utils/hooks/forms/useResetOnChange'
import { DatePickerFormValues, DatePickerFormProps } from '../types'
import { findInitialValues } from '../utils'
import { createDateSchema } from '../validationSchema'

const localeMap = {
  en: enLocale,
  de: deLocale
}

/**
 * Enables DatePicker components and manages / validates form state
 * Note: Dates have the toJSON method, which will call toISOString internally
 * when stringifying it, and transforming it back to a Date Object when parsing
 */
export const DatePickerForm = (props: DatePickerFormProps) => {
  const {
    hasError,
    handleChange,
    path,
    data,
    fields,
    disableDays,
    isRequired
  } = props

  const format = getLocaleDateString(navigator.language)

  // detect language and only get language code before the first dash
  const localeDetected = navigator.language.substr(
    0,
    navigator.language.indexOf('-')
  )

  // set locale. Default to de
  const locale = (
    ['de', 'en'].includes(localeDetected) ? localeDetected : 'de'
  ) as 'de' | 'en'

  const { t } = useTranslation()

  const dateSchema = useMemo(
    () => createDateSchema(t, format, { endDate: !!fields.endDate }),
    [t, format, fields.endDate]
  )

  // get initial values for form reset considering prefilling
  const initialValues = {
    startDate: findInitialValues(
      data?.startDate,
      fields.startDate.prefillByDays,
      disableDays
    ),
    endDate: findInitialValues(
      data?.endDate,
      fields.endDate?.prefillByDays,
      disableDays
    )
  }

  const { control, resetField } = useFormHandler<DatePickerFormValues>({
    handleChange,
    path,
    fields,
    data: initialValues,
    isRequired,
    formProps: {
      resolver: yupResolver(dateSchema),
      context: {
        startDate: fields.startDate,
        endDate: fields.endDate,
        disableDays,
        required: isRequired
      }
    }
  })

  // resets default value if it changes
  useResetOnChange(resetField, {
    name: 'startDate',
    watch: fields.startDate.prefillByDays,
    value:
      typeof fields.startDate.prefillByDays === 'number'
        ? findNextAvailableDay(
            add(addDate(), {
              days: fields.startDate.prefillByDays
            }),
            disableDays
          )
        : null
  })

  // resets default value if it changes
  useResetOnChange(resetField, {
    name: 'endDate',
    watch: fields.endDate?.prefillByDays,
    value:
      typeof fields.endDate?.prefillByDays === 'number'
        ? findNextAvailableDay(
            add(addDate(), {
              days: fields.endDate?.prefillByDays
            }),
            disableDays
          )
        : null
  })

  return (
    <MuiPickersUtilsProvider locale={localeMap[locale]} utils={DateFnsUtils}>
      <Grid container spacing={3}>
        <Grid item sm={fields.endDate ? 6 : 12} xs={12}>
          <FieldDatePicker
            control={control}
            disableDays={disableDays}
            format={format}
            hasError={hasError}
            name="startDate"
            path={path}
            required={isRequired}
            {...fields.startDate}
          />
        </Grid>
        {fields.endDate && (
          <Grid item sm={6} xs={12}>
            <FieldDatePicker
              control={control}
              disableDays={disableDays}
              format={format}
              hasError={hasError}
              name="endDate"
              path={path}
              required={isRequired}
              {...fields.endDate}
            />
          </Grid>
        )}
      </Grid>
    </MuiPickersUtilsProvider>
  )
}
