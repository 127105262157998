import {
  AppBar,
  Hidden,
  Tab,
  TabContext,
  TabPanel,
  Tabs,
  TabsProps
} from '@epilot/base-elements'
import { Layout, LayoutProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react'
import React, { useState } from 'react'

export type TabsLayoutProps = Layout & {
  labels: string[]
}
export type TabsLayoutOptions = TabsProps & {
  appBarElevation?: number
  appBarColor?: 'inherit' | 'primary' | 'secondary' | 'default' | 'transparent'
}

const TabsLayout = ({
  uischema,
  path,
  enabled,
  visible,
  renderers,
  schema
}: LayoutProps) => {
  const { labels = [], elements } = (uischema as TabsLayoutProps) || {}
  const {
    appBarColor = 'transparent',
    appBarElevation = 0,
    textColor = 'primary',
    indicatorColor = 'primary',
    ...rest
  } = (uischema.options as TabsLayoutOptions) || {}

  // set state
  const [value, setValue] = useState(labels[0] ? labels[0] : '')

  const onHandleChange = (_event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue)
  }

  return (
    <Hidden xsUp={!visible}>
      <TabContext value={value}>
        <AppBar
          color={appBarColor}
          elevation={appBarElevation}
          position="static"
        >
          <Tabs
            disabled={!enabled}
            indicatorColor={indicatorColor}
            onChange={onHandleChange as never}
            textColor={textColor}
            {...rest}
          >
            {labels.map((label: string) => (
              <Tab key={label} label={label} value={label} />
            ))}
          </Tabs>
        </AppBar>
        {labels.map((label: string, index: number) => (
          <TabPanel key={index} value={label}>
            <JsonFormsDispatch
              enabled={enabled}
              path={path}
              renderers={renderers}
              schema={schema}
              uischema={elements[index]}
            />
          </TabPanel>
        ))}
      </TabContext>
    </Hidden>
  )
}

export default withJsonFormsLayoutProps(TabsLayout as never)
export const TabsLayoutTester = rankWith(2, uiTypeIs('TabsLayout'))
