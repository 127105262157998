import { makeStyles, createStyles } from '@epilot/base-elements'
import { EnumControl } from '@epilot/base-modules'
import React from 'react'
import {
  Control,
  Controller,
  Path,
  UnpackNestedValue,
  PathValue,
  UseControllerProps
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FieldOption } from './types'

type FieldToggleButtonProps<T> = {
  control: Control<T>
  name: Path<T>
  hasError: boolean
  defaultValue?: UnpackNestedValue<PathValue<T, Path<T>>>
  rules?: UseControllerProps['rules']
  options: string[]
  optionsLabels?: string[]
} & FieldOption

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiToggleButtonGroup-root': {
        marginTop: 0
      }
    }
  })
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FieldToggleButton = <T extends Record<string, any>>(
  props: FieldToggleButtonProps<T>
) => {
  const {
    hasError,
    control,
    name,
    label,
    errorMessage,
    defaultValue,
    options,
    optionsLabels,
    required,
    rules
  } = props

  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <div className={classes.root}>
            <EnumControl
              errorMessage={
                hasError && fieldState.invalid
                  ? errorMessage || t('field_required')
                  : undefined
              }
              label={label}
              options={options}
              optionsLabels={optionsLabels}
              uiType="toggle"
              {...field}
            />
          </div>
        )
      }}
      rules={{
        required,
        ...(rules && { rules })
      }}
    />
  )
}
