import { schemaTypeIs, rankWith, and, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'

import {
  Person,
  PersonalInformation,
  PersonalInformationComponentProps
} from '../../../components/PersonalInformation'
import { EpilotControlProps } from '../../../utils'
import { includeCommon } from '../../../utils/includeCommon'

export type PersonalInformationControlOptions = Omit<
  PersonalInformationComponentProps,
  'label' | 'value' | 'onChange' | 'error'
> & {}

function PersonalInformationControl({
  data,
  handleChange,
  path,
  errors,
  uischema
}: EpilotControlProps) {
  const handleOnChange = (data?: Person) => {
    handleChange(path, data)
  }

  const props = uischema.options as PersonalInformationControlOptions

  return (
    <PersonalInformation
      error={errors ? errors : ''}
      onChange={handleOnChange}
      value={data}
      {...props}
    />
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: PersonalInformationControl }) as never
)

export const PersonalInformationControlTester = rankWith(
  4,
  and(schemaTypeIs('object'), uiTypeIs('PersonalInformationControl'))
)
