import {
  Card,
  CardContent,
  Box,
  Typography,
  H6,
  useTheme
} from '@epilot/base-elements'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { IsMobileOrTablet } from './helper'
import { PREVENT_COMMON_COMPONENT } from './services/config'
import { EpilotControlProps } from './types'

type IncludeCommonOptions = {
  component: (props: EpilotControlProps) => JSX.Element
}

export function includeCommon({ component: Component }: IncludeCommonOptions) {
  if (PREVENT_COMMON_COMPONENT) return Component

  const wrappedComponent = ({ ...props }: EpilotControlProps) => {
    const { uischema, required } = props
    const { options, type: uiType } = uischema

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const theme = useTheme()
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslation()

    const spacing =
      (theme && typeof theme.spacing === 'number' && theme.spacing) || 4

    const mobile = IsMobileOrTablet()

    const requiredLabel =
      (uiType as any) === 'ProductSelectionControl' ? (
        <></>
      ) : (
        <Typography
          color="textSecondary"
          style={{ marginLeft: `${spacing * 3}px` }}
          variant="caption"
        >
          {'*' + t('input_required')}
        </Typography>
      )

    const content =
      options?.title || options?.subtitle || required ? (
        <>
          <Box pb={`${spacing * 3}px`} position="relative">
            {options?.title ? (
              <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <H6>
                  <b style={{ fontSize: mobile ? '80%' : '100%' }}>
                    {options?.title}
                  </b>
                </H6>
                {required && requiredLabel}
              </Box>
            ) : null}
            {options?.subtitle ? (
              <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography variant="body1">{options.subtitle}</Typography>
                {required && !options?.title && requiredLabel}
              </Box>
            ) : null}
            {!options?.title && !options?.subtitle && required && (
              <Box display="flex" flexDirection="row" justifyContent="flex-end">
                {requiredLabel}
              </Box>
            )}
          </Box>
          <Component {...props} />
        </>
      ) : (
        <Component {...props} />
      )

    return options?.showPaper ? (
      <Card>
        <CardContent
          style={{
            paddingTop: `${spacing * 4}px`,
            paddingLeft: `${spacing * 3}px`,
            paddingRight: `${spacing * 3}px`
          }}
        >
          {content}
        </CardContent>
      </Card>
    ) : (
      <Box>
        <CardContent
          style={{
            paddingTop: `${spacing * 4}px`
          }}
        >
          {content}
        </CardContent>
      </Box>
    )
  }

  return wrappedComponent
}
