import * as styledComponents from 'styled-components'

import { Theme } from './ThemeProvider/types'

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes
} = styledComponents as styledComponents.ThemedStyledComponentsModule<Theme>

export { css, createGlobalStyle, keyframes }
export default styled
