/*
 * Generated type guards for "types.ts".
 * WARNING: Do not manually change this file.
 */
import { ContactControlData, ContactControlOptions } from './types'

export function isContactControlData(
  obj: any,
  _argumentName?: string
): obj is ContactControlData {
  return (
    typeof obj === 'undefined' ||
    obj === null ||
    (((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
      (typeof obj.salutation === 'undefined' ||
        obj.salutation === null ||
        typeof obj.salutation === 'string') &&
      (typeof obj.title === 'undefined' ||
        obj.title === null ||
        typeof obj.title === 'string') &&
      (typeof obj.firstName === 'undefined' ||
        typeof obj.firstName === 'string') &&
      (typeof obj.lastName === 'undefined' ||
        typeof obj.lastName === 'string') &&
      (typeof obj.birthDate === 'undefined' ||
        obj.birthDate === null ||
        obj.birthDate instanceof Date) &&
      (typeof obj.email === 'undefined' || typeof obj.email === 'string') &&
      (typeof obj.telephone === 'undefined' ||
        typeof obj.telephone === 'string') &&
      (typeof obj.customerType === 'undefined' ||
        obj.customerType === 'Private' ||
        obj.customerType === 'Business') &&
      (typeof obj.companyName === 'undefined' ||
        typeof obj.companyName === 'string') &&
      (typeof obj.registryCourt === 'undefined' ||
        typeof obj.registryCourt === 'string') &&
      (typeof obj.registerNumber === 'undefined' ||
        typeof obj.registerNumber === 'string'))
  )
}

export function isContactControlOptions(
  obj: any,
  _argumentName?: string
): obj is ContactControlOptions {
  return (
    ((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
    (typeof obj.label === 'undefined' || typeof obj.label === 'string') &&
    ((obj.fields !== null && typeof obj.fields === 'object') ||
      typeof obj.fields === 'function') &&
    (typeof obj.fields.salutation === 'undefined' ||
      (((obj.fields.salutation !== null &&
        typeof obj.fields.salutation === 'object') ||
        typeof obj.fields.salutation === 'function') &&
        (typeof obj.fields.salutation.label === 'undefined' ||
          typeof obj.fields.salutation.label === 'string') &&
        (typeof obj.fields.salutation.errorMessage === 'undefined' ||
          typeof obj.fields.salutation.errorMessage === 'string') &&
        (typeof obj.fields.salutation.placeholder === 'undefined' ||
          typeof obj.fields.salutation.placeholder === 'string') &&
        (typeof obj.fields.salutation.required === 'undefined' ||
          obj.fields.salutation.required === false ||
          obj.fields.salutation.required === true) &&
        (typeof obj.fields.salutation.disabled === 'undefined' ||
          obj.fields.salutation.disabled === false ||
          obj.fields.salutation.disabled === true) &&
        (typeof obj.fields.salutation.defaultValue === 'undefined' ||
          typeof obj.fields.salutation.defaultValue === 'string')) ||
      (((obj.fields.salutation !== null &&
        typeof obj.fields.salutation === 'object') ||
        typeof obj.fields.salutation === 'function') &&
        (typeof obj.fields.salutation.genderType === 'undefined' ||
          obj.fields.salutation.genderType === 'GENDER3'))) &&
    (typeof obj.fields.title === 'undefined' ||
      (((obj.fields.title !== null && typeof obj.fields.title === 'object') ||
        typeof obj.fields.title === 'function') &&
        (typeof obj.fields.title.label === 'undefined' ||
          typeof obj.fields.title.label === 'string') &&
        (typeof obj.fields.title.errorMessage === 'undefined' ||
          typeof obj.fields.title.errorMessage === 'string') &&
        (typeof obj.fields.title.placeholder === 'undefined' ||
          typeof obj.fields.title.placeholder === 'string') &&
        (typeof obj.fields.title.required === 'undefined' ||
          obj.fields.title.required === false ||
          obj.fields.title.required === true) &&
        (typeof obj.fields.title.disabled === 'undefined' ||
          obj.fields.title.disabled === false ||
          obj.fields.title.disabled === true) &&
        (typeof obj.fields.title.defaultValue === 'undefined' ||
          typeof obj.fields.title.defaultValue === 'string'))) &&
    (typeof obj.fields.firstName === 'undefined' ||
      (((obj.fields.firstName !== null &&
        typeof obj.fields.firstName === 'object') ||
        typeof obj.fields.firstName === 'function') &&
        (typeof obj.fields.firstName.label === 'undefined' ||
          typeof obj.fields.firstName.label === 'string') &&
        (typeof obj.fields.firstName.errorMessage === 'undefined' ||
          typeof obj.fields.firstName.errorMessage === 'string') &&
        (typeof obj.fields.firstName.placeholder === 'undefined' ||
          typeof obj.fields.firstName.placeholder === 'string') &&
        (typeof obj.fields.firstName.required === 'undefined' ||
          obj.fields.firstName.required === false ||
          obj.fields.firstName.required === true) &&
        (typeof obj.fields.firstName.disabled === 'undefined' ||
          obj.fields.firstName.disabled === false ||
          obj.fields.firstName.disabled === true) &&
        (typeof obj.fields.firstName.defaultValue === 'undefined' ||
          typeof obj.fields.firstName.defaultValue === 'string'))) &&
    (typeof obj.fields.lastName === 'undefined' ||
      (((obj.fields.lastName !== null &&
        typeof obj.fields.lastName === 'object') ||
        typeof obj.fields.lastName === 'function') &&
        (typeof obj.fields.lastName.label === 'undefined' ||
          typeof obj.fields.lastName.label === 'string') &&
        (typeof obj.fields.lastName.errorMessage === 'undefined' ||
          typeof obj.fields.lastName.errorMessage === 'string') &&
        (typeof obj.fields.lastName.placeholder === 'undefined' ||
          typeof obj.fields.lastName.placeholder === 'string') &&
        (typeof obj.fields.lastName.required === 'undefined' ||
          obj.fields.lastName.required === false ||
          obj.fields.lastName.required === true) &&
        (typeof obj.fields.lastName.disabled === 'undefined' ||
          obj.fields.lastName.disabled === false ||
          obj.fields.lastName.disabled === true) &&
        (typeof obj.fields.lastName.defaultValue === 'undefined' ||
          typeof obj.fields.lastName.defaultValue === 'string'))) &&
    (typeof obj.fields.birthDate === 'undefined' ||
      (((obj.fields.birthDate !== null &&
        typeof obj.fields.birthDate === 'object') ||
        typeof obj.fields.birthDate === 'function') &&
        (typeof obj.fields.birthDate.label === 'undefined' ||
          typeof obj.fields.birthDate.label === 'string') &&
        (typeof obj.fields.birthDate.errorMessage === 'undefined' ||
          typeof obj.fields.birthDate.errorMessage === 'string') &&
        (typeof obj.fields.birthDate.placeholder === 'undefined' ||
          typeof obj.fields.birthDate.placeholder === 'string') &&
        (typeof obj.fields.birthDate.required === 'undefined' ||
          obj.fields.birthDate.required === false ||
          obj.fields.birthDate.required === true) &&
        (typeof obj.fields.birthDate.disabled === 'undefined' ||
          obj.fields.birthDate.disabled === false ||
          obj.fields.birthDate.disabled === true) &&
        (typeof obj.fields.birthDate.defaultValue === 'undefined' ||
          obj.fields.birthDate.defaultValue instanceof Date))) &&
    (typeof obj.fields.email === 'undefined' ||
      (((obj.fields.email !== null && typeof obj.fields.email === 'object') ||
        typeof obj.fields.email === 'function') &&
        (typeof obj.fields.email.label === 'undefined' ||
          typeof obj.fields.email.label === 'string') &&
        (typeof obj.fields.email.errorMessage === 'undefined' ||
          typeof obj.fields.email.errorMessage === 'string') &&
        (typeof obj.fields.email.placeholder === 'undefined' ||
          typeof obj.fields.email.placeholder === 'string') &&
        (typeof obj.fields.email.required === 'undefined' ||
          obj.fields.email.required === false ||
          obj.fields.email.required === true) &&
        (typeof obj.fields.email.disabled === 'undefined' ||
          obj.fields.email.disabled === false ||
          obj.fields.email.disabled === true) &&
        (typeof obj.fields.email.defaultValue === 'undefined' ||
          typeof obj.fields.email.defaultValue === 'string'))) &&
    (typeof obj.fields.telephone === 'undefined' ||
      (((obj.fields.telephone !== null &&
        typeof obj.fields.telephone === 'object') ||
        typeof obj.fields.telephone === 'function') &&
        (typeof obj.fields.telephone.label === 'undefined' ||
          typeof obj.fields.telephone.label === 'string') &&
        (typeof obj.fields.telephone.errorMessage === 'undefined' ||
          typeof obj.fields.telephone.errorMessage === 'string') &&
        (typeof obj.fields.telephone.placeholder === 'undefined' ||
          typeof obj.fields.telephone.placeholder === 'string') &&
        (typeof obj.fields.telephone.required === 'undefined' ||
          obj.fields.telephone.required === false ||
          obj.fields.telephone.required === true) &&
        (typeof obj.fields.telephone.disabled === 'undefined' ||
          obj.fields.telephone.disabled === false ||
          obj.fields.telephone.disabled === true) &&
        (typeof obj.fields.telephone.defaultValue === 'undefined' ||
          typeof obj.fields.telephone.defaultValue === 'string'))) &&
    (typeof obj.fields.customerType === 'undefined' ||
      (((obj.fields.customerType !== null &&
        typeof obj.fields.customerType === 'object') ||
        typeof obj.fields.customerType === 'function') &&
        (typeof obj.fields.customerType.label === 'undefined' ||
          typeof obj.fields.customerType.label === 'string') &&
        (typeof obj.fields.customerType.errorMessage === 'undefined' ||
          typeof obj.fields.customerType.errorMessage === 'string') &&
        (typeof obj.fields.customerType.placeholder === 'undefined' ||
          typeof obj.fields.customerType.placeholder === 'string') &&
        (typeof obj.fields.customerType.required === 'undefined' ||
          obj.fields.customerType.required === false ||
          obj.fields.customerType.required === true) &&
        (typeof obj.fields.customerType.disabled === 'undefined' ||
          obj.fields.customerType.disabled === false ||
          obj.fields.customerType.disabled === true) &&
        (typeof obj.fields.customerType.defaultValue === 'undefined' ||
          obj.fields.customerType.defaultValue === 'Private' ||
          obj.fields.customerType.defaultValue === 'Business')) ||
      (((obj.fields.customerType !== null &&
        typeof obj.fields.customerType === 'object') ||
        typeof obj.fields.customerType === 'function') &&
        (typeof obj.fields.customerType.options === 'undefined' ||
          (Array.isArray(obj.fields.customerType.options) &&
            obj.fields.customerType.options.every(
              (e: any) =>
                ((e !== null && typeof e === 'object') ||
                  typeof e === 'function') &&
                (e.value === 'Private' || e.value === 'Business') &&
                (typeof e.label === 'undefined' || typeof e.label === 'string')
            ))))) &&
    (typeof obj.fields.companyName === 'undefined' ||
      (((obj.fields.companyName !== null &&
        typeof obj.fields.companyName === 'object') ||
        typeof obj.fields.companyName === 'function') &&
        (typeof obj.fields.companyName.label === 'undefined' ||
          typeof obj.fields.companyName.label === 'string') &&
        (typeof obj.fields.companyName.errorMessage === 'undefined' ||
          typeof obj.fields.companyName.errorMessage === 'string') &&
        (typeof obj.fields.companyName.placeholder === 'undefined' ||
          typeof obj.fields.companyName.placeholder === 'string') &&
        (typeof obj.fields.companyName.required === 'undefined' ||
          obj.fields.companyName.required === false ||
          obj.fields.companyName.required === true) &&
        (typeof obj.fields.companyName.disabled === 'undefined' ||
          obj.fields.companyName.disabled === false ||
          obj.fields.companyName.disabled === true) &&
        (typeof obj.fields.companyName.defaultValue === 'undefined' ||
          typeof obj.fields.companyName.defaultValue === 'string')) ||
      (((obj.fields.companyName !== null &&
        typeof obj.fields.companyName === 'object') ||
        typeof obj.fields.companyName === 'function') &&
        (typeof obj.fields.companyName.showIf === 'undefined' ||
          (((obj.fields.companyName.showIf !== null &&
            typeof obj.fields.companyName.showIf === 'object') ||
            typeof obj.fields.companyName.showIf === 'function') &&
            obj.fields.companyName.showIf.customerType === 'Business')))) &&
    (typeof obj.fields.registryCourt === 'undefined' ||
      (((obj.fields.registryCourt !== null &&
        typeof obj.fields.registryCourt === 'object') ||
        typeof obj.fields.registryCourt === 'function') &&
        (typeof obj.fields.registryCourt.label === 'undefined' ||
          typeof obj.fields.registryCourt.label === 'string') &&
        (typeof obj.fields.registryCourt.errorMessage === 'undefined' ||
          typeof obj.fields.registryCourt.errorMessage === 'string') &&
        (typeof obj.fields.registryCourt.placeholder === 'undefined' ||
          typeof obj.fields.registryCourt.placeholder === 'string') &&
        (typeof obj.fields.registryCourt.required === 'undefined' ||
          obj.fields.registryCourt.required === false ||
          obj.fields.registryCourt.required === true) &&
        (typeof obj.fields.registryCourt.disabled === 'undefined' ||
          obj.fields.registryCourt.disabled === false ||
          obj.fields.registryCourt.disabled === true) &&
        (typeof obj.fields.registryCourt.defaultValue === 'undefined' ||
          typeof obj.fields.registryCourt.defaultValue === 'string')) ||
      (((obj.fields.registryCourt !== null &&
        typeof obj.fields.registryCourt === 'object') ||
        typeof obj.fields.registryCourt === 'function') &&
        (typeof obj.fields.registryCourt.showIf === 'undefined' ||
          (((obj.fields.registryCourt.showIf !== null &&
            typeof obj.fields.registryCourt.showIf === 'object') ||
            typeof obj.fields.registryCourt.showIf === 'function') &&
            obj.fields.registryCourt.showIf.customerType === 'Business')))) &&
    (typeof obj.fields.registerNumber === 'undefined' ||
      (((obj.fields.registerNumber !== null &&
        typeof obj.fields.registerNumber === 'object') ||
        typeof obj.fields.registerNumber === 'function') &&
        (typeof obj.fields.registerNumber.label === 'undefined' ||
          typeof obj.fields.registerNumber.label === 'string') &&
        (typeof obj.fields.registerNumber.errorMessage === 'undefined' ||
          typeof obj.fields.registerNumber.errorMessage === 'string') &&
        (typeof obj.fields.registerNumber.placeholder === 'undefined' ||
          typeof obj.fields.registerNumber.placeholder === 'string') &&
        (typeof obj.fields.registerNumber.required === 'undefined' ||
          obj.fields.registerNumber.required === false ||
          obj.fields.registerNumber.required === true) &&
        (typeof obj.fields.registerNumber.disabled === 'undefined' ||
          obj.fields.registerNumber.disabled === false ||
          obj.fields.registerNumber.disabled === true) &&
        (typeof obj.fields.registerNumber.defaultValue === 'undefined' ||
          typeof obj.fields.registerNumber.defaultValue === 'string')) ||
      (((obj.fields.registerNumber !== null &&
        typeof obj.fields.registerNumber === 'object') ||
        typeof obj.fields.registerNumber === 'function') &&
        (typeof obj.fields.registerNumber.showIf === 'undefined' ||
          (((obj.fields.registerNumber.showIf !== null &&
            typeof obj.fields.registerNumber.showIf === 'object') ||
            typeof obj.fields.registerNumber.showIf === 'function') &&
            obj.fields.registerNumber.showIf.customerType === 'Business'))))
  )
}
