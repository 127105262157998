import { Hidden } from '@epilot/base-elements'
import {
  PreviousProvider,
  PreviousProviderProps,
  Provider
} from '@epilot/base-modules'
import { rankWith, and, optionIs, isObjectControl } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'

import { EpilotControlProps } from '../../utils'

export type PreviousProviderControlOptions = PreviousProviderProps & {}
function PreviousProviderControl({
  data,
  handleChange,
  path,
  uischema,
  visible,
  errors,
  schema,
  enabled
}: EpilotControlProps) {
  const { label = '' } = uischema || {}
  const { errorMessage } = schema
  const handleOnChange = (provider?: Provider) => {
    handleChange(path, provider || undefined)
  }

  const { ...props } = uischema.options as PreviousProviderControlOptions

  return (
    <Hidden xsUp={!visible}>
      <PreviousProvider
        disabled={!enabled}
        error={
          errors
            ? errorMessage && typeof errorMessage
              ? errorMessage
              : errors
            : undefined
        }
        label={label as string}
        onChange={handleOnChange as never}
        value={data}
        {...props}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(PreviousProviderControl as never)

export const PreviousProviderControlTester = rankWith(
  4,
  and(isObjectControl, optionIs('previous_provider', true))
)
