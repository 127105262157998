import { Product } from '@epilot/base-modules'
import { DEFAULT_FILTER_VALUES_CONFIG_NAME } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React, { useEffect, useState } from 'react'

import { EpilotControlProps } from '../../../utils'
import { includeCommon } from '../../../utils/includeCommon'
import { withQueryProvider } from '../../../utils/react-query'
import {
  PriceEntity,
  ProductEntity,
  useSearchPrices
} from '../../../utils/services/product-service'
import { ProductSelectionControlComponent } from '../_common/ProductSelectionControl'
import {
  JourneyPrice,
  ProductControlOptions
} from '../_common/ProductSelectionControl/types'

type SelectedProduct = Product & {
  selectedProductId: string
  selectionMetadata: {
    selectedProduct: ProductEntity
    selectedPrice: PriceEntity
  }
}

type ProductSelection = {
  product: SelectedProduct
  quantity: number
}

type Filter = {
  [key: string]: ProductSelection[] | ProductSelection
}

const getAllCrossSellProducts = (filter: Filter): ProductEntity[] => {
  return Object.keys(filter).reduce((acc: Product[], filterKey: string) => {
    // depending on selectionOption filter entry can be an array or not
    const filterEntry = Array.isArray(filter[filterKey])
      ? filter[filterKey]
      : [filter[filterKey]]

    const blockCrossSellProducts = (filterEntry as ProductSelection[]).reduce(
      (acc1: Product[], productSelection: ProductSelection) => {
        const productCrossSellProducts =
          productSelection.product?.selectionMetadata?.selectedProduct
            .cross_sellable_products

        if (!productCrossSellProducts) {
          return acc1
        }

        return [...acc1, ...productCrossSellProducts]
      },
      []
    )

    if (!blockCrossSellProducts) {
      return acc
    }

    return [...acc, ...blockCrossSellProducts]
  }, [])
}

export function CrossSellProductSelectionControlComponent(
  props: EpilotControlProps
) {
  const filter = props.uischema?.options?.[DEFAULT_FILTER_VALUES_CONFIG_NAME]
  const allCrossSellProducts = filter ? getAllCrossSellProducts(filter) : []

  const { products: journeyPrices, orgId }: ProductControlOptions = (props
    .uischema?.options as ProductControlOptions) || {
    products: [],
    orgId: ''
  }

  const filteredJourneyPrices = journeyPrices.filter(
    ({ productId }: JourneyPrice) =>
      !!allCrossSellProducts.find(({ _id }) => productId === _id)
  )

  const {
    isLoading,
    results,
    hits: resultsHits
  } = useSearchPrices(orgId, filteredJourneyPrices)

  const [availableProducts, setAvailableProducts] =
    useState<(ProductEntity | PriceEntity)[]>()

  const isProductSellable = (selectedItem: SelectedProduct) => {
    return !!availableProducts?.find(
      (product: ProductEntity | PriceEntity) =>
        product._id === selectedItem.selectedProductId &&
        (product.type as any) === 'product'
    )
  }

  useEffect(() => {
    if (results) {
      setAvailableProducts(results)
    }
  }, [results])

  useEffect(() => {
    if (props.data && availableProducts?.length) {
      const isMultiSelection = Array.isArray(props.data)
      const data = isMultiSelection ? props.data : [props.data]
      const filteredData = data?.filter((selectedItem: ProductSelection) =>
        isProductSellable(selectedItem.product)
      )

      props.handleChange(
        props.path,
        isMultiSelection ? filteredData : filteredData?.[0]
      )
    }
  }, [availableProducts])

  return (
    <ProductSelectionControlComponent
      {...props}
      isLoading={isLoading}
      results={results}
      resultsHits={resultsHits}
    />
  )
}

export const ProductSelectionControl = withQueryProvider<EpilotControlProps>(
  CrossSellProductSelectionControlComponent
)

export default withJsonFormsControlProps(
  includeCommon({
    component: ProductSelectionControl
  }) as never
)
