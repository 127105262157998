/* eslint-disable react/display-name */
import React from 'react'

import { applyFilters } from './applyFilters'
import { WithFiltersProps } from './types'

/**
 * HOC replacing/filtering prop defined by optionKey
 * @param filters = uischema.options.filters
 * @param _filterValues = uischema.options._filterValues; added to config on runtime; contains users selected values
 * @param optionKey = defines which of the props should be filtered/replaced
 * @param matchKey = defines which key of the values (keyof optionKey) should be used to filter
 */

export const withFilters = <P extends Record<string, unknown>>(
  Component: React.ComponentType<P>
): React.FC<P & WithFiltersProps<P>> => {
  return (props: P & WithFiltersProps<P>) => {
    const { filters, _filterValues, optionKey, matchKey } = props

    // if not defined, pass through component
    if (!optionKey) return <Component {...props} />

    // find initialValues based on specified key
    const initialValues = props[optionKey]

    if (!initialValues || !Array.isArray(initialValues)) {
      return <Component {...props} />
    }

    // apply filters and replace prop by optionKey
    const values = applyFilters(
      filters,
      _filterValues,
      initialValues,
      matchKey || ''
    )
    const injectedProps = { [optionKey]: values }

    return <Component {...props} {...injectedProps} />
  }
}
