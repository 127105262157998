import * as React from 'react'

function SvgProductChp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="1em"
      viewBox="0 0 1024 1024"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M52.8 180.8c-3.66 3.66-4.8 89.78-4.8 363.216v358.412l10.132 20.336c6.332 12.704 16.332 24.628 26.66 31.784L101.316 966h821.368l16.616-11.516c10.628-7.364 20.24-18.924 26.68-32.084l10.064-20.568-1.024-359.48c-.956-337.372-1.452-359.752-8.02-363.896-5.104-3.22-8.896-3.22-14 0-6.46 4.072-7.08 17.456-8.044 172.98L943.912 520H848v-63.404c0-68.392-2.984-83.968-19.544-101.952C804.48 328.6 822.48 330 512 330s-292.48-1.4-316.456 24.644C178.984 372.628 176 388.204 176 456.596V520H80V352.8C80 180.188 79.62 176 64 176c-3.52 0-8.56 2.16-11.2 4.8m745.76 189.04c17.3 12.12 17.708 17.952 16.544 237.156L814 815.824 791.82 838H232.18L210 815.824l-1.104-208.828c-1.164-219.204-.756-225.036 16.544-237.156 12.524-8.768 560.596-8.768 573.12 0M563.7 411.28c-3.464 1.496-39 39.62-78.972 84.72-83.492 94.208-84.82 97.016-54.364 115.1 35.148 20.868 33.692 11.656 14.268 90.184-18.752 75.808-18.596 84.064 1.704 89.156 12.648 3.176 12.644 3.18 97.06-89.808 77.064-84.888 79.14-89.22 51.844-108.264-37.236-25.98-34.916-14.824-18.04-86.792 17.428-74.32 17.984-82.892 5.908-91.348-9.736-6.824-10.248-6.9-19.408-2.948m-23.884 112.912c-6.544 27.392-11.052 53.408-10.02 57.808 1.036 4.4 12.024 14.052 24.416 21.448l22.536 13.448-13.636 14.552c-7.5 8.004-30.444 33.028-50.984 55.612-20.54 22.58-38.008 40.392-38.82 39.584-.812-.812 3.964-23.744 10.608-50.96C490.564 648.468 496 623.136 496 619.392c0-4.056-8.884-11.888-22-19.392-12.1-6.924-22-14.2-22-16.168 0-4.248 96.348-112.82 98.344-110.82.752.752-3.984 23.784-10.528 51.18M176 616v64H80V552h96v64m768 0v64h-96V552h96v64M176 763.152c0 45.16 1.056 53.216 9 68.768 10.6 20.744 23.724 31.244 45.308 36.252 20.476 4.748 542.908 4.748 563.384 0 21.584-5.008 34.708-15.508 45.308-36.252 7.944-15.552 9-23.608 9-68.768V712h96l-.016 93c-.012 86.224-.604 93.964-8.088 106.24C920.108 937.136 939.568 936 512 936s-408.108 1.136-423.896-24.76c-7.484-12.276-8.076-20.016-8.088-106.24L80 712h96v51.152" />
    </svg>
  )
}

export default SvgProductChp
