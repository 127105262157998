import regExpStatments from './reg_exps'
import IBAN from 'iban'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

/**
 * Validates that a date is actually valid
 * @param data date as string in DD.MM.YYYY
 * @returns boolean
 */
export function validateDate(data: string): boolean {
  return dayjs(data, 'DD.MM.YYYY', true).isValid()
}

/**
 * Validate date in german format is in the past
 * @param data date as string DD.MM.YYY
 * @returns boolean
 */
export function validateBirthDate(data: string): boolean {
  const de_date = regExpStatments.date_de.regex
  // check if the data format matches the de format and the date in the past

  if (de_date.test(data)) {
    if (parseDateDE(data).getTime() < new Date().getTime()) {
      return true
    }
  }

  return false
}

/**
 * Validate date is 14 days in the future
 * @param data date as string DD.MM.YYY
 * @returns boolean
 */
export function validateFutureDate(data: string): boolean {
  const de_date = regExpStatments.date_de.regex
  // check if the data format matches the de format

  if (de_date.test(data)) {
    if (parseDateDE(data).getTime() > new Date().getTime()+(14*24*60*60*1000)) {
      return true
    }
  }

  return false
}

export function parseDateDE(dateStr: string): Date {
  const dateArray = dateStr.split('.')
  const date = new Date()

  date.setFullYear(+dateArray[2], +dateArray[1] - 1, +dateArray[0])

  return date
}

type FunctionalValidators = {
  [key: string]: {
    name: string
    callback: (data: string) => boolean
    description: string
  }
}

/**
 * Validate iban and validate its syntax
 * @param data iban string
 * @returns boolean
 */
export function validateIBAN(data: string): boolean {
  return IBAN.isValid(data)
}

export const functionalValidators: FunctionalValidators = {
  iban: {
    name: 'iban',
    callback: validateIBAN,
    description:
      'this validation function will validate the syntax of the provided iban'
  },
  birth_date: {
    name: 'birth_date',
    callback: validateBirthDate,
    description:
      'this function will validate that the date is in the past'
  },
  future_date_14: {
    name: 'future_date_14',
    callback: validateFutureDate,
    description:
      'this function will validate that the date is in the future with 14 days at least'
  }
}

export const IBAN_Specifications = IBAN.countries