import { useState } from 'react'

import { JOURNEY_LOADING_EVENT } from '../../../utils/constants/EventConstant'
import {
  EventDetailType,
  isLoadingEventPayload
} from '../../../utils/constants/types'
import useListener from '../../../utils/hooks/useListener'

const useLoadingListener = () => {
  const [loading, setLoading] = useState<'buttonSubmit' | ''>('')

  const loadingListener = async (e: CustomEvent<EventDetailType>) => {
    const { eventCaller, payload } = e.detail

    console.info('The event is triggered by', eventCaller)

    if (!isLoadingEventPayload(payload)) {
      console.error(
        `Error when receiving event: ${JOURNEY_LOADING_EVENT}. Incorrect usage of payload`
      )

      return
    }

    if (payload.item === 'buttonSubmit')
      setLoading(payload.value ? 'buttonSubmit' : '')
  }

  useListener(JOURNEY_LOADING_EVENT, loadingListener)

  return loading
}

export default useLoadingListener
