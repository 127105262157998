import { Hidden, Icon, IconProps } from '@epilot/base-elements'
import { LayoutProps, rankWith, uiTypeIs, or } from '@jsonforms/core'
import { withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'

export type IconRendererOptions = IconProps & {}

const IconRenderer = ({ uischema, visible }: LayoutProps) => {
  const { ...rest } = (uischema.options as IconRendererOptions) || {}

  return (
    <Hidden xsUp={!visible}>
      <Icon {...rest}></Icon>
    </Hidden>
  )
}

export const IconRendererTester = rankWith(2, or(uiTypeIs('Icon')))

export default withJsonFormsLayoutProps(IconRenderer)
