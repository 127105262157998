/*
 * Generated type guards for "types.ts".
 * WARNING: Do not manually change this file.
 */
import { isEnumButtonControlData } from '../EnumButtonControl/types.guard'

import { NumberInputControlOptions, NumberInputControlData } from './types'

export function isNumberInputControlOptions(
  obj: any,
  _argumentName?: string
): obj is NumberInputControlOptions {
  return (
    ((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
    ((obj.fields !== null && typeof obj.fields === 'object') ||
      typeof obj.fields === 'function') &&
    ((((obj.fields.numberInput !== null &&
      typeof obj.fields.numberInput === 'object') ||
      typeof obj.fields.numberInput === 'function') &&
      (isEnumButtonControlData(obj.fields.numberInput.label) as boolean) &&
      (isEnumButtonControlData(
        obj.fields.numberInput.errorMessage
      ) as boolean) &&
      (isEnumButtonControlData(
        obj.fields.numberInput.placeholder
      ) as boolean) &&
      (typeof obj.fields.numberInput.disabled === 'undefined' ||
        obj.fields.numberInput.disabled === false ||
        obj.fields.numberInput.disabled === true) &&
      (isEnumButtonControlData(
        obj.fields.numberInput.defaultValue
      ) as boolean)) ||
      (((obj.fields.numberInput !== null &&
        typeof obj.fields.numberInput === 'object') ||
        typeof obj.fields.numberInput === 'function') &&
        (typeof obj.fields.numberInput.unit === 'undefined' ||
          (((obj.fields.numberInput.unit !== null &&
            typeof obj.fields.numberInput.unit === 'object') ||
            typeof obj.fields.numberInput.unit === 'function') &&
            (typeof obj.fields.numberInput.unit.show === 'undefined' ||
              obj.fields.numberInput.unit.show === false ||
              obj.fields.numberInput.unit.show === true) &&
            (isEnumButtonControlData(
              obj.fields.numberInput.unit.label
            ) as boolean))) &&
        (typeof obj.fields.numberInput.format === 'undefined' ||
          (((obj.fields.numberInput.format !== null &&
            typeof obj.fields.numberInput.format === 'object') ||
            typeof obj.fields.numberInput.format === 'function') &&
            (typeof obj.fields.numberInput.format.show === 'undefined' ||
              obj.fields.numberInput.format.show === false ||
              obj.fields.numberInput.format.show === true) &&
            (typeof obj.fields.numberInput.format.validate === 'undefined' ||
              obj.fields.numberInput.format.validate === false ||
              obj.fields.numberInput.format.validate === true) &&
            (typeof obj.fields.numberInput.format.digitsBeforeDecimalPoint ===
              'undefined' ||
              typeof obj.fields.numberInput.format.digitsBeforeDecimalPoint ===
                'number') &&
            (typeof obj.fields.numberInput.format.decimalPlaces ===
              'undefined' ||
              typeof obj.fields.numberInput.format.decimalPlaces ===
                'number'))))) &&
    (typeof obj.required === 'undefined' ||
      obj.required === false ||
      obj.required === true)
  )
}

export function isNumberInputControlData(
  obj: any,
  _argumentName?: string
): obj is NumberInputControlData {
  return (
    typeof obj === 'undefined' ||
    obj === null ||
    (((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
      (typeof obj.numberInput === 'undefined' ||
        obj.numberInput === null ||
        typeof obj.numberInput === 'string') &&
      (isEnumButtonControlData(obj.numberUnit) as boolean))
  )
}
