import { Button } from '@epilot/base-elements'
import { schemaTypeIs, rankWith } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'

import { EpilotControlProps } from '../../utils'

function ButtonControl({
  data,
  uischema,
  enabled,
  schema
}: EpilotControlProps) {
  const { label } = uischema

  const { examples } = schema

  const placeholder =
    Array.isArray(examples) && examples[0] ? examples[0] : label

  return (
    <Button
      disabled={!enabled}
      fullWidth
      placeholder={placeholder}
      value={data}
    />
  )
}

export default withJsonFormsControlProps(ButtonControl as never)

export const ButtonTester = rankWith(3, schemaTypeIs('button'))
