import * as React from 'react'

function SvgProductStorage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="1em"
      viewBox="0 0 1024 1024"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M411.628 57.308c-24.776 13.08-40.48 36.772-42.784 64.552L367.008 144H334.1c-18.096 0-39.024 2.04-46.504 4.536-20.268 6.764-38.204 25.712-43.228 45.664-6.276 24.924-6.1 715.432.188 734.204 6.116 18.26 18.152 31.132 37.444 40.036 14.924 6.888 30.392 7.384 230 7.384s215.076-.496 230-7.384c19.292-8.904 31.328-21.776 37.444-40.036 6.288-18.772 6.464-709.28.188-734.204-5.024-19.952-22.96-38.9-43.228-45.664-7.48-2.496-28.408-4.536-46.504-4.536h-32.908l-1.836-22.14c-2.304-27.78-18.008-51.472-42.784-64.552C595.56 48.432 590.896 48 512 48s-83.56.432-100.372 9.308m196.74 37.204c9.26 9.268 13.044 17.312 14.588 31L625.04 144H398.96l2.056-18.248c2.12-18.804 13.6-35.464 28.984-42.056 4.4-1.884 43.52-3.036 86.932-2.56l78.932.864 12.504 12.512m130.544 94.58L750 200.18v719.64L727.82 942H296.18L274 919.82V560.328c0-353.856.124-359.628 8-368.38 4.4-4.892 11.6-10.388 16-12.22s102.912-2.968 218.912-2.528l210.912.8 11.088 11.092m-248.244 65.576c-1.468 1.464-2.668 19.4-2.668 39.852v37.192l-39 1.144c-37.284 1.092-39.056 1.548-40.308 10.364-2.42 17.076 5.356 20.78 43.648 20.78h35.36l1.148 36.576c.936 29.676 2.472 37.408 8.152 40.98 5.092 3.204 8.908 3.204 14 0 5.68-3.572 7.216-11.304 8.152-40.98L520.3 364h35.36c39.152 0 47.184-4.128 42.712-21.948-2.476-9.852-3.28-10.052-40.448-10.052H520v-33.672c0-18.52-1.092-36.52-2.428-40-2.412-6.288-21.66-8.904-26.904-3.66M433 828c-6.012 2.5-9 7.14-9 13.972 0 16.196 13.472 18.644 95.04 17.264 74.388-1.256 80.836-2.632 80.836-17.236 0-14.712-6.244-15.984-82.724-16.872-41.332-.476-79.204.816-84.152 2.872" />
    </svg>
  )
}

export default SvgProductStorage
