import { useState, useEffect, MutableRefObject } from 'react'

export const useResize = (myRef: MutableRefObject<HTMLDivElement | null>) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      if (myRef && myRef.current) {
        setWidth(myRef.current.offsetWidth)
        setHeight(myRef.current.offsetHeight)
      }
    }

    // invoked when myRef changed, so basically when ref is set initially
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [myRef])

  return { width, height }
}
