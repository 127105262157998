import { Hidden } from '@epilot/base-elements'
import { BooleanControl } from '@epilot/base-modules'
import {
  schemaTypeIs,
  rankWith,
  optionIs,
  and,
  isEnumControl,
  or
} from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React, { ChangeEvent } from 'react'

import { EpilotControlProps, stringToHTML } from '../../utils'
import { includeCommon } from '../../utils/includeCommon'

type BooleanSwitchControlOptions = {
  clearPathOnChange?: string[]
  plainTextLabel?: boolean
}

function BooleanSwitchControl({
  data,
  handleChange,
  path,
  uischema,
  errors,
  enabled,
  required,
  schema,
  visible
}: EpilotControlProps) {
  const { clearPathOnChange = [], plainTextLabel } =
    (uischema.options as BooleanSwitchControlOptions) || {}
  const handleOnChange = (_event: ChangeEvent<HTMLInputElement>) => {
    if (clearPathOnChange && clearPathOnChange.length > 0) {
      clearPathOnChange.forEach((path) => {
        handleChange(path, undefined)
      })
    }
    if (required) {
      data ? handleChange(path, null) : handleChange(path, true)
    } else {
      handleChange(path, !data)
    }
  }

  const { errorMessage } = schema
  const { label, options = {} } = uischema

  return (
    <Hidden xsUp={!visible}>
      <BooleanControl
        disabled={!enabled}
        errorMessage={
          errors
            ? errorMessage && typeof errorMessage
              ? errorMessage
              : errors
            : ''
        }
        label={stringToHTML(label as string, {
          isPlainText: plainTextLabel
        })}
        onChange={handleOnChange}
        required={required}
        typographyVariant={options?.typographyVariant || 'body1'}
        uiType="switch"
        value={data ? true : false}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: BooleanSwitchControl }) as never
)

export const SwitchTester = rankWith(
  6,
  or(
    and(schemaTypeIs('boolean'), optionIs('toggle', true)),
    and(isEnumControl, and(schemaTypeIs('boolean'), optionIs('toggle', true)))
  )
)
