import * as React from 'react'

function SvgPerson(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M16 6a4 4 0 11-8 0 4 4 0 018 0zm-4 6a6 6 0 100-12 6 6 0 000 12zm12 10c0 2-2 2-2 2H2s-2 0-2-2 2-8 12-8 12 6 12 8zm-2-.007c-.003-.493-.308-1.972-1.664-3.329C19.03 17.36 16.578 16 12 16s-7.031 1.36-8.336 2.664C2.307 20.021 2.003 21.5 2 21.993h20z"
      />
    </svg>
  )
}

export default SvgPerson
