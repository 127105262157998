import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'

import { EpilotControlProps } from '../../../utils'
import { includeCommon } from '../../../utils/includeCommon'
import { withQueryProvider } from '../../../utils/react-query'
import { useSearchPrices } from '../../../utils/services/product-service'
import { ProductSelectionControlComponent as ProductSelectionControlComponentCommon } from '../_common/ProductSelectionControl'
import { ProductControlOptions } from '../_common/ProductSelectionControl/types'

export function ProductSelectionControlComponent(props: EpilotControlProps) {
  const { products: journeyPrices, orgId }: ProductControlOptions = (props
    .uischema?.options as ProductControlOptions) || {
    products: [],
    orgId: ''
  }

  const {
    isLoading,
    results,
    hits: resultsHits
  } = useSearchPrices(orgId, journeyPrices)

  return (
    <ProductSelectionControlComponentCommon
      {...props}
      isLoading={isLoading}
      results={results}
      resultsHits={resultsHits}
    />
  )
}

export const ProductSelectionControl = withQueryProvider<EpilotControlProps>(
  ProductSelectionControlComponent
)

export default withJsonFormsControlProps(
  includeCommon({
    component: ProductSelectionControl
  }) as never
)
