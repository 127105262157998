import { EIcon, Hidden, IconsId } from '@epilot/base-elements'
import {
  ConsumptionCalculator,
  ConsumptionCalculatorData,
  ConsumptionCalculatorProps
} from '@epilot/base-modules'
import { rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'

import { EpilotControlProps } from '../../utils'

function ConsumptionCalculatorControl({
  data,
  handleChange,
  path,
  uischema,
  required,
  errors,
  schema,
  visible
}: EpilotControlProps) {
  const {
    icon,
    iconComponent,
    variant,
    error: unused_error,
    helperText: unused_helperText,
    value: unused_value,
    required: unused_required,
    onChange: unused_onChange,
    ...rest
  } = uischema.options as ConsumptionCalculatorProps & {
    icon?: string
  }

  const { errorMessage } = schema || errors
  const handleValueChange = (
    value: ConsumptionCalculatorData | null | undefined
  ) => {
    const isEmptyValue =
      !value || Object.values(value).every((v) => !v || v === 0)

    handleChange(path, isEmptyValue ? undefined : value)
  }

  return (
    <Hidden xsUp={!visible}>
      <ConsumptionCalculator
        error={
          errors
            ? errorMessage && typeof errorMessage
              ? errorMessage
              : errors
            : undefined
        }
        helperText={
          errors
            ? errorMessage && typeof errorMessage
              ? errorMessage
              : errors
            : ''
        }
        iconComponent={<EIcon name={icon as IconsId} />}
        onChange={handleValueChange}
        required={required}
        value={data}
        variant={variant}
        {...rest}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(ConsumptionCalculatorControl as never)

export const ConsumptionCalculatorTester = rankWith(
  5,
  uiTypeIs('ConsumptionCalculatorControl')
)
