import * as React from 'react'

function SvgProcessOffering(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="1em"
      viewBox="0 0 1024 1024"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M88 532v492h892V308L712 44H88v488zm596-320v128h256v640H128V84h556v128zM300 320v20h212v-40H300v20zm0 212v24h468v-44H300v20zm0 212v24h468v-44H300v20z" />
    </svg>
  )
}

export default SvgProcessOffering
