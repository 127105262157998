import { Autocomplete } from '@epilot/base-elements'
import { toDashCase, usePrevious } from '@epilot/journey-logic-commons'
import React, { useState, useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FieldAutocompleteProps } from './types'

export const FieldAutocomplete = <
  T extends Record<string, unknown>, // fields value type
  O extends string | Record<string, unknown> = string, // options
  S extends string | Record<string, unknown> = string // suggestions
>(
  props: FieldAutocompleteProps<T, O, S>
) => {
  const {
    hasError,
    control,
    name,
    label,
    placeholder,
    errorMessage,
    defaultValue,
    required,
    disabled,
    rules,
    rulesCustomErrorMessages,
    path,
    acceptSuggestedOnly,
    noOptionsText,
    options,
    minChars = 2,
    onBlur,
    getSuggestions,
    onChange,
    onInputChange,
    getOptionLabel,
    getOptionSelected,
    freeSolo
  } = props

  const { t } = useTranslation()
  const id = `${toDashCase(path)}-${name}`

  const [inputValue, setInputValue] = useState('')
  const inputValuePrev = usePrevious(inputValue)

  useEffect(() => {
    // getSuggestions would be rebuild if related values change, which should not trigger the call
    if (inputValue !== inputValuePrev) {
      getSuggestions(inputValue)
    }
  }, [inputValue, inputValuePrev, getSuggestions])

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <Autocomplete
            {...field}
            ListboxProps={{
              style: {
                maxHeight: '120px'
              }
            }}
            disableOpenOnFocus={!inputValue || inputValue.length > minChars}
            disabled={disabled}
            error={
              hasError && fieldState.invalid
                ? acceptSuggestedOnly && !field.value && inputValue
                  ? t('no_selected_suggestion')
                  : errorMessage ||
                    fieldState.error?.message ||
                    rulesCustomErrorMessages?.[fieldState.error?.type || ''] ||
                    t('field_required')
                : ''
            }
            freeSolo={freeSolo ?? !acceptSuggestedOnly}
            fullWidth
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            id={id}
            inputValue={inputValue}
            label={label}
            multiple={false}
            noOptionsText={noOptionsText || t('no_options')}
            // select first option if blurring out
            onBlur={() => {
              if (onBlur) {
                onBlur(options, field, setInputValue)

                return
              }

              if (options && options.length === 1) {
                field.onChange(options[0])
              } else if (acceptSuggestedOnly && !field.value) {
                setInputValue('')
              }
            }}
            // when selecting option
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(_: unknown, data: any) => {
              if (onChange) {
                onChange(data, field)

                return
              }

              if (typeof data === 'string') {
                return field.onChange(data)
              } else {
                return field.onChange('')
              }
            }}
            // when typing
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onInputChange={(_, value) => {
              if (onInputChange) {
                onInputChange(value, field, setInputValue)

                return
              }

              field.onChange(
                !acceptSuggestedOnly ? value : field.value ? field.value : null
              ) // keep state empty until selecting value or having it initially. Still update it to go through validation

              setInputValue(value)
            }}
            options={options}
            placeholder={placeholder}
            value={typeof field.value === 'string' ? field.value : ''}
          />
        )
      }}
      rules={{
        required: !!required,
        validate: {
          noSelectedSuggestion: (value) => {
            // dont validate further if no value. Other, internal rules still validate
            if (acceptSuggestedOnly && !value && inputValue) {
              return false
            }

            return true
          }
        },
        ...rules
      }}
    />
  )
}
