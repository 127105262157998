import { Hidden } from '@epilot/base-elements'
import {
  EnumProductControl,
  EnumProductControlProps
} from '@epilot/base-modules'
import { rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'

import { EpilotControlProps } from '../../utils'

export type ProductControlOptions = Omit<
  EnumProductControlProps,
  'value' | 'onChange'
> & {}
function ProductControl({
  data,
  handleChange,
  path,
  uischema,
  visible
}: EpilotControlProps) {
  const handleOnChange = (value: any) => {
    handleChange(path, value)
  }

  const { ...props } = uischema.options as ProductControlOptions

  return (
    <Hidden xsUp={!visible}>
      <EnumProductControl onChange={handleOnChange} value={data} {...props} />
    </Hidden>
  )
}

export default withJsonFormsControlProps(ProductControl as never)

export const ProductControlTester = rankWith(4, uiTypeIs('ProductControl'))
