import * as React from 'react'

function SvgProcessOperations(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="1em"
      viewBox="0 0 1024 1024"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M480 48q-8 0-52 8-72 16-138 54t-118 94Q92 292 62 410t-4 234 106 208q88 100 212 144t256 18 236-124 136-230q40-144-8-286T828 144q-44-36-102-60T608 48q-28-4-72-4t-60 4h4zm152 48q120 28 212 116t124 212q8 24 8 40v64q0 56-2 80t-14 64q-32 100-110 178T672 960q-40 12-76 16t-86 2-90-12-84-30q-104-52-170-148T88 572q-12-100 24-196t112-164Q328 112 476 88q24-4 74-2t78 10h4zM512 256v44l44-4v-84h-44v44zm-208 52l-12 12 152 156-8 16q-12 28-4 62t28 58q32 28 74 26t74-30q16-16 22-34t6-38q4-44-24-74t-72-34q-16 0-26 2t-22 6l-16 8-76-76q-80-76-82-76t-14 16zm412 16l-28 28 24 28 60-56-12-16q-12-12-16-12t-28 28zM568 480q28 20 28 50t-16 48-46 18-50-22-16-54q4-12 16-26t24-20 30-4 30 10zm-356 52l4 20h84v-40h-88v20zm556 0v24h84v-44h-84v20z" />
    </svg>
  )
}

export default SvgProcessOperations
