import { Button as ButtonBase, ButtonProps } from '@epilot/base-elements'
import React, { useEffect } from 'react'
/**
 * Wraps Base Elements Button, forwards all props + refs to it
 * If unmounts, removes ref
 */
export const Button = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & { deleteRef: () => void }
>(({ deleteRef, ...props }, ref) => {
  useEffect(() => {
    return () => {
      deleteRef()
    }
  }, [])

  return <ButtonBase ref={ref} {...props} />
})

Button.displayName = 'Button'
