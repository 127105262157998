import * as React from 'react'

function SvgPersonOldDesign(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.5 22v-7.5H8V9c0-1.1.9-2 2-2h3c1.1 0 2 .9 2 2v5.5h-1.5V22h-4zm2-16c1.11 0 2-.89 2-2 0-1.11-.89-2-2-2-1.11 0-2 .89-2 2 0 1.11.89 2 2 2z" />
    </svg>
  )
}

export default SvgPersonOldDesign
