import { Box, CircularProgress } from '@epilot/base-elements'
import {
  EnumProductControl as ProductControl,
  EnumProductControlData,
  EnumProductControlProps,
  Product
} from '@epilot/base-modules'
import { formatAmount } from '@epilot/pricing'
import { rankWith, uiTypeIs } from '@jsonforms/core'
import React, { CSSProperties, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EpilotControlProps, getCurrencySymbol } from '../../../../utils'
import NoProductsIcon from '../../../../utils/NoProductsIcon'
import { RecurrenceInterval } from '../../../../utils/services/product-service'
import type {
  ProductEntity,
  PriceEntity
} from '../../../../utils/services/product-service'
import { withFilters } from '../../../../utils/withFilters'

import ImageStepper from './components/ImageStepper'
import { JourneyPrice, ProductControlOptions, TFunction, File } from './types'

type ProductSelectionControlProps = EpilotControlProps & {
  isLoading: boolean
  results?: (ProductEntity | PriceEntity)[]
  resultsHits?: number
}

const panelStyles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  flexDirection: 'column'
}

export const defaultImageStyles: CSSProperties = {
  background: 'rgb(0 0 0/ 10%)',
  height: 'auto',
  width: '100%',
  objectFit: 'cover',
  aspectRatio: '3/2',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center'
}

const defaultImageIconStyles = {
  color: 'primary',
  fontSize: '90px',
  display: 'inline-flex'
}

const defaultImage = (
  <div style={{ marginBottom: '34px' }}>
    <div style={defaultImageStyles}>
      <i
        className="epilot-icon epilot-icon-box  notranslate"
        style={defaultImageIconStyles}
      ></i>
    </div>
  </div>
)

const NoProducts = ({ t }: { t: TFunction }) => (
  <Box sx={panelStyles}>
    <div style={{ height: '135px' }}>
      <div style={{ marginTop: '-15px' }}>
        <NoProductsIcon />
      </div>
    </div>
    <div>
      <h2>{t('No Products Available')}</h2>
    </div>
  </Box>
)

// inject data with filters
const EnumProductControl = withFilters((props: EnumProductControlProps) => (
  <ProductControl {...props} />
))

const getActionButtonLabel = (
  t: TFunction,
  ctaTextOption: Pick<ProductControlOptions, 'ctaTextOption'>['ctaTextOption'],
  removeOption?: boolean
) => {
  switch (ctaTextOption) {
    case 'cta_add_to_cart':
    case 'cta_select':
    case 'cta_subscribe':
    case 'cta_request_quote':
      return t(`${ctaTextOption}${removeOption ? '_undo' : ''}`)
    default:
      return t(`cta_add_to_cart${removeOption ? '_undo' : ''}`)
  }
}

export function ProductSelectionControlComponent({
  handleChange,
  data,
  path,
  uischema,
  isLoading,
  resultsHits,
  results
}: ProductSelectionControlProps) {
  const {
    products: journeyPrices,
    orgId,
    ctaTextOption,
    ...rest
  }: ProductControlOptions = (uischema?.options as ProductControlOptions) || {
    products: [],
    orgId: ''
  }

  const { t } = useTranslation(undefined, {
    keyPrefix: 'product.selection',
    useSuspense: true
  })

  const [entitiesMap, setEntitiesMap] = useState<
    Record<string, ProductEntity | PriceEntity>
  >({})

  useEffect(() => {
    if (resultsHits && resultsHits > 0) {
      const mapOfEntities = (results || []).reduce(
        (map, entity) => ({ ...map, [entity._id as string]: entity }),
        {}
      )

      setEntitiesMap(mapOfEntities || {})
    }
  }, [resultsHits, results])

  const handleOnChange = (
    value: EnumProductControlData | EnumProductControlData[] | undefined
  ) => {
    /**
     * Remove the productImage from the selection.
     * Currently they are a react component and is
     * causing a circular structure JSON.
     */
    if (value) {
      if (Array.isArray(value)) {
        for (const item of value) {
          delete item.product.productImage
        }
      } else {
        delete value.product.productImage
      }
    }
    handleChange(path, value)
  }

  if (isLoading || (!!resultsHits && Object.keys(entitiesMap).length === 0)) {
    return (
      <Box sx={panelStyles}>
        <CircularProgress color="inherit" size={40} />
      </Box>
    )
  }

  if (resultsHits === 0) {
    return <NoProducts t={t} />
  }

  let showTileImages = true

  if (uischema?.options?.showImages !== undefined) {
    showTileImages = uischema?.options?.showImages
  }

  let featuresAmountLimit: number | undefined

  if (
    uischema?.options?.featuresLimit !== undefined &&
    uischema?.options?.featuresLimit?.trim() !== '' &&
    uischema?.options?.featuresLimit !== null
  ) {
    featuresAmountLimit = !isNaN(
      Number(uischema?.options?.featuresLimit?.trim())
    )
      ? Number(uischema?.options?.featuresLimit)
      : undefined
  }

  return (
    <EnumProductControl
      matchKey="selectedPriceId"
      onChange={handleOnChange}
      optionKey="options"
      options={
        toProductTiles(
          journeyPrices,
          entitiesMap,
          showTileImages,
          featuresAmountLimit,
          t
        ) || []
      }
      value={data}
      {...rest}
      actionButtonLabel={getActionButtonLabel(t, ctaTextOption)}
      actionButtonRemoveLabel={getActionButtonLabel(t, ctaTextOption, true)}
      productFeaturesTitle=" "
    />
  )
}

function toProductTiles(
  journeyPrices: JourneyPrice[],
  entitiesMap: Record<string, ProductEntity | PriceEntity>,
  showTileImages: boolean,
  featuresAmountLimit: number | undefined,
  t: TFunction
): Product[] | null {
  if (!entitiesMap || Object.keys(entitiesMap).length === 0) {
    return null
  }

  return journeyPrices
    .map(({ productId, priceId }) => {
      const product: ProductEntity & {
        feature: { feature: string }[]
      } = entitiesMap[productId] as ProductEntity & {
        feature: { feature: string }[]
      }
      const price: PriceEntity = entitiesMap[priceId] as PriceEntity

      if (!product || !price) {
        return null
      }

      const priceType: string = price.type as string
      const billing_period: string =
        price.billing_period || RecurrenceInterval.Monthly
      const value = formatAmount({
        integerAmount: price.unit_amount || 0,
        format: '0.00'
      })
      const floatValue = value ? parseFloat(value) : ''

      const priceInfo = {
        price: floatValue,
        priceCurrency: getCurrencySymbol(price.unit_amount_currency || 'EUR'),
        title:
          priceType === 'recurring'
            ? t(`${priceType}.${billing_period}`)
            : t(priceType),
        interval:
          priceType === 'recurring'
            ? RecurrenceInterval[billing_period]
            : priceType && priceType.replace(/ /g, '').toLowerCase(),
        priceDisplayType: price.price_display_in_journeys,
        computeValue: price.unit_amount_decimal
      }

      switch (price?.price_display_in_journeys) {
        case 'show_as_starting_price': {
          priceInfo.price = `${t(
            'show_as_starting_price'
          )} ${floatValue.toLocaleString(navigator.language)}`
          break
        }
        case 'show_as_on_request': {
          priceInfo.price = t('show_as_on_request')
          priceInfo.priceCurrency = ''
          priceInfo.title = ''
          break
        }
        default: {
          break
        }
      }

      let features: { checked: boolean; text: string }[] = []

      if (product.feature) {
        features = product.feature?.map(({ feature }) => ({
          checked: true,
          text: feature
        }))
      }
      if (featuresAmountLimit !== undefined) {
        features.splice(featuresAmountLimit, features.length)
      }

      let productImage: string | React.ReactNode = null

      const productImages = product?.product_images?.filter(
        (productImage: File) => !!productImage._id
      )

      if (productImages?.length > 0) {
        productImage = (
          <ImageStepper
            images={productImages?.map(
              ({ name, public_url }: { name: string; public_url: string }) => ({
                label: name,
                imgPath: public_url?.split('?')?.[0]
              })
            )}
          />
        )
      } else {
        productImage = defaultImage
      }

      interface ProductDownloadItem {
        filename: string
        public_url: string
        mime_type: string
      }

      const productDownloads = product?.product_downloads?.filter(
        (productDownload: File) => !!productDownload._id
      )

      const downloadItems = productDownloads?.map(
        ({
          filename: fileName,
          public_url: fileUrl,
          mime_type
        }: ProductDownloadItem) => ({
          fileName,
          fileUrl,
          mime_type
        })
      )

      return {
        selectedProductId: product._id,
        selectedPriceId: price._id,
        selectionMetadata: {
          selectedProduct: product,
          selectedPrice: price
        },
        productImage: showTileImages ? productImage : [],
        productName: product.name,
        prices: [priceInfo],
        productFeatures: features,
        collapsedDetails: downloadItems?.length > 0 ? true : false,
        attachments: downloadItems,
        id: `${product._id}||${price._id}`
      }
    })
    .filter(Boolean) as Product[]
}

export const ProductSelectionControlTester = rankWith(
  4,
  uiTypeIs('ProductSelectionControl')
)
