import { Grid } from '@epilot/base-elements'
import { BooleanControl } from '@epilot/base-modules'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { mdToHTML } from '../../../utils'

import { ConsentBox, Consents } from './types'
import { getConsentsMap } from './utils'

type ConsentBlocksProps = {
  error?: string
  consents: ConsentBox[]
  data: Consents | undefined
  onChange: (data: Consents, consent: string) => void
}

const ConsentBlocks = ({
  consents,
  data,
  error,
  onChange
}: ConsentBlocksProps) => {
  const { t } = useTranslation()
  const dataMap = getConsentsMap(data)
  const handleChange = (value: boolean, consent: string) => {
    onChange({ ...data, [consent]: value }, consent)
  }

  return (
    <>
      {consents.map(
        (consent) =>
          consent.isVisible && (
            <Grid item key={consent.name} xs={12}>
              <BooleanControl
                errorMessage={
                  error && consent.isRequired && !dataMap.get(consent.name)
                    ? t('field_required')
                    : undefined
                }
                label={mdToHTML(consent.text)}
                onChange={(v) => handleChange(v, consent.name)}
                required={consent.isRequired}
                uiType="checkbox"
                value={dataMap.get(consent.name)}
              />
            </Grid>
          )
      )}
    </>
  )
}

export default ConsentBlocks
