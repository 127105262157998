import {
  Grid,
  GridProps,
  StackSpacing,
  SpacingProps,
  AppBar,
  useTheme,
  AppBarProps,
  Card,
  makeStyles,
  createStyles,
  Theme,
  BoxProps
} from '@epilot/base-elements'
import {
  LayoutProps,
  Layout,
  rankWith,
  uiTypeIs,
  UISchemaElement
} from '@jsonforms/core'
import {
  ResolvedJsonFormsDispatch,
  withJsonFormsLayoutProps
} from '@jsonforms/react'
import React, { useMemo } from 'react'

import { IsMobile } from '../../utils/helper'

export type MainContentCartLayoutOptions = GridProps & {
  scale?: SpacingProps['scale']
  alignItems?: SpacingProps['alignItems']
}

export enum MainContentCartZones {
  'Header',
  'Footer',
  'Content',
  'Side'
}

export const useWrapperStyles = makeStyles((theme: Theme) => {
  return createStyles({
    wrapperStyles: {
      padding: theme.spacing(4),
      paddingBottom: 0,
      maxWidth: 1256,
      margin: 'auto',
      [theme.breakpoints.between('xs', 'md')]: {
        maxWidth: 936
      },
      flex: 1,
      width: '100%'
    }
  })
})

const MainContentCartLayout = ({
  uischema,
  renderers,
  schema,
  path,
  enabled
}: LayoutProps) => {
  const { wrapperStyles } = useWrapperStyles()

  const layout = uischema as Layout
  const elements = (layout.elements as any[]) || []
  const {
    spacing = 4,
    scale = 3,
    alignItems = 'stretch',
    ...rest
  } = (uischema.options as MainContentCartLayoutOptions) || {}

  const mobile = IsMobile()

  const sticky: UISchemaElement[] = useMemo(() => {
    let full: UISchemaElement[] = []

    full = full.concat(getStickyItems(elements[0]))
    full = full.concat(getStickyItems(elements[1]))
    full = full.concat(getStickyItems(elements[2]))
    full = full.concat(getStickyItems(elements[3]))
    full.sort(stickySorter)

    return full
  }, [elements])

  // first element is the main content
  const content = useMemo(
    () =>
      renderZone(
        elements[0],
        'content',
        {
          renderers,
          schema,
          path,
          enabled
        } as LayoutProps,
        alignItems,
        scale,
        mobile,
        Array.isArray(elements[1]) && elements[1].length > 0 ? 7 : 12
      ),
    [mobile, elements[0]]
  )

  //second element is the side (ex. shopping cart)
  const side = useMemo(
    () =>
      renderZone(
        elements[1],
        'side',
        {
          renderers,
          schema,
          path,
          enabled
        } as LayoutProps,
        alignItems,
        scale,
        mobile,
        Array.isArray(elements[0]) && elements[0].length > 0 ? 5 : 12
      ),
    [mobile, elements[1]]
  )

  //third element is the header
  const header = useMemo(
    () =>
      renderZone(
        elements[2],
        'header',
        {
          renderers,
          schema,
          path,
          enabled
        } as LayoutProps,
        alignItems,
        scale,
        mobile
      ),
    [mobile, elements[2]]
  )

  //forth element is the footer
  const footer = useMemo(
    () =>
      renderZone(
        elements[3],
        'footer',
        {
          renderers,
          schema,
          path,
          enabled
        } as LayoutProps,
        alignItems,
        scale,
        mobile
      ),
    [mobile, elements[3]]
  )

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        flex: 1
      }}
    >
      <div className={wrapperStyles}>
        <Grid container spacing={spacing} {...rest}>
          {header}
          {content}
          {side}
          {footer}
        </Grid>
      </div>
      {mobile && sticky.length > 0 && (
        <StickyZone>
          {sticky.map((child: any, index: number) => (
            <ResolvedJsonFormsDispatch
              enabled={enabled}
              key={'sticky' + index}
              path={path}
              renderers={renderers}
              schema={schema}
              uischema={child}
            />
          ))}
        </StickyZone>
      )}
    </div>
  )
}

function renderZone(
  elements: UISchemaElement[],
  name: string,
  { renderers, schema, path, enabled }: LayoutProps,
  alignItems: BoxProps['alignItems'],
  scale: number,
  mobile?: boolean,
  md?: GridProps['md']
) {
  if (Array.isArray(elements) && elements.length > 0) {
    return (
      <Grid item md={md} xs={12}>
        <StackSpacing alignItems={alignItems} scale={scale}>
          {elements.map((child: UISchemaElement, index: number) => {
            if (child.options?.stickyOnMobile && mobile) {
              return <></>
            } else {
              return (
                <ResolvedJsonFormsDispatch
                  enabled={enabled}
                  key={name + index}
                  path={path}
                  renderers={renderers}
                  schema={schema}
                  uischema={child}
                />
              )
            }
          })}
        </StackSpacing>
      </Grid>
    )
  }

  return <></>
}

export function StickyZone({ children, ...rest }: AppBarProps) {
  const theme = useTheme()

  return (
    <AppBar
      color="transparent"
      position="sticky"
      style={{
        top: 'auto',
        bottom: 0,
        backgroundColor: theme.palette.background.paper,
        marginTop: 10
      }}
      {...rest}
    >
      <Card style={{ borderRadius: 0 }}>{children}</Card>
    </AppBar>
  )
}

export const stickySorter = (a: UISchemaElement, b: UISchemaElement) =>
  a.options?.stickyOnMobileIndex > b.options?.stickyOnMobileIndex
    ? 1
    : a.options?.stickyOnMobileIndex < b.options?.stickyOnMobileIndex
    ? -1
    : 0

export function getStickyItems(elements: UISchemaElement[]) {
  const arr: UISchemaElement[] = []

  elements.map((e) => {
    if (e.options && e.options.stickyOnMobile) {
      arr.push(e)
    }
  })

  return arr
}

export const MainContentCartLayoutTester = rankWith(
  2,
  uiTypeIs('MainContentCartLayout')
)

export default withJsonFormsLayoutProps(MainContentCartLayout)
