import {
  BankTransfer,
  PaymentInformation,
  PaymentInformationValue,
  PaymentMathod,
  SEPA
} from '@epilot/base-modules'
import {
  schemaTypeIs,
  rankWith,
  and,
  optionIs,
  or,
  uiTypeIs
} from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CustomPayment, EpilotControlProps, stringToHTML } from '../../utils'
import { includeCommon } from '../../utils/includeCommon'

export type TypedPaymentMathod = PaymentMathod & {
  type: string
}
export type PaymentControlProps = {
  implementations: TypedPaymentMathod[]
}

export type PaymentControlOptions = PaymentControlProps & {
  initialType?: string
}
export enum PAYMENT_METHOD {
  SEPA = 'SEPA',
  TRANSFER = 'BankTransfer'
}
function PaymentControl({
  handleChange,
  uischema,
  path,
  schema,
  errors,
  enabled,
  data,
  required
}: EpilotControlProps) {
  const { implementations, initialType } =
    uischema.options as PaymentControlOptions
  const { errorMessage } = schema || {}
  const { t } = useTranslation()
  const [paymentInfo, setPaymentInfo] = useState(data)

  if (!data && initialType) {
    data = { type: initialType }
  }

  const filledImplementations = implementations.map(
    (item: TypedPaymentMathod) => {
      switch (item.type) {
        case 'SEPA': {
          return {
            ...item,
            informationComponent: SEPA,
            componentProps: {
              ...item.componentProps,
              scale: 3,
              consentLabel: stringToHTML(item.componentProps?.consentLabel)
            }
          }
        }
        case 'BankTransfer': {
          return { ...item, informationComponent: BankTransfer }
        }
        default: {
          return { ...item, informationComponent: CustomPayment }
        }
      }
    }
  )

  const handleOnChange = (
    data: PaymentInformationValue | undefined,
    hasError?: boolean
  ) => {
    let values

    if (
      data?.type === 'SEPA' &&
      data.data &&
      typeof data.data.iban === 'string'
    ) {
      values = {
        ...data,
        data: {
          ...data.data,
          iban: data.data.iban.replace(/ /g, '').split('_').join('')
        }
      }
    } else {
      values = data
    }

    setPaymentInfo(values)

    if (hasError && required) {
      handleChange(path, undefined)
    } else {
      handleChange(path, values)
    }
  }

  return (
    <PaymentInformation
      disabled={!enabled}
      error={
        errors
          ? errorMessage && typeof errorMessage === 'string'
            ? errorMessage
            : t('field_required')
          : ''
      }
      onPaymentMethodSelected={handleOnChange}
      paymentMethods={filledImplementations}
      value={paymentInfo}
    />
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: PaymentControl }) as never
)

export const PaymentControlTester = rankWith(
  6,
  or(
    and(schemaTypeIs('object'), optionIs('payment', true)),
    uiTypeIs('PaymentControl')
  )
)
