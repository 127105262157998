import * as React from 'react'

function SvgPersonFilled(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M2 24s-2 0-2-2 2-8 12-8 12 6 12 8-2 2-2 2H2zM12 12a6 6 0 100-12 6 6 0 000 12z"
      />
    </svg>
  )
}

export default SvgPersonFilled
