import { Hidden, EIcon, EIconProps } from '@epilot/base-elements'
import { LayoutProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'

export type EIconRendererOptions = EIconProps & {}

const EIconRenderer = ({ uischema, visible }: LayoutProps) => {
  const { ...rest } = (uischema.options as EIconRendererOptions) || {}

  return (
    <Hidden xsUp={!visible}>
      <EIcon {...rest}></EIcon>
    </Hidden>
  )
}

export const EIconRendererTester = rankWith(2, uiTypeIs('EIcon'))

export default withJsonFormsLayoutProps(EIconRenderer)
