import throttle from 'lodash/throttle'
import React, { useState, useMemo } from 'react'
import {
  Control,
  Path,
  UnpackNestedValue,
  PathValue,
  UseControllerProps,
  UseFormReturn
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { apiCall } from '../../../../api'
import { FieldAutocomplete } from '../../../../components/FieldAutocomplete'
import { FieldOption } from '../../../../components/types'
import {
  Address,
  FeedBackType,
  ZipCitySuggestion,
  ZipCitySuggestionData,
  CustomValidationRules
} from '../types'
import { isZipCitySuggestionData } from '../types.guard'
import {
  constructZipCityUrl,
  sendFeedback,
  regexZipCodeCityByCountry,
  zipCodeExamplesByCountry
} from '../utils'

export type FieldZipCityInputProps = {
  control: Control<Address>
  name: Path<Address>
  hasError: boolean
  defaultValue?: UnpackNestedValue<PathValue<Address, Path<Address>>>
  path: string
  rules?: UseControllerProps['rules']
  fieldValues: Address
  acceptSuggestedOnly?: boolean
  freeSolo?: boolean
  disableAddressSuggestions?: boolean
  addressCountryCode?: string
  setValue: UseFormReturn<Address>['setValue']
  register: UseFormReturn<Address>['register']
} & FieldOption

const MIN_CHARS = 2

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FieldZipCityInput = (props: FieldZipCityInputProps) => {
  const {
    acceptSuggestedOnly,
    label,
    setValue,
    register,
    required,
    fieldValues,
    disableAddressSuggestions,
    addressCountryCode,
    ...autocompleteProps
  } = props

  // register city as there is no dedicated component
  register('city', { required: !!required })

  const { t } = useTranslation()

  const [options, setOptions] = useState<ZipCitySuggestionData>([])

  const getCustomValidationRules: CustomValidationRules = useMemo(() => {
    return {
      ...(addressCountryCode && {
        rules: {
          validate: {
            zipCodeCityFormat: (value: string) => {
              if (
                value &&
                fieldValues.city &&
                regexZipCodeCityByCountry(addressCountryCode).test(
                  `${value} ${fieldValues.city}`
                )
              ) {
                return true
              }

              return false
            }
          }
        },
        errorMessages: {
          zipCodeCityFormat: t('address_zip_code_city_format_error', {
            zipCodeExample: zipCodeExamplesByCountry(addressCountryCode)
          })
        }
      })
    }
  }, [addressCountryCode, fieldValues.city, t])

  const getSuggestions = useMemo(
    () =>
      throttle(async (value: string) => {
        try {
          const countryCode = fieldValues.countryCode

          // || !countryCode || !city || !zipCode
          if (
            disableAddressSuggestions ||
            !value ||
            value.length <= MIN_CHARS
          ) {
            setOptions([])

            return []
          }

          const { data } = await apiCall(
            'GET',
            constructZipCityUrl(countryCode, value)
          )

          if (!isZipCitySuggestionData(data)) {
            throw Error(
              `Invalid API Response received of ${JSON.stringify(data)}`
            )
          }

          setOptions(data)

          return data
        } catch (e) {
          console.error('Error when fetching Address Options', { error: e })

          return []
        }
      }, 500),
    [disableAddressSuggestions, fieldValues.countryCode]
  )

  return (
    <FieldAutocomplete<Address, ZipCitySuggestion, ZipCitySuggestion>
      {...autocompleteProps}
      acceptSuggestedOnly={acceptSuggestedOnly}
      getOptionLabel={(option) => {
        // if an option is selected, the field contains only the zip, so find value based on field value + other fields city value
        if (typeof option === 'string' && fieldValues.city) {
          return `${option} ${fieldValues.city}`
        }

        // option will be object before selecting a value
        if (option.postal_code && option.city) {
          return `${option.postal_code} ${option.city}`
        }

        if (!acceptSuggestedOnly) {
          return option
        }

        return ''
      }}
      getOptionSelected={(option, value) => {
        // selected value is only zipCode
        return option.postal_code === value
      }}
      getSuggestions={getSuggestions}
      label={(label || t('City / Postcode')) + (required ? ' *' : '')}
      minChars={MIN_CHARS}
      noOptionsText={t('no_zip_code')}
      onBlur={(suggestions, field, setInputValue) => {
        if (suggestions && suggestions.length === 1) {
          field.onChange(suggestions[0].postal_code)

          // set related fields value
          setValue('city', suggestions[0]['city'])
        } else if (acceptSuggestedOnly && !field.value) {
          setInputValue('')
        }
      }}
      onChange={(value, field) => {
        if (value && value['postal_code'] && value['city']) {
          // set own fields value
          field.onChange(value.postal_code)

          // set related fields value
          setValue('city', value['city'])

          // send feedback
          sendFeedback(
            FeedBackType.POSTAL_CODE_OR_CITY,
            fieldValues.countryCode,
            value
          )
        } else {
          // clear data as e.g. clear button clicked
          field.onChange('')
          setValue('city', '')
        }
      }}
      // select first option if blurring out
      onInputChange={(value, field, setInputValue) => {
        if (!acceptSuggestedOnly) {
          const [zipCode, ...city] = value.split(' ')

          field.onChange(zipCode)
          setValue('city', city ? city.join(' ') : '')
        } else if (field.value) {
          field.onChange(field.value)
          setValue('city', fieldValues.city || '')
        } else {
          field.onChange(null)
          setValue('city', '')
        }
        setInputValue(value)
      }}
      options={options}
      required={required}
      rules={getCustomValidationRules.rules}
      rulesCustomErrorMessages={getCustomValidationRules.errorMessages}
    />
  )
}
