import React from 'react'

export type SpinnerProps = {
  className?: string
  color?: string
  size?: number
  duration?: number
}

function Spinner(props: SpinnerProps) {
  const { className, color, duration, size } = props

  return (
    <svg
      className={className}
      height={`${size || 32}px`}
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
      width={`${size || 32}px`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        r="32"
        stroke={color || '#039BE5'}
        strokeDasharray="150.79644737231007 52.26548245743669"
        strokeWidth="8"
        transform="rotate(330.62 50 50)"
      >
        <animateTransform
          attributeName="transform"
          dur={`${duration || 1}s`}
          keyTimes="0;1"
          repeatCount="indefinite"
          type="rotate"
          values="0 50 50;360 50 50"
        ></animateTransform>
      </circle>
    </svg>
  )
}

export default Spinner
