import { IbanInput, IbanResponse } from '@epilot/base-modules'
import { schemaTypeIs, rankWith, and, optionIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React, { useState } from 'react'

import { EpilotControlProps } from '../../utils'

export type IbanControlProps = {
  bicLabel: string
  bankNameLabel: string
  apiBaseUrl: string
}
function IbanControl({
  data,
  handleChange,
  path,
  uischema,
  errors,
  enabled,
  required,
  schema
}: EpilotControlProps) {
  const [iban, setIban] = useState(data?.iban || '')
  const handleOnChange = (newIban: string, ibanResponse: IbanResponse) => {
    const sanitizedIban = newIban.replace(/ /g, '').split('_').join('')

    setIban(sanitizedIban)
    if (sanitizedIban === '') {
      handleChange(path, {})
    } else {
      handleChange(path, { iban: sanitizedIban, ...ibanResponse })
    }
  }

  const { label } = uischema

  const { errorMessage } = schema

  const { bicLabel, bankNameLabel, apiBaseUrl } =
    uischema.options as IbanControlProps

  return (
    <IbanInput
      apiBaseUrl={apiBaseUrl}
      bankName={data?.bank_name || ''}
      bankNameLabel={bankNameLabel}
      bic={data?.bic_number || ''}
      bicLabel={bicLabel}
      disabled={!enabled}
      iban={iban}
      ibanHelper={
        errors
          ? errorMessage && typeof errorMessage
            ? errorMessage
            : errors
          : ''
      }
      ibanLabel={label as string}
      onChange={handleOnChange}
      required={required}
    />
  )
}

export default withJsonFormsControlProps(IbanControl as never)

export const IbanControlTester = rankWith(
  4,
  and(schemaTypeIs('object'), optionIs('iban_complex', true))
)
