import {
  Divider,
  Grid,
  MuiPickersUtilsProvider,
  DateFnsUtils
} from '@epilot/base-elements'
import { regExpStatments } from '@epilot/validators'
import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en-US'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { removeExtras } from '../../../utils'
import {
  getLocaleDateString,
  isAnyInObject,
  matchesValue
} from '../../../utils/helper'
import { FieldDatePicker } from '../../FieldDatePicker'
import { FieldText } from '../../FieldText'
import { FieldToggleButton } from '../../FieldToggleButton'
import { FieldCustomerType } from '../FieldCustomerType'

import { ContactFormFieldsProps } from './types'

const localeMap = {
  en: enLocale,
  de: deLocale
}

export const ContactFormFields = (
  props: ContactFormFieldsProps
): ReactElement => {
  const { fields, control, hasError, path, resetField, fieldValues } = props
  const { t } = useTranslation()

  const format = getLocaleDateString(navigator.language || 'de-DE')

  // detect language and only get language code before the first dash
  const localeDetected = navigator.language.substr(
    0,
    navigator.language.indexOf('-')
  )
  // set locale. Default to de
  const locale = (
    ['de', 'en'].includes(localeDetected) ? localeDetected : 'de'
  ) as 'de' | 'en'

  return (
    <>
      {(fields.salutation || fields.title) && (
        <>
          <Grid item md={6} sm={12} xs={12}>
            {fields.salutation && (
              <FieldToggleButton
                control={control}
                hasError={hasError}
                name="salutation"
                options={
                  fields.salutation.genderType === 'GENDER3'
                    ? ['Mr', 'Ms / Mrs', 'Other']
                    : ['Mr', 'Ms / Mrs']
                }
                optionsLabels={
                  fields.salutation.genderType === 'GENDER3'
                    ? [t('Mr.'), t('Mrs'), t('Others')]
                    : [t('Mr.'), t('Mrs')]
                }
                {...fields.salutation}
                label=""
              />
            )}
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            {fields.title && (
              <FieldToggleButton
                control={control}
                hasError={hasError}
                name="title"
                options={['Dr.', 'Prof.', 'Prof. Dr.']}
                {...fields.title}
                label=""
              />
            )}
          </Grid>
        </>
      )}
      {fields.firstName && (
        <Grid item md={6} sm={12} xs={12}>
          <FieldText
            control={control}
            defaultValue={fields.firstName.defaultValue || ''}
            hasError={hasError}
            name="firstName"
            path={path}
            rules={{
              pattern: new RegExp(removeExtras(regExpStatments.name.regex))
            }}
            {...fields.firstName}
          />
        </Grid>
      )}
      {fields.lastName && (
        <Grid item md={6} sm={12} xs={12}>
          <FieldText
            control={control}
            defaultValue={fields.lastName.defaultValue || ''}
            hasError={hasError}
            name="lastName"
            path={path}
            rules={{
              pattern: new RegExp(removeExtras(regExpStatments.name.regex))
            }}
            {...fields.lastName}
          />
        </Grid>
      )}
      {isAnyInObject(fields, [
        'salutation',
        'title',
        'firstName',
        'lastName'
      ]) &&
        isAnyInObject(fields, ['birthDate', 'email', 'telephone']) && (
          <Grid item style={{ padding: '0 6px' }} xs={12}>
            <Divider
              orientation="horizontal"
              style={{ width: '100%', margin: '4px 0px' }}
            />
          </Grid>
        )}
      {fields.email && (
        <Grid item md={6} sm={12} xs={12}>
          <FieldText
            control={control}
            defaultValue={fields.email.defaultValue || ''}
            errorMessage={fields.email.errorMessage || t('email_error')}
            hasError={hasError}
            name="email"
            path={path}
            rules={{
              pattern: new RegExp(removeExtras(regExpStatments.email.regex))
            }}
            {...fields.email}
          />
        </Grid>
      )}
      {fields.telephone && (
        <Grid item md={6} sm={12} xs={12}>
          <FieldText
            control={control}
            defaultValue={fields.telephone.defaultValue || ''}
            errorMessage={fields.telephone.errorMessage || t('telephone_error')}
            hasError={hasError}
            name="telephone"
            path={path}
            rules={{
              pattern: new RegExp(
                removeExtras(regExpStatments.telephone_de.regex)
              )
            }}
            {...fields.telephone}
          />
        </Grid>
      )}
      {fields.birthDate && (
        <Grid item md={6} sm={12} xs={12}>
          <MuiPickersUtilsProvider
            locale={localeMap[locale]}
            utils={DateFnsUtils}
          >
            <FieldDatePicker
              control={control}
              defaultValue={fields.birthDate.defaultValue}
              disableFuture
              errorMessage={
                fields.birthDate.errorMessage || t('birthDateError')
              }
              format={format}
              hasError={hasError}
              label={fields.birthDate.label || t('birthDate')}
              name="birthDate"
              path={path}
              rules={{
                validate: (value) => {
                  // dont validate further if no value. Other, internal rules still validate
                  if (!value) return true

                  return new Date(value).getTime() < new Date().getTime()
                }
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      )}
      {isAnyInObject(fields, [
        'customerType',
        'companyName',
        'registryCourt',
        'registerNumber'
      ]) &&
        isAnyInObject(fields, ['birthDate', 'email', 'telephone']) && (
          <Grid item style={{ padding: '0 6px' }} xs={12}>
            <Divider
              orientation="horizontal"
              style={{ width: '100%', margin: '4px 0px' }}
            />
          </Grid>
        )}
      {fields.customerType && (
        <Grid item xs={12}>
          <FieldCustomerType
            control={control}
            formProps={{ resetField }}
            hasError={hasError}
            {...fields.customerType}
          />
        </Grid>
      )}
      {fields.companyName &&
        (!fields.companyName.showIf ||
          matchesValue(fields.companyName.showIf, fieldValues)) && (
          <Grid item md={6} sm={12} xs={12}>
            <FieldText
              control={control}
              defaultValue={fields.companyName.defaultValue || ''}
              hasError={hasError}
              name="companyName"
              path={path}
              {...fields.companyName}
            />
          </Grid>
        )}
      {fields.registryCourt &&
        (!fields.registryCourt.showIf ||
          matchesValue(fields.registryCourt.showIf, fieldValues)) && (
          <Grid item md={6} sm={12} xs={12}>
            <FieldText
              control={control}
              defaultValue={fields.registryCourt.defaultValue || ''}
              hasError={hasError}
              name="registryCourt"
              path={path}
              {...fields.registryCourt}
            />
          </Grid>
        )}
      {fields.registerNumber &&
        (!fields.registerNumber.showIf ||
          matchesValue(fields.registerNumber.showIf, fieldValues)) && (
          <Grid item md={6} sm={12} xs={12}>
            <FieldText
              control={control}
              defaultValue={fields.registerNumber.defaultValue || ''}
              hasError={hasError}
              name="registerNumber"
              path={path}
              {...fields.registerNumber}
            />
          </Grid>
        )}
    </>
  )
}
