import { Grid, H2 } from '@epilot/base-elements'
import React, { ReactElement } from 'react'

import { ContactFormFields } from '../../../../components/contact/ContactFormFields'
import { useFormHandler } from '../../../../utils/hooks/forms/useFormHandler'
import { useResetOnNotEqual } from '../../../../utils/hooks/forms/useResetOnNotEqual'
import { Contact, ContactFormProps } from '../types'

export const ContactForm = (props: ContactFormProps): ReactElement => {
  const { handleChange, path, hasError, fields, data, label, isRequired } =
    props

  const { control, fieldValues, resetField } = useFormHandler<Contact>({
    handleChange,
    path,
    data,
    isRequired,
    fields
  })

  useResetOnNotEqual(resetField, {
    name: 'companyName',
    watch: { name: 'customerType', value: fieldValues.customerType },
    value: fields.companyName?.defaultValue || '',
    match: fields.companyName?.showIf
  })

  useResetOnNotEqual(resetField, {
    name: 'registerNumber',
    watch: { name: 'customerType', value: fieldValues.customerType },
    value: fields.registerNumber?.defaultValue || '',
    match: fields.registerNumber?.showIf
  })

  useResetOnNotEqual(resetField, {
    name: 'registryCourt',
    watch: { name: 'customerType', value: fieldValues.customerType },
    value: fields.registryCourt?.defaultValue || '',
    match: fields.registryCourt?.showIf
  })

  return (
    <>
      {label && <H2>{label}</H2>}
      <Grid container spacing={3}>
        <ContactFormFields
          control={control}
          fieldValues={fieldValues}
          fields={fields}
          hasError={hasError}
          path={path}
          resetField={resetField}
        />
      </Grid>
    </>
  )
}
