import { add } from 'date-fns'

/**
 * Attempts to find the next available date based on passed date, and disabled days
 * @param date
 * @param disableDays
 */
export const findNextAvailableDay = (
  date: Date,
  disableDays: number[] | undefined
): Date | null => {
  // if no disabled days, the passed date can be chosen
  if (!disableDays) {
    return date
  }

  if (
    disableDays &&
    [0, 1, 2, 3, 4, 5, 6].every((value) => disableDays.includes(value))
  ) {
    // if all days are disabled, return null to prevent infinity loop
    return null
  }

  // if passed day is disabled, find next possible day
  const day = date.getDay() as 0 | 1 | 2 | 3 | 4 | 5 | 6

  if (disableDays.includes(day)) {
    return findNextAvailableDay(add(date, { days: 1 }), disableDays)
  }

  return date
}
