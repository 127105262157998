import { Theme } from '@epilot/base-elements'
import { makeStyles, createStyles } from '@material-ui/core/styles'
export const useEnumButtonStyles = makeStyles((theme: Theme) => {
  return createStyles({
    buttonStyles: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'flex',
      flex: 1,
      transition: 'none',
      margin: theme.spacing(1.5),
      '&.Mui-focusVisible': {
        border: `2px solid ${theme.palette.primary.main}`
      },
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`
      },
      '&:active': {
        boxShadow: 'none',
        borderColor: 'initial'
      },
      '& > span': {
        display: 'initial',
        width: 'initial'
      }
    },
    buttonActiveStyles: {
      border: `2px solid ${theme.palette.primary.main}`,
      '&:hover': {
        border: `2px solid ${theme.palette.primary.main}`
      }
    },
    buttonWrapStyles: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    }
  })
})
