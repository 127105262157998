import * as React from 'react'

function SvgCircleCheckBoxNull(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 17 16"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 8A7.5 7.5 0 111 8a7.5 7.5 0 0115 0z"
        fill="#fff"
        stroke="#D2DEE4"
      />
    </svg>
  )
}

export default SvgCircleCheckBoxNull
