import { Hidden, EIcon, LargeText, Link } from '@epilot/base-elements'
import { UploadPanel, UploadPanelProps } from '@epilot/base-modules'
import { rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  postPresignedPutUrls,
  putUploadPresignedUrl,
  uploadFile
} from '../../api'
import {
  EpilotControlProps,
  S3File,
  PresignedUrlReq,
  PresignedUrlResp
} from '../../utils'
import { getBase64 } from '../../utils/helper'
import { includeCommon } from '../../utils/includeCommon'

function UploadPanelControl({
  uischema,
  schema,
  data,
  handleChange,
  path,
  errors,
  visible
}: EpilotControlProps) {
  const { t } = useTranslation()

  // map file_name to name, as module will use that when prefilling
  const initialData =
    data &&
    data.map((file: S3File) => {
      return { ...file, name: file.original_name }
    })
  const labels = {
    complete: t('complete'),
    failed: t('failed')
  }

  const filesTableProps = {
    labels: {
      fileName: t('file_name')
    }
  }

  const uploadZoneProps = {
    acceptedFilesMIME:
      'image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.oasis.opendocument.text, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.presentation',
    uploadMessage: (
      <>
        <div>
          <LargeText>
            {t('Drag your files here or')}{' '}
            <Link color="primary">{t('Browse files')}</Link>
          </LargeText>
        </div>
      </>
    ),
    uploadIcon: <EIcon color="inherit" htmlColor="#C5D0DB" name="image" />
  }

  const options = uischema.options

  const uploadFileHandler = async (formData: FormData) => {
    const newData = data ? [...data] : []
    const file = formData.get('file') as File

    if (
      newData.find((s3File: S3File) => s3File.original_name === file.name) ||
      (!options?.apiResultPresignedUrl && !options?.orgId)
    ) {
      return
    }

    let s3File: S3File | undefined = undefined

    const splitName = file.name.split('.')
    const fileType = splitName[splitName.length - 1]
    const presignedUrlReq: PresignedUrlReq = {
      prefixKey: 'temp',
      fileTypes: [fileType]
    }

    // if orgId is passed, we can make use of the File API, which will also create an entity
    if (options?.orgId) {
      s3File = await uploadFile(file, options.orgId)
      if (s3File) {
        newData.push(s3File)
        handleChange(path, newData)
      }
    } else {
      // Get presigned URL
      const presignedUrlsResponse = await postPresignedPutUrls(
        presignedUrlReq,
        options?.apiResultPresignedUrl
      )
      const base64File = await getBase64(file)

      const presignedUrls = presignedUrlsResponse.data as PresignedUrlResp
      const currentKey = presignedUrls[0].key
      const fileName = currentKey.includes('/')
        ? currentKey.substring(currentKey.lastIndexOf('/') + 1)
        : currentKey

      s3File = {
        file_name: fileName,
        file_size: file.size,
        original_name: file.name,
        file_type: fileType
      }

      // Upload file to s3
      const s = base64File as string
      const base64data = s.split(',')[1]
      const buffer = Buffer.from(base64data, 'base64')

      await putUploadPresignedUrl(buffer, presignedUrls[0].url, file.type)
      newData.push(s3File)
      handleChange(path, newData)
    }
  }

  const deleteFileHandler = (filename: string) => {
    let newData = data
      ? data.filter((file: S3File) => file.original_name !== filename)
      : []

    newData = newData.length ? newData : undefined

    handleChange(path, newData)
  }

  const errorMessage = errors
    ? schema.errorMessage
      ? schema.errorMessage
      : errors
    : undefined

  console.log(t('upload_limit_text'))

  const panelProps: UploadPanelProps = {
    labels,
    filesTableProps,
    uploadZoneProps,
    uploadFileHandler,
    deleteFileHandler,
    showLoadingBar: options?.showLoadingBar || true,
    initUploadedFiles: initialData,
    maxQuantity: options?.maxQuantity,
    error: errorMessage,
    limitText: t('upload_limit_text')
  }

  return (
    <Hidden xsUp={!visible}>
      <UploadPanel {...panelProps} />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: UploadPanelControl }) as never
)

export const UploadPanelControlTester = rankWith(
  4,
  uiTypeIs('UploadPanelControl')
)
