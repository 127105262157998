/*
 * Generated type guards for "types.ts".
 * WARNING: Do not manually change this file.
 */
import {
  EnumButtonControlOptions,
  EnumButtonControlData,
  EnumOptions
} from './types'

export function isEnumButtonControlOptions(
  obj: any,
  _argumentName?: string
): obj is EnumButtonControlOptions {
  return (
    ((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
    (typeof obj.optionsLabels === 'undefined' ||
      (Array.isArray(obj.optionsLabels) &&
        obj.optionsLabels.every((e: any) => typeof e === 'string'))) &&
    (typeof obj.defaultSelection === 'undefined' ||
      obj.defaultSelection === null ||
      typeof obj.defaultSelection === 'string')
  )
}

export function isEnumButtonControlData(
  obj: any,
  _argumentName?: string
): obj is EnumButtonControlData {
  return typeof obj === 'undefined' || typeof obj === 'string'
}

export function isEnumOptions(
  obj: any,
  _argumentName?: string
): obj is EnumOptions {
  return Array.isArray(obj) && obj.every((e: any) => typeof e === 'string')
}
