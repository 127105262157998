import { Hidden } from '@epilot/base-elements'
import { StepperInputProps, StepperInput } from '@epilot/base-modules'
import { rankWith, and, isNumberControl, optionIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'

import { EpilotControlProps } from '../../utils'

export type StepperInputOptions = StepperInputProps

function StepperInputControl({
  data = 0,
  handleChange,
  path,
  uischema,
  errors,
  enabled,
  required,
  schema,
  visible
}: EpilotControlProps) {
  const handleOnChange = (value: number) => {
    handleChange(path, value)
  }

  const { label } = uischema

  const { errorMessage, examples } = schema

  const placeholder =
    Array.isArray(examples) && examples[0] ? examples[0] : label

  const { startIcon, endIcon, ...rest } =
    (uischema.options as StepperInputOptions) || {}

  return (
    <Hidden xsUp={!visible}>
      <StepperInput
        disabled={!enabled}
        error={errors ? true : false}
        fullWidth
        helperText={
          errors
            ? errorMessage && typeof errorMessage
              ? errorMessage
              : errors
            : ''
        }
        onChange={(e) => handleOnChange(parseInt(e.target.value))}
        onEndIconClick={() => {
          handleOnChange(data + 1)
        }}
        onStartIconClick={() => {
          handleOnChange(data - 1)
        }}
        placeholder={placeholder}
        required={required}
        value={data}
        {...rest}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(StepperInputControl as never)

export const StepperInputTester = rankWith(
  3,
  and(isNumberControl, optionIs('stepper', true))
)
