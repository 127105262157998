import { Divider, Hidden } from '@epilot/base-elements'
import {
  Address,
  IntegratedAddressModule,
  IntegratedAddressModuleProps
} from '@epilot/base-modules'
import { rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  FieldOrder,
  PersonalInformation,
  PersonalInformationComponentProps,
  PERSONAL_INFO_FIELDS
} from '../../components/PersonalInformation'
import { EpilotControlProps } from '../../utils'
import { includeCommon } from '../../utils/includeCommon'
const ADDRESS_GROUP_NAME = 'address'

export type AddressControlOptions = IntegratedAddressModuleProps & {
  integrationAPI?: string
}

type IntegrationProvider = 'Enet' | 'GetAG'

function AddressControlLegacy({
  data,
  handleChange,
  path,
  uischema,
  visible,
  errors,
  schema
}: EpilotControlProps) {
  const { t } = useTranslation()

  const {
    integrationAPI,
    labels: labelsPassed,
    initialAddress,
    fieldsOrder,
    ...props
  } = uischema.options as AddressControlOptions

  const { errorMessage } = schema || {}
  const personalInfoFieldsOrder =
    fieldsOrder &&
    (fieldsOrder.filter((fieldsOrder) =>
      PERSONAL_INFO_FIELDS.includes(fieldsOrder.name)
    ) as FieldOrder[])

  const addressFieldsOrder =
    fieldsOrder &&
    fieldsOrder.filter(
      (fieldsOrder) => fieldsOrder?.groupName === ADDRESS_GROUP_NAME
    )

  // support labels coming from the config / db
  const labels = labelsPassed
    ? labelsPassed
    : {
        streetLabel: t('Street'),
        zipLabel: t('Postcode'),
        housenumberLabel: t('houseNumber'),
        cityLabel: t('City'),
        zipCityLabel: t('City / Postcode'),
        extentionLabel: t('extention'),
        countryLabel: t('Country')
      }

  const handleOnChange = (value: any) => {
    // only merge data if personal fields are given
    const newData =
      personalInfoFieldsOrder && personalInfoFieldsOrder.length > 0
        ? { ...data, ...value }
        : { ...value }

    const isEmptyValue =
      !newData || Object.values(newData).every((v) => !v || v === '')

    // Clean empty properties
    if (value) {
      Object.keys(newData).forEach((key) => {
        if (newData[key] === undefined || newData[key] === '') delete value[key]
      })
    }
    handleChange(path, isEmptyValue ? undefined : newData)
  }

  // if the dev passed an API then an integration is needed
  const handleIntegration = integrationAPI
    ? async (address?: Address) => {
        if (address) {
          const provider = getServiceProvider(integrationAPI)
          const url = getIntegrationURL(integrationAPI, address)

          try {
            const jsonResult = await (await fetch(url)).json()

            // based on the returned providers > 1 it should be true=show more fields, or false = do not show more fields
            return shouldShowMoreFields(provider, jsonResult)
          } catch {
            // false = do not show more fields
            return false
          }
        }

        // false = do not show more fields
        return false
      }
    : undefined

  return (
    <Hidden xsUp={!visible}>
      <IntegratedAddressModule
        error={
          errors
            ? errorMessage && typeof errorMessage === 'string'
              ? errorMessage
              : t('field_required')
            : ''
        }
        fieldsOrder={
          addressFieldsOrder && addressFieldsOrder.length > 0
            ? addressFieldsOrder
            : fieldsOrder
        }
        gridSpacing={3}
        initialAddress={data || initialAddress}
        labels={labels}
        noOptionSelectedTextStreet={t('no_selected_suggestion')}
        noOptionSelectedTextZipCity={t('no_selected_suggestion')}
        noOptionsTextStreet={t('no_street')}
        noOptionsTextZipCity={t('no_zip_code')}
        onChange={handleOnChange as never}
        onZipSelected={handleIntegration as never}
        {...props}
      />
      {personalInfoFieldsOrder && personalInfoFieldsOrder.length > 0 ? (
        <Divider style={{ margin: '16px 0px' }}></Divider>
      ) : null}
      {personalInfoFieldsOrder && personalInfoFieldsOrder.length > 0 ? (
        <PersonalInformation
          error={errors ? errors : ''}
          fieldsOrder={personalInfoFieldsOrder}
          labels={labels as never}
          onChange={handleOnChange}
          value={data}
          {...(props as PersonalInformationComponentProps)}
        />
      ) : null}
    </Hidden>
  )
}

function getIntegrationURL(url: string, address: Address) {
  //"https://svc-enet-integration-api.sls.epilot.io/v1/enet-pricing/grid-providers?brand_id=4520697&product_type=Power&delivery_date=2021-05-31&postal_code=50676&consumption=1500"
  //"https://svc-enet-integration-api.sls.epilot.io/v1/enet-pricing/grid-providers?brand_id=4520697&product_type=Power&delivery_date=%delivery_date%&postal_code=%zip%&consumption=1500"
  const now = new Date()
  // should be like 2021-05-31
  const delivery_date =
    now.getFullYear() +
    '-' +
    ('0' + (now.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + now.getDate()).slice(-2)

  return url
    .replace('%delivery_date%', delivery_date)
    .replace('%zip%', address.zipCode || '')
}

function getServiceProvider(url: string): IntegrationProvider {
  /**
   * @todo I am not sure exactly the content of the url, I assume it will include "getag"
   */
  if (url.toLowerCase().indexOf('getag') >= 0) {
    return 'GetAG'
  }

  return 'Enet'
}

function parseProviders(int: IntegrationProvider, response: any) {
  switch (int) {
    case 'GetAG': {
      /**
       * @todo
       */
      return 1
    }
    default:
      return response.amount_grid_providers ? response.amount_grid_providers : 1
  }
}

function shouldShowMoreFields(int: IntegrationProvider, response: any) {
  const providers = parseProviders(int, response)

  return providers === 1
}

export default withJsonFormsControlProps(
  includeCommon({ component: AddressControlLegacy }) as never
)

export const AddressControlLegacyTester = rankWith(
  4,
  uiTypeIs('AddressControl')
)
