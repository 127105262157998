import { Divider, Grid, H2, TextField, Typography } from '@epilot/base-elements'
import { BooleanControl, EnumControl } from '@epilot/base-modules'
import { regExpStatments, functionalValidators } from '@epilot/validators'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { removeExtras } from '../../utils'

import {
  CustomerTypeAndCompany,
  Errors,
  FieldOrder,
  Person,
  PersonalInformationComponentProps
} from './types'
import {
  CUSTOMER_TYPE,
  CUSTOMER_TYPE_FIELDS,
  DefaultPersonalInformationComponentProps,
  GENDER_TYPE,
  OPTIONS_VALUE_FOR_COMPANY_NAME_CONDITION
} from './utils'

export function PersonalInformation({
  onChange,
  value,
  fieldsOrder = DefaultPersonalInformationComponentProps.fieldsOrder,
  label,
  labels,
  requiredFields = DefaultPersonalInformationComponentProps.requiredFields,
  errorMessages,
  placeHolders = DefaultPersonalInformationComponentProps.placeHolders,
  showCondition,
  salutationType,
  error,
  defaultCustomerTypeAndLabelOptions = DefaultPersonalInformationComponentProps.defaultCustomerTypeAndLabelOptions
}: PersonalInformationComponentProps) {
  const { t } = useTranslation()

  const getRequiredFieldList = (cType?: string) => {
    // if customer type PRIVATE --> remove business fields from required list
    const businessFields = ['registryCourt', 'companyName', 'registerNumber']

    const response: any = { ...requiredFields }

    if (cType === CUSTOMER_TYPE.PRIVATE) {
      businessFields.map((field) => {
        response[field] && delete response[field]
      })
    }

    return response
  }

  const getInitialErrors = (cType?: string) => {
    const businessFields = ['registryCourt', 'companyName', 'registerNumber']
    const response: any = { ...requiredFields }

    Object.keys(response).forEach((key) => {
      if (!response[key]) {
        delete response[key]
      }
    })

    // Initial proccesing
    const keys = Object.keys(response)
    const personKeys = [
      'salutation',
      'title',
      'customerType',
      'companyName',
      'registryCourt',
      'registerNumber',
      'firstName',
      'lastName',
      'email',
      'telephone',
      'birthDate',
      'agreement'
    ]

    // is field there? --> remove error from list
    for (const key of keys) {
      if (!personKeys.includes(key) || (person && key in person)) {
        delete response[key]
      }
    }

    // if Customer type is PRIVATE --> remove business fields from error list
    if (cType === CUSTOMER_TYPE.PRIVATE) {
      businessFields.map((field) => {
        response[field] && delete response[field]
      })
    }

    return response
  }

  // Set person
  const [person, setPerson] = useState<Person | undefined>(
    value || {
      customerType: defaultCustomerTypeAndLabelOptions?.defaultCustomerSelection // first radio option is predefined
    }
  )

  // Customer type stuff
  const defaultCustomerTypeSelection =
    defaultCustomerTypeAndLabelOptions?.defaultCustomerSelection
  const customerTypeOptionsInfo =
    defaultCustomerTypeAndLabelOptions?.customerTypeLabelOptions
  const customerTypeOptions = [
    customerTypeOptionsInfo?.privateLabel || t(CUSTOMER_TYPE.PRIVATE),
    customerTypeOptionsInfo?.businessLabel || t(CUSTOMER_TYPE.BUSINESS)
  ]

  // get customer type
  const customerType = person?.customerType || defaultCustomerTypeSelection

  // Set errors to be processed initial errors considering customer type
  const [errors, setErrors] = useState<Errors>(() =>
    getInitialErrors(customerType)
  )

  // actual required fields to use depend on customer type. Created function to get correct list
  const requiredFieldsToUse = getRequiredFieldList(customerType)

  const newFieldsOrder = generateGroupsFieldsOrder(
    removeFieldsBasedOnCondition(
      fieldsOrder,
      showCondition,
      person,
      customerType
    )
  )

  const handleOnChange = (
    v: Person,
    field: keyof Person,
    hasError?: boolean
  ) => {
    // add to errors
    let tempErrors = { ...errors }

    if (field === 'customerType') {
      tempErrors = getInitialErrors(v.customerType)
    }

    if (hasError) {
      tempErrors = { ...errors, [field]: true }
    } else {
      tempErrors[field] && delete tempErrors[field]
    }
    setErrors(tempErrors)

    // set the person
    setPerson(v)

    // sent to callback
    if (onChange) {
      Object.keys(tempErrors).length === 0
        ? onChange(v, tempErrors)
        : onChange(undefined, tempErrors)
    }
  }

  const blocks = {
    salutation: (
      <EnumControl
        errorMessage={
          error && errors?.salutation
            ? errorMessages?.salutation || t('field_required')
            : undefined
        }
        label=""
        onChange={(v) =>
          handleOnChange(
            { ...person, salutation: v },
            'salutation',
            isValidField(v, requiredFields?.salutation)
          )
        }
        options={
          salutationType === GENDER_TYPE.GENDER3
            ? [t('Mr.'), t('Mrs'), t('Others')]
            : [t('Mr.'), t('Mrs')]
        }
        required={requiredFieldsToUse?.salutation}
        uiType="toggle"
        value={person?.salutation}
      />
    ),
    title: (
      <EnumControl
        errorMessage={
          error && errors?.title
            ? errorMessages?.title || t('field_required')
            : undefined
        }
        label=""
        onChange={(v) =>
          handleOnChange(
            { ...person, title: v },
            'title',
            isValidField(v, requiredFieldsToUse?.title)
          )
        }
        options={['Dr.', 'Prof.', 'Prof. Dr.']}
        required={requiredFieldsToUse?.title}
        uiType="toggle"
        value={person?.title}
      />
    ),
    firstName: (
      <TextField
        error={error && errors?.firstName ? true : false}
        fullWidth
        helperText={
          error && errors?.firstName
            ? errorMessages?.firstName || t('field_required')
            : undefined
        }
        inputProps={{
          pattern: removeExtras(regExpStatments.name.regex)
        }}
        label={labels?.firstName || t('firstName')}
        onChange={(e) =>
          handleOnChange(
            { ...person, firstName: e.target.value },
            'firstName',
            !e.target.validity.valid
          )
        }
        placeholder={placeHolders?.firstName}
        required={requiredFieldsToUse?.firstName}
        value={person?.firstName}
      />
    ),
    lastName: (
      <TextField
        error={error && errors?.lastName ? true : false}
        fullWidth
        helperText={
          error && errors?.lastName
            ? errorMessages?.lastName || t('field_required')
            : undefined
        }
        inputProps={{
          pattern: removeExtras(regExpStatments.name.regex)
        }}
        label={labels?.lastName || t('lastName')}
        onChange={(e) =>
          handleOnChange(
            { ...person, lastName: e.target.value },
            'lastName',
            !e.target.validity.valid
          )
        }
        placeholder={placeHolders?.lastName}
        required={requiredFieldsToUse?.lastName}
        value={person?.lastName}
      />
    ),
    email: (
      <TextField
        error={error && errors?.email ? true : false}
        fullWidth
        helperText={
          error && errors?.email
            ? errorMessages?.email || t('email_error')
            : undefined
        }
        inputProps={{ pattern: removeExtras(regExpStatments.email.regex) }}
        label={labels?.email || t('email')}
        onChange={(e) =>
          handleOnChange(
            { ...person, email: e.target.value },
            'email',
            !e.target.validity.valid
          )
        }
        placeholder={placeHolders?.email}
        required={requiredFieldsToUse?.email}
        value={person?.email}
      />
    ),
    telephone: (
      <TextField
        error={error && errors?.telephone ? true : false}
        fullWidth
        helperText={
          error && errors?.telephone
            ? errorMessages?.telephone || t('telephone_error')
            : undefined
        }
        inputProps={{
          pattern: removeExtras(regExpStatments.telephone_de.regex)
        }}
        label={labels?.telephone || t('telephone')}
        onChange={(e) =>
          handleOnChange(
            { ...person, telephone: e.target.value },
            'telephone',
            !e.target.validity.valid
          )
        }
        placeholder={placeHolders?.telephone}
        required={requiredFieldsToUse?.telephone}
        value={person?.telephone}
      />
    ),
    birthDate: (
      <TextField
        error={error && errors?.birthDate ? true : false}
        fullWidth
        helperText={
          error && errors?.birthDate
            ? errorMessages?.birthDate || t('birth_data_error')
            : undefined
        }
        label={labels?.birthDate || t('birthDate')}
        onChange={(e) => {
          const valid = functionalValidators.birth_date.callback(e.target.value)

          handleOnChange(
            { ...person, birthDate: e.target.value },
            'birthDate',
            !valid
          )
        }}
        placeholder={placeHolders?.birthDate}
        required={requiredFieldsToUse?.birthDate}
        value={person?.birthDate}
      />
    ),
    agreement: (
      <BooleanControl
        errorMessage={
          error && errors?.agreement
            ? errorMessages?.agreement || t('field_required')
            : undefined
        }
        label={labels?.agreement || t('agreement')}
        onChange={(v) =>
          handleOnChange({ ...person, agreement: v }, 'agreement', !v)
        }
        required={requiredFieldsToUse?.agreement}
        uiType="checkbox"
        value={person?.agreement}
      />
    ),
    customerType: (
      <>
        <Typography color="textSecondary" variant="body1">
          {labels?.customerType || t('customerType')}
        </Typography>
        <EnumControl
          errorMessage={
            error && errors?.customerType && !customerType
              ? errorMessages?.customerType || t('field_required')
              : undefined
          }
          label={''}
          onChange={(v) => {
            handleOnChange(
              {
                ...person,
                customerType: v
              },
              'customerType',
              isValidField(v, requiredFieldsToUse?.customerType)
            )
          }}
          options={[CUSTOMER_TYPE.PRIVATE, CUSTOMER_TYPE.BUSINESS]}
          optionsLabels={customerTypeOptions}
          required={requiredFieldsToUse?.customerType}
          uiType="radio"
          value={customerType}
        />
      </>
    ),
    companyName: (
      <TextField
        error={error && errors?.companyName ? true : false}
        fullWidth
        helperText={
          error && errors?.companyName
            ? errorMessages?.companyName || t('field_required')
            : undefined
        }
        label={labels?.companyName || t('companyName')}
        onChange={(e) =>
          handleOnChange(
            { ...person, companyName: e.target.value },
            'companyName',
            isValidField(e.target.value, requiredFieldsToUse?.companyName)
          )
        }
        placeholder={placeHolders?.companyName}
        required={requiredFieldsToUse?.companyName}
        value={person?.companyName}
      />
    ),
    registryCourt: (
      <TextField
        error={error && errors?.registryCourt ? true : false}
        fullWidth
        helperText={
          error && errors?.registryCourt
            ? errorMessages?.registryCourt || t('field_required')
            : undefined
        }
        label={labels?.registryCourt || t('registryCourt')}
        onChange={(e) => {
          handleOnChange(
            { ...person, registryCourt: e.target.value },
            'registryCourt',
            isValidField(e.target.value, requiredFieldsToUse?.registryCourt)
          )
        }}
        placeholder={placeHolders?.registryCourt}
        required={requiredFieldsToUse?.registryCourt}
        value={person?.registryCourt}
      />
    ),
    registerNumber: (
      <TextField
        error={error && errors?.registerNumber ? true : false}
        fullWidth
        helperText={
          error && errors?.registerNumber
            ? errorMessages?.registerNumber || t('field_required')
            : undefined
        }
        label={labels?.registerNumber || t('registerNumber')}
        onChange={(e) => {
          handleOnChange(
            { ...person, registerNumber: e.target.value },
            'registerNumber',
            isValidField(e.target.value, requiredFieldsToUse?.registerNumber)
          )
        }}
        placeholder={placeHolders?.registerNumber}
        required={requiredFieldsToUse?.registerNumber}
        value={person?.registerNumber}
      />
    )
  }

  return (
    <>
      <H2>{label}</H2>
      {Object.keys(newFieldsOrder) &&
        Object.keys(newFieldsOrder).map((k, i) => {
          return (
            <Grid key={k + i}>
              <Grid container spacing={3}>
                {newFieldsOrder[k] &&
                  newFieldsOrder[k].map((field: FieldOrder, index: number) => {
                    if (field.name && blocks[field.name]) {
                      return (
                        <Grid
                          item
                          md={6}
                          sm={12}
                          xs={12}
                          {...field.gridItemProps}
                          key={index}
                        >
                          {field.name ? blocks[field.name] : <></>}
                        </Grid>
                      )
                    }

                    return null
                  })}
              </Grid>
              {i < Object.keys(newFieldsOrder).length - 1 && (
                <Divider
                  orientation="horizontal"
                  style={{ width: '100%', margin: '16px 0px' }}
                />
              )}
            </Grid>
          )
        })}
    </>
  )
}
const isValidField = (value: any, isRequired?: boolean) => {
  return (value === undefined || value.length === 0) && isRequired
}
const showCompanyFieldsBasedOnCondition = (
  showCondition?: OPTIONS_VALUE_FOR_COMPANY_NAME_CONDITION,
  selectedCustomerType?: string
) => {
  return (
    showCondition === OPTIONS_VALUE_FOR_COMPANY_NAME_CONDITION.ALWAYS ||
    selectedCustomerType === CUSTOMER_TYPE.BUSINESS
  )
}
const removeFieldsBasedOnCondition = (
  fieldsOrder?: FieldOrder[],
  showCondition?: {
    [S in keyof CustomerTypeAndCompany]: string
  },
  person?: Person,
  defaultCustomerTypeSelection?: string
) => {
  let newFieldOrder = fieldsOrder
  const selectedCustomerType = person?.customerType

  newFieldOrder &&
    newFieldOrder.map((k) => {
      const fieldName = k.name

      const isCompanyField = CUSTOMER_TYPE_FIELDS.includes(fieldName)

      if (isCompanyField) {
        const showFieldCondition = showCondition?.[fieldName as never]

        const isShowField = showCompanyFieldsBasedOnCondition(
          showFieldCondition,
          selectedCustomerType || defaultCustomerTypeSelection
        )

        if (!isShowField) {
          newFieldOrder =
            newFieldOrder && newFieldOrder.filter((field) => field !== k)
        }
      }
    })

  return newFieldOrder
}

const generateGroupsFieldsOrder = (fieldsOrder?: FieldOrder[]) => {
  const fieldsOrderGroup: Record<string, FieldOrder[]> = {}

  fieldsOrder &&
    fieldsOrder.forEach((fieldOrder) => {
      const groupNames = Object.keys(fieldsOrderGroup)
      const fieldGroupName = fieldOrder.groupName

      if (fieldGroupName) {
        if (!groupNames.includes(fieldGroupName)) {
          fieldsOrderGroup[fieldGroupName] = [fieldOrder]
        } else {
          fieldsOrderGroup[fieldGroupName] =
            fieldsOrderGroup[fieldGroupName].concat(fieldOrder)
        }
      }
    })

  return fieldsOrderGroup
}
