import { Hidden } from '@epilot/base-elements'
import { EnumControl } from '@epilot/base-modules'
import { rankWith, isEnumControl, and, optionIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'

import { EpilotControlProps } from '../../utils'
import { includeCommon } from '../../utils/includeCommon'

export type TabsControlOptions = {
  optionsLabels?: string[]
  values?: any[]
  clearPathOnChange?: string[]
}

function TabsControl({
  data,
  handleChange,
  path,
  uischema,
  errors,
  enabled,
  required,
  schema,
  visible
}: EpilotControlProps) {
  const { errorMessage, examples } = schema
  const { label } = uischema
  const {
    optionsLabels,
    values = [],
    clearPathOnChange = []
  } = (uischema.options as TabsControlOptions) || {}

  const placeholder =
    Array.isArray(examples) && examples[0] ? examples[0] : label

  const handleOnChange = (value: string) => {
    if (clearPathOnChange && clearPathOnChange.length > 0) {
      clearPathOnChange.forEach((path) => {
        handleChange(path, undefined)
      })
    }
    handleChange(path, value)
  }

  return (
    <Hidden xsUp={!visible}>
      <EnumControl
        disabled={!enabled}
        errorMessage={
          errors
            ? errorMessage && typeof errorMessage
              ? errorMessage
              : errors
            : ''
        }
        label={label as string}
        onChange={handleOnChange}
        options={values}
        optionsLabels={optionsLabels}
        placeholder={placeholder}
        required={required}
        uiType="tabs"
        value={data || ''}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: TabsControl }) as never
)

export const TabsControlTester = rankWith(
  5,
  and(isEnumControl, optionIs('tabs', true))
)
