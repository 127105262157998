import { Icon, KeyboardDatePicker } from '@epilot/base-elements'
import { toDashCase } from '@epilot/journey-logic-commons'
import { add } from 'date-fns'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { addDate } from '../../utils/helper'
import { useResetOnChange } from '../../utils/hooks/forms/useResetOnChange'

import { FieldDatePickerProps } from './types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FieldDatePicker = <T extends Record<string, any>>(
  props: FieldDatePickerProps<T>
) => {
  const {
    hasError,
    control,
    path,
    name,
    label,
    placeholder,
    errorMessage,
    required,
    rules,
    format,
    disableDays,
    limits,
    disableFuture,
    formProps,
    defaultValue
  } = props

  // resets default value if it changes
  useResetOnChange(formProps?.resetField, {
    name: name,
    watch: defaultValue,
    value: defaultValue
  })

  const { t } = useTranslation()
  const id = `${toDashCase(path)}-${name}`

  const minDate =
    typeof limits?.minByDays === 'number'
      ? add(addDate(), {
          days: limits.minByDays
        })
      : undefined

  const maxDate =
    typeof limits?.maxByDays === 'number'
      ? add(addDate(), {
          days: limits.maxByDays
        })
      : undefined

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <KeyboardDatePicker
            InputLabelProps={{ htmlFor: id }}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            autoOk
            disableFuture={disableFuture}
            disablePast={limits?.disablePast}
            disableToolbar
            error={hasError && fieldState.invalid}
            format={format}
            fullWidth
            helperText={
              hasError && fieldState.invalid
                ? errorMessage ||
                  fieldState.error?.message ||
                  t('field_required')
                : ''
            }
            id={id}
            keyboardIcon={<Icon color="secondary" name="event" />}
            label={label}
            margin="none"
            maxDate={maxDate}
            minDate={minDate}
            placeholder={placeholder}
            required={required}
            shouldDisableDate={
              disableDays
                ? (date) => {
                    if (!date) return false
                    const day = date.getDay() as 0 | 1 | 2 | 3 | 4 | 5 | 6

                    return disableDays.includes(day)
                  }
                : undefined
            }
            variant="inline"
            {...field}
            onChange={(value) => {
              // required to gain consistent behaviour. Without it, the picked date would include a time, and the typed in date would be at midnight.
              return field.onChange(value ? addDate({ date: value }) : value)
            }}
            value={field.value || null}
          />
        )
      }}
      rules={{
        required,
        ...rules
      }}
    />
  )
}
