import { Hidden, InlineSpacing, SpacingProps } from '@epilot/base-elements'
import { LayoutProps, Layout, rankWith, uiTypeIs, or } from '@jsonforms/core'
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'

export type InlineSpacingLayoutOptions = SpacingProps & {}

const InlineSpacingLayout = ({
  uischema,
  renderers,
  schema,
  path,
  enabled,
  visible
}: LayoutProps) => {
  const layout = uischema as Layout
  const { scale = 2, ...rest } =
    uischema.options || ({} as InlineSpacingLayoutOptions)

  return (
    <Hidden xsUp={!visible}>
      <InlineSpacing scale={scale} {...rest}>
        {layout.elements.map((child, index) => (
          <JsonFormsDispatch
            enabled={enabled}
            key={index}
            path={path}
            renderers={renderers}
            schema={schema}
            uischema={child}
          />
        ))}
      </InlineSpacing>
    </Hidden>
  )
}

export const InlineSpacingLayoutTester = rankWith(
  2,
  or(uiTypeIs('InlineSpacingLayout'))
)

export default withJsonFormsLayoutProps(InlineSpacingLayout)
