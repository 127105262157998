import {
  EIcon,
  Grid,
  Hidden,
  StackSpacing,
  IconButton,
  LargeText,
  Divider,
  BodyText
} from '@epilot/base-elements'
import { RankedTester, rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { EpilotControlProps, S3File } from '../../utils'
import { JOURNEY_NAVIGATION_EVENT } from '../../utils/constants/EventConstant'
import { EventDetailType } from '../../utils/constants/types'
import { CONTROL_NAME, toDateString } from '../../utils/helper'
import { includeCommon } from '../../utils/includeCommon'

export type SummaryControlRendererOptions = {
  blocksInSummary?: Record<string, boolean>
  title?: string
  subTitle?: string
  showPaper?: boolean
}
function handleOnEditorButton(index: number, blockName?: string) {
  const event = new CustomEvent<EventDetailType>(JOURNEY_NAVIGATION_EVENT, {
    detail: {
      eventCaller: 'SummaryBlock',
      payload: { stepIndex: index, blockName: blockName }
    }
  })

  document.dispatchEvent(event)
}
//Data mapping based on required order and block info
function getRenderData(type: string, block: any, t: TFunction) {
  switch (type) {
    case CONTROL_NAME.PERSONAL_INFORMATION_CONTROL:
      //2d array for collect the data for each Block Control type
      return [
        [t(block?.salutation), block?.title],
        [block?.firstName, block?.lastName],
        [block?.birthDate ? toDateString(block.birthDate) : undefined],
        [block?.email],
        [block?.telephone],
        [block?.companyName],
        [block?.registryCourt],
        [block?.registerNumber]
      ]
    case CONTROL_NAME.ADDRESS_BLOCK:
      return [
        [t(block?.salutation), block?.title],
        [block?.firstName, block?.lastName],
        [block?.streetName, block?.houseNumber],
        [block?.zipCode, block?.city],
        [block?.addressExtention || block?.extention],
        [block?.companyName]
      ]
    case CONTROL_NAME.UPLOAD_CONTROL: {
      const fileNames = block?.map((file: S3File) => {
        return [file.original_name]
      })

      return fileNames || []
    }

    case CONTROL_NAME.DATE_PICKER: {
      const dateStringStart = block && toDateString(block.startDate)
      const dateStringEnd = block && toDateString(block.endDate)

      return [
        [`${dateStringStart}${dateStringEnd ? ` - ${dateStringEnd}` : ''}`]
      ]
    }

    case CONTROL_NAME.CONTROL: {
      let type = (typeof block).toString()

      if (Array.isArray(block)) type = 'array'

      switch (type) {
        case 'boolean':
          return [[block ? 'Ja' : 'Nein']]
        case 'array':
          return [[block.join()]]
        default:
          return [[block]]
      }
    }
    case CONTROL_NAME.PAYMENT_CONTROL: {
      return [
        [block?.label || block?.type],
        [block?.data?.fullname],
        [block?.data?.iban]
      ]
    }

    case CONTROL_NAME.NUMBER_INPUT_CONTROL: {
      return [
        [
          `${block.numberInput}${
            block.numberUnit ? ` ${block.numberUnit}` : ''
          }`
        ]
      ]
    }

    default:
      return [[block]]
  }
}
// process block name string
function processDataKey(key: string) {
  return key.includes('/') ? key.split('/')[1] : key
}
// get Control Type, e.g PersonalInformationControl is type of '2/Contact Info/PersonalInformationControl'
function getTypeFromKey(key: string) {
  const split = key.split('/')

  if (split.length > 2) return split[2]
  else return ''
}

function renderBlockGroup(
  key: string,
  blockRenderData: { [name: string]: any }
) {
  const isEmpty =
    !blockRenderData ||
    blockRenderData === null ||
    Object.values(blockRenderData).join('').replaceAll(',', '') === ''
  let stepIndex = 0
  let blockName: string | undefined = undefined

  if (key.includes('/')) {
    const parts = key.split('/')

    stepIndex = parseInt(parts[0]) - 1
    blockName = parts[1]
  }

  if (isEmpty) {
    return <></>
  }

  return (
    <StackSpacing alignItems="stretch" key={key} scale={2}>
      <Grid container>
        <Grid
          item
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <LargeText color="textPrimary">
            <b>{processDataKey(key)}</b>
          </LargeText>
          <IconButton
            onClick={() => handleOnEditorButton(stepIndex, blockName)}
          >
            <EIcon color="secondary" name="pencil2" />
          </IconButton>
        </Grid>
        <Divider orientation="horizontal" style={{ width: '100%' }}></Divider>
      </Grid>
      <StackSpacing alignItems="stretch" scale={3}>
        {blockRenderData.map((row: any, index: number) => {
          //join text without space and undefine
          let joinedText = ''

          row.forEach((item: string) => {
            if (item) joinedText += item + ' '
          })
          if (joinedText) {
            return (
              <Grid item key={index}>
                <BodyText color={'textSecondary'}>{joinedText}</BodyText>
              </Grid>
            )
          } else {
            return <></>
          }
        })}
      </StackSpacing>
    </StackSpacing>
  )
}

function SummaryControlRenderer({ visible, data }: EpilotControlProps) {
  const renderOutput: JSX.Element[] = []
  const { t } = useTranslation()

  data &&
    Object.keys(data).forEach((key) => {
      const rawData = data[key]
      const blockType: string = getTypeFromKey(key)

      //render each block based on mapped and ordered Block Control data
      renderOutput.push(
        renderBlockGroup(key, getRenderData(blockType, rawData, t))
      )
    })

  return (
    <Hidden xsUp={!visible}>
      <div>
        <StackSpacing alignItems="stretch" scale={2}>
          {renderOutput}
        </StackSpacing>
      </div>
    </Hidden>
  )
}

export const SummaryControlTester: RankedTester = rankWith(
  3,
  uiTypeIs('SummaryControl')
)

export default withJsonFormsControlProps(
  includeCommon({ component: SummaryControlRenderer }) as never
)
