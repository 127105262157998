import { Hidden } from '@epilot/base-elements'
import { BooleanControl } from '@epilot/base-modules'
import { schemaTypeIs, rankWith, or, and, isEnumControl } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React, { ChangeEvent } from 'react'

import { EpilotControlProps, stringToHTML } from '../../utils'
import { includeCommon } from '../../utils/includeCommon'

type BooleanCheckboxControlOptions = {
  clearPathOnChange?: string[]
  plainTextLabel?: boolean
}

function BooleanCheckboxControl({
  data,
  handleChange,
  path,
  uischema,
  errors,
  enabled,
  required,
  schema,
  visible
}: EpilotControlProps) {
  const { clearPathOnChange = [], plainTextLabel } =
    (uischema.options as BooleanCheckboxControlOptions) || {}

  const handleOnChange = (_event: ChangeEvent<HTMLInputElement>) => {
    if (clearPathOnChange && clearPathOnChange.length > 0) {
      clearPathOnChange.forEach((path) => {
        handleChange(path, undefined)
      })
    }
    if (required) {
      data ? handleChange(path, undefined) : handleChange(path, true)
    } else {
      handleChange(path, !data)
    }
  }
  const { errorMessage } = schema
  const { label, options = {} } = uischema

  return (
    <Hidden xsUp={!visible}>
      <BooleanControl
        disabled={!enabled}
        errorMessage={
          errors
            ? errorMessage && typeof errorMessage === 'string'
              ? errorMessage
              : errors
            : ''
        }
        hideRequiredAsterisk={options?.hideRequiredAsterisk}
        label={stringToHTML(label as string, {
          isPlainText: plainTextLabel
        })}
        onChange={handleOnChange}
        required={required || options?.showRequiredAsterisk}
        typographyVariant={options?.typographyVariant || 'body1'}
        uiType="checkbox"
        value={data ? true : false}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: BooleanCheckboxControl }) as never
)

export const CheckboxTester = rankWith(
  5,
  or(schemaTypeIs('boolean'), and(isEnumControl, schemaTypeIs('boolean')))
)
