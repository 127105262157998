import * as React from 'react'

function SvgProductBroadband(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="1em"
      viewBox="0 0 1024 1024"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M456.36 164.476C361.768 175.44 259.5 213.208 178 267.272c-42.308 28.068-102 78.776-102 86.652C76 360.736 86.868 372 93.44 372c2.68 0 16.864-10.648 31.528-23.664 51.812-45.996 104.42-79.068 171.616-107.888C508.42 149.58 754.216 191.38 931.032 348.336 945.696 361.352 959.88 372 962.56 372c6.572 0 17.44-11.264 17.44-18.076 0-7.876-59.692-58.584-102-86.652-124.12-82.336-276.88-119.58-421.64-102.796m6.692 257.556c-60.74 11.54-116.664 36.276-163.5 72.312-12.656 9.74-26.764 22.48-31.352 28.312-7.356 9.352-7.688 11.588-2.792 18.972 8.78 13.248 15.968 11.94 36.968-6.732C369.668 475.056 439.264 449.672 534 450.4c89.856.696 149.788 23.32 216.56 81.748C763.036 543.068 775.564 552 778.396 552c8.504 0 17.728-16.292 13.596-24.016-4.832-9.028-52.948-47.6-77.992-62.524-27.324-16.28-66.804-32.208-98-39.536-38.488-9.04-115.54-11-152.948-3.892M502 675.52c-50.532 13.34-86 58.08-86 108.48 0 60.232 51.768 112 112 112s112-51.768 112-112c0-57.448-44.976-105.436-102.984-109.884-12.66-.972-28.416-.34-35.016 1.404m68.124 41.788c8.044 5.468 19.392 16.86 25.216 25.316 8.804 12.788 10.58 19.752 10.544 41.376-.04 21.944-1.832 28.652-11.5 43.004C577.14 852.604 559.32 862 528 862s-49.14-9.396-66.384-34.996c-9.668-14.352-11.46-21.06-11.5-43.004-.036-21.624 1.74-28.588 10.544-41.376 10.832-15.728 31.336-31.94 45.44-35.924 16.864-4.76 49.316.616 64.024 10.608" />
    </svg>
  )
}

export default SvgProductBroadband
