import { MobileStepper, Button, EIcon, Box } from '@epilot/base-elements'
import * as React from 'react'
import { CSSProperties } from 'react'
import SwipeableViews from 'react-swipeable-views'

import { defaultImageStyles } from '../ProductSelectionControl'

const sharedButtonStyle: CSSProperties = {
  opacity: 0.8,
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)'
}

export interface Props {
  images: { label: string; imgPath: string }[]
}

export function ImageStepper({ images }: Props) {
  const [activeStep, setActiveStep] = React.useState(0)
  const maxSteps = images.length

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStepChange = (step: number) => {
    setActiveStep(step)
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <div style={{ position: 'relative' }}>
        <SwipeableViews
          enableMouseEvents
          index={activeStep}
          onChangeIndex={handleStepChange}
        >
          {images.map((step, index) => (
            <div key={step.label}>
              {Math.abs(activeStep - index) <= 2 ? (
                <img
                  alt={step.label}
                  src={step.imgPath}
                  style={defaultImageStyles}
                />
              ) : null}
            </div>
          ))}
        </SwipeableViews>
        {activeStep !== 0 && (
          <div
            style={{
              left: 0,
              ...sharedButtonStyle
            }}
          >
            <Button onClick={handleBack} size="small">
              <EIcon color="primary" name="arrow-left-circle-fill" />
            </Button>
          </div>
        )}
        {activeStep !== maxSteps - 1 && (
          <div
            style={{
              right: 0,
              ...sharedButtonStyle
            }}
          >
            <Button onClick={handleNext} size="small">
              <EIcon
                color="primary"
                name="arrow-left-circle-fill"
                style={{ transform: 'rotate(180deg)' }}
              />
            </Button>
          </div>
        )}
      </div>
      <div style={{ clear: 'both' }}></div>
      {images?.length === 1 && <div style={{ height: '35px' }}></div>}
      {images?.length > 1 && (
        <MobileStepper
          activeStep={activeStep}
          backButton={<div />}
          nextButton={<div />}
          position="static"
          steps={maxSteps}
          style={{ marginTop: '10px' }}
        />
      )}
    </Box>
  )
}

export default ImageStepper
