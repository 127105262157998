import { usePrevious } from '@epilot/journey-logic-commons'
import isEqual from 'fast-deep-equal'
import { useEffect, useRef } from 'react'
import {
  useForm,
  SubmitErrorHandler,
  SubmitHandler,
  UnpackNestedValue,
  DeepPartial,
  UseFormProps
} from 'react-hook-form'

type UseFormHandlerParams<T> = {
  handleChange: (path: string, value: T | undefined | null) => void
  path: string
  data: UnpackNestedValue<DeepPartial<T>> | undefined | null
  formProps?: UseFormProps<T>
  fields?: unknown
  isRequired?: boolean // based on schema, not on field level.
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useFormHandler = <T extends Record<string, any>>(
  params: UseFormHandlerParams<T>
) => {
  const { handleChange, path, formProps, isRequired, fields, data } = params
  const { watch, handleSubmit, reset, trigger, ...rest } = useForm<T>(formProps)
  const fieldValues = watch()
  const prevValues = usePrevious(fieldValues)
  const isMounted = useRef(true)

  const onValid: SubmitHandler<T> = (values: T | undefined) => {
    if (isMounted.current) handleChange(path, values)
  }

  const onError: SubmitErrorHandler<T> = () => {
    // do not update if the value is the same
    if (!isRequired && data == null) {
      return
    }
    if (isRequired && typeof data === 'undefined') {
      return
    }

    // in case of error, and json forms schema is not required, pass null to instruct JSON Forms this form is in error state
    return handleChange(path, !isRequired ? null : undefined)
  }

  useEffect(() => {
    if (!isEqual(prevValues, fieldValues)) {
      handleSubmit(onValid, onError)()
    }
  }, [fieldValues])

  // trigger validation if field options change
  useEffect(() => {
    trigger()
  }, [fields, trigger])

  // reset to default, or given values if path changed
  useEffect(() => {
    reset(data ?? undefined)
  }, [path])

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  return {
    ...rest,
    trigger,
    watch,
    reset,
    handleSubmit,
    fieldValues
  }
}
