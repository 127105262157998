import * as React from 'react'

function SvgEmptyStyle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 140 104"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M70 104c28.77 0 52.093-23.281 52.093-52S98.77 0 70 0C41.23 0 17.907 23.281 17.907 52S41.23 104 70 104z"
        fill="#F9FAFB"
      />
      <g filter="url(#empty-style_svg__filter0_d)">
        <path
          d="M39.642 42.25c-7.917 0-14.636 5.026-17.019 12h-8.962v.235h-.008c-7.465 0-13.53 5.873-13.651 13.161L0 67.868c0 7.39 6.112 13.382 13.652 13.382h44.713v-.005c7.36-.203 13.263-6.114 13.263-13.377 0-7.263-5.903-13.175-13.263-13.377v-.241h-1.704c-2.382-6.974-9.102-12-17.019-12z"
          fill="#000"
        />
      </g>
      <path
        d="M39.642 42.25c-7.917 0-14.636 5.026-17.019 12h-8.962v.235h-.008c-7.465 0-13.53 5.873-13.651 13.161L0 67.868c0 7.39 6.112 13.382 13.652 13.382h44.713v-.005c7.36-.203 13.263-6.114 13.263-13.377 0-7.263-5.903-13.175-13.263-13.377v-.241h-1.704c-2.382-6.974-9.102-12-17.019-12z"
        fill="#fff"
      />
      <path
        d="M36.63 36.546c.9 0 1.63-.724 1.63-1.617s-.73-1.617-1.63-1.617c-.9 0-1.63.724-1.63 1.617s.73 1.617 1.63 1.617zM9.769 64.993c.45 0 .815-.363.815-.81a.812.812 0 00-.815-.808.812.812 0 00-.816.809c0 .446.365.809.816.809z"
        fill="#EFF2F3"
      />
      <g filter="url(#empty-style_svg__filter1_d)">
        <path
          d="M96.54 9.75c-4.592 0-8.508 3.065-10.026 7.37H85.29v.018a8.038 8.038 0 00-.548-.019c-4.546 0-8.231 3.807-8.231 8.503s3.685 8.503 8.23 8.503c.185 0 .368-.006.55-.019v.019h31.826c4.546 0 8.231-3.807 8.231-8.503s-3.685-8.503-8.231-8.503h-10.551c-1.518-4.304-5.434-7.369-10.026-7.369z"
          fill="#000"
        />
      </g>
      <path
        d="M96.54 9.75c-4.592 0-8.508 3.065-10.026 7.37H85.29v.018a8.038 8.038 0 00-.548-.019c-4.546 0-8.231 3.807-8.231 8.503s3.685 8.503 8.23 8.503c.185 0 .368-.006.55-.019v.019h31.826c4.546 0 8.231-3.807 8.231-8.503s-3.685-8.503-8.231-8.503h-10.551c-1.518-4.304-5.434-7.369-10.026-7.369z"
        fill="#fff"
      />
      <path
        d="M83.567 80.704c.3 0 .543-.241.543-.54a.542.542 0 00-.543-.539c-.3 0-.544.242-.544.54 0 .298.244.54.544.54zM73.8 53.08c.3 0 .543-.242.543-.54a.542.542 0 00-.544-.54c-.3 0-.543.242-.543.54 0 .298.243.54.543.54z"
        fill="#EFF2F3"
      />
      <path
        clipRule="evenodd"
        d="M113.957 45.5l.001.008a2.804 2.804 0 002.445 2.417 2.808 2.808 0 00-2.445 2.42l-.001.005a2.803 2.803 0 00-2.444-2.425h-.001a2.8 2.8 0 002.445-2.424V45.5z"
        fill="#E5EBEE"
        fillRule="evenodd"
        stroke="#E5EBEE"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M131.863 60.125l.001.005a1.868 1.868 0 001.63 1.612 1.87 1.87 0 00-1.63 1.613l-.001.003a1.868 1.868 0 00-1.629-1.616h-.001a1.868 1.868 0 001.63-1.616v-.001z"
        fill="#EFF2F3"
        fillRule="evenodd"
        stroke="#EFF2F3"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M32.562 66.625l.001.008a2.803 2.803 0 002.445 2.417 2.807 2.807 0 00-2.445 2.42l-.001.005a2.803 2.803 0 00-2.444-2.425h-.002a2.802 2.802 0 002.446-2.424v-.001z"
        fill="#E5EBEE"
        fillRule="evenodd"
        stroke="#E5EBEE"
        strokeLinejoin="round"
      />
      <path
        d="M101.744 36.563v4.85M104.194 39h-4.892M42.326 13v4.85M44.775 15.438h-4.891M69.186 13v2.694M70.276 14.626h-2.718"
        stroke="#EFF2F3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M78.956 80.438v.005a1.868 1.868 0 001.63 1.611 1.87 1.87 0 00-1.63 1.613v.004a1.869 1.869 0 00-1.63-1.617h0a1.868 1.868 0 001.63-1.616h0z"
        fill="#E5EBEE"
        fillRule="evenodd"
        stroke="#E5EBEE"
        strokeLinejoin="round"
      />
      <path
        d="M23.605 43.063v2.694M24.694 44.688h-2.717"
        stroke="#EFF2F3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g filter="url(#empty-style_svg__filter2_d)">
        <path
          d="M130.895 89.375v-.072C136.024 88.7 140 84.415 140 79.219c0-5.61-4.632-10.156-10.347-10.156-.082 0-.164 0-.245.002-1.737-5.191-6.715-8.94-12.585-8.94-5.993 0-11.056 3.907-12.69 9.267a10.53 10.53 0 00-2.623-.33c-5.715 0-10.347 4.548-10.347 10.157s4.632 10.156 10.347 10.156c.42 0 .834-.025 1.241-.072v.072h28.144z"
          fill="#000"
        />
      </g>
      <path
        d="M130.895 89.375v-.072C136.024 88.7 140 84.415 140 79.219c0-5.61-4.632-10.156-10.347-10.156-.082 0-.164 0-.245.002-1.737-5.191-6.715-8.94-12.585-8.94-5.993 0-11.056 3.907-12.69 9.267a10.53 10.53 0 00-2.623-.33c-5.715 0-10.347 4.548-10.347 10.157s4.632 10.156 10.347 10.156c.42 0 .834-.025 1.241-.072v.072h28.144z"
        fill="#fff"
      />
      <path
        d="M109.356 76.934c.608 0 1.101-.489 1.101-1.092 0-.603-.493-1.092-1.101-1.092-.607 0-1.1.489-1.1 1.092 0 .603.493 1.092 1.1 1.092z"
        fill="#D2DEE4"
      />
      <mask
        height={4}
        id="empty-style_svg__a"
        maskUnits="userSpaceOnUse"
        width={8}
        x={114}
        y={78}
      >
        <path d="M121.371 78h-6.604v3.64h6.604V78z" fill="#fff" />
      </mask>
      <g mask="url(#empty-style_svg__a)">
        <path
          d="M118.069 82.03c1.824 0 3.302-1.63 3.302-3.64 0-2.01-1.478-3.64-3.302-3.64-1.823 0-3.302 1.63-3.302 3.64 0 2.01 1.479 3.64 3.302 3.64z"
          fill="#D2DEE4"
        />
      </g>
      <path
        d="M124.008 76.934c.607 0 1.1-.489 1.1-1.092 0-.603-.493-1.092-1.1-1.092-.608 0-1.101.489-1.101 1.092 0 .603.493 1.092 1.101 1.092z"
        fill="#D2DEE4"
      />
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height={79}
          id="empty-style_svg__filter0_d"
          width={111.628}
          x={-20}
          y={22.25}
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={10} />
          <feColorMatrix values="0 0 0 0 0.517647 0 0 0 0 0.564706 0 0 0 0 0.588235 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height={64.375}
          id="empty-style_svg__filter1_d"
          width={88.837}
          x={56.512}
          y={-10.25}
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={10} />
          <feColorMatrix values="0 0 0 0 0.517647 0 0 0 0 0.564706 0 0 0 0 0.588235 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height={69.25}
          id="empty-style_svg__filter2_d"
          width={88.837}
          x={71.163}
          y={40.125}
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={10} />
          <feColorMatrix values="0 0 0 0 0.517647 0 0 0 0 0.564706 0 0 0 0 0.588235 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgEmptyStyle
