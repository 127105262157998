import { Hidden } from '@epilot/base-elements'
import { rankWith, and, schemaTypeIs, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'

import { EpilotControlProps } from '../../..'
import { includeCommon } from '../../../utils/includeCommon'

import { DatePickerForm } from './components/DatePickerForm'
import {
  isDatePickerControlData,
  isDatePickerControlOptions
} from './types.guard'

function DatePickerControl({
  data,
  handleChange,
  path,
  uischema,
  errors,
  visible,
  required
}: EpilotControlProps) {
  const options = uischema.options
  const hasError = !!errors

  if (!isDatePickerControlOptions(options)) {
    console.error(
      'DatePickerControl -> Invalid uischema.options detected. Passed options are:',
      { options }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  if (!isDatePickerControlData(data)) {
    console.error(
      'DatePickerControl -> Invalid data detected. Current data is:',
      {
        data
      }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  return (
    <Hidden xsUp={!visible}>
      <DatePickerForm
        data={data}
        handleChange={handleChange}
        hasError={hasError}
        isRequired={!!required}
        path={path}
        {...options}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({
    component: DatePickerControl
  }) as never
)

export const DatePickerControlTester = rankWith(
  2,
  and(schemaTypeIs('object'), uiTypeIs('DatePickerControl'))
)
