import * as React from 'react'

function SvgEPilot(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg height="1em" viewBox="0 0 259 80" width="1em" {...props}>
      <g fill="#039BE5" fillRule="nonzero">
        <path d="M69.782 27.913a27.72 27.72 0 01-2.99 12.585 28.265 28.265 0 01-2.12 3.54 28.914 28.914 0 01-4.959 5.358 26.092 26.092 0 01-3.863 2.691 27.802 27.802 0 01-11.414 3.614c-.324.025-.648.05-.997.075a16.41 16.41 0 00.424-3.689c0-4.386 2.368-8.224 5.906-10.318.025 0 .025-.024.05-.024l.15-.075a16.34 16.34 0 002.866-2.168 16.086 16.086 0 002.716-3.365 15.848 15.848 0 002.268-8.224c0-1.271-.15-2.517-.423-3.689a16.177 16.177 0 00-1.57-4.037 15.929 15.929 0 00-13.957-8.224 15.929 15.929 0 00-13.956 8.224c-4.86 0-9.446 1.072-13.558 3.015C16.598 10.02 28.062 0 41.87 0 55.676 0 67.14 10.044 69.383 23.202c.274 1.52.399 3.116.399 4.71zM83.738 52.087C83.738 67.514 71.252 80 55.826 80c-3.415 0-6.68-.623-9.695-1.72 3.838-2.666 7.053-6.18 9.42-10.243h.275c8.797 0 15.95-7.127 15.95-15.95 0-3.015-.823-5.832-2.268-8.224a31.615 31.615 0 004.162-13.259c6.156 5.134 10.068 12.86 10.068 21.483z" />
        <path d="M53.832 27.913c0 1.57-.3 3.065-.848 4.436a12.307 12.307 0 01-2.267 3.614 11.499 11.499 0 01-2.767 2.243c-.025 0-.025.025-.05.025l-.15.074a15.827 15.827 0 00-5.582 5.533c-.1.174-.2.324-.299.498a15.909 15.909 0 00-1.994 7.726c0 1.271-.199 2.468-.548 3.614a12.247 12.247 0 01-1.994 3.788 11.967 11.967 0 01-6.828 4.287 27.709 27.709 0 01-2.169-6.978 26.502 26.502 0 01-.398-4.71c0-4.188.922-8.125 2.567-11.69.15-.298.274-.597.448-.896a29.12 29.12 0 011.57-2.692c.175-.274.374-.548.549-.822a27.655 27.655 0 018.847-8.05 26.66 26.66 0 014.262-2.019 27.218 27.218 0 017.127-1.62 13.37 13.37 0 01.524 3.639z" />
        <path d="M55.427 56.798a27.709 27.709 0 01-2.168 6.978 26.414 26.414 0 01-1.994 3.588 28.155 28.155 0 01-9.396 8.898 26.66 26.66 0 01-4.262 2.018c-3.015 1.122-6.28 1.72-9.694 1.72C12.486 80 0 67.514 0 52.087c0-8.623 3.913-16.349 10.093-21.483a26.092 26.092 0 013.863-2.691 27.631 27.631 0 0112.387-3.689c.523-.025 1.046-.05 1.57-.05.872 0 1.72.05 2.542.125 2.492.224 4.885.773 7.128 1.62-2.592 1.794-4.885 3.988-6.854 6.455a32.607 32.607 0 00-2.567 3.788h-.274c-4.237 0-8.1 1.645-10.966 4.361a16.086 16.086 0 00-2.716 3.365 15.848 15.848 0 00-2.268 8.224c0 8.823 7.152 15.95 15.95 15.95h.274a15.262 15.262 0 004.287-.673 15.898 15.898 0 009.395-7.551c1.545 0 3.066-.1 4.561-.324a32.114 32.114 0 009.022-2.716z" />
        <g>
          <path d="M258.692 59.863a8.81 8.81 0 01-4.486 1.196 9.182 9.182 0 01-2.991-.498 8.97 8.97 0 01-5.981-8.474V25.171h-3.988V19.19h3.988v-5.981h5.981v5.981h5.981v5.981h-5.981v26.916a3 3 0 002.99 2.99c.549 0 1.047-.149 1.496-.398l2.99 5.184zM221.308 25.171c-9.919 0-17.944 8.025-17.944 17.944 0 9.92 8.025 17.944 17.944 17.944 9.92 0 17.944-8.025 17.944-17.944 0-9.919-8.025-17.944-17.944-17.944zm0 29.907c-6.604 0-11.962-5.358-11.962-11.963 0-6.604 5.358-11.962 11.962-11.962 6.605 0 11.963 5.358 11.963 11.962 0 6.605-5.358 11.963-11.963 11.963zM191.402 13.209h5.98v47.85h-5.98z" />
          <g transform="translate(178.442 15.202)">
            <path d="M.997 9.969h5.981v35.888H.997z" />
            <circle cx={3.988} cy={3.988} r={3.988} />
          </g>
          <path d="M155.514 25.171c-4.586 0-8.797 1.72-11.963 4.561-3.663 3.29-5.98 8.05-5.98 13.383v29.907h5.98V56.498a17.872 17.872 0 0011.963 4.561c9.919 0 17.944-8.025 17.944-17.944 0-9.919-8.025-17.944-17.944-17.944zm0 29.907c-6.604 0-11.963-5.358-11.963-11.963 0-6.604 5.359-11.962 11.963-11.962 6.604 0 11.963 5.358 11.963 11.962 0 6.605-5.359 11.963-11.963 11.963zM131.589 43.115c0-1.022-.075-2.018-.25-2.99-1.42-8.474-8.797-14.954-17.694-14.954-8.897 0-16.274 6.48-17.695 14.954-.174.972-.25 1.968-.25 2.99 0 1.022.076 2.019.25 2.99a17.705 17.705 0 002.143 5.982c3.116 5.358 8.898 8.972 15.552 8.972s12.436-3.614 15.551-8.972h-7.626a11.9 11.9 0 01-7.9 2.99 11.851 11.851 0 01-7.9-2.99c-1.77-1.545-3.066-3.638-3.689-5.981h29.259c.174-.972.249-1.969.249-2.99zm-29.533-2.99a11.958 11.958 0 0111.589-8.972 11.958 11.958 0 0111.589 8.972h-23.178z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgEPilot
