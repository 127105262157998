export const PRICING_API_URL =
  process.env.REACT_APP_JOURNEYS_PRICING_API ??
  'https://pricing-api.dev.sls.epilot.io'

export const FILE_API_URL =
  process.env.REACT_APP_FILE_API_URL ?? 'https://file.dev.sls.epilot.io'

export const ADDRESS_API_URL =
  process.env.REACT_APP_ADDRESS_API_URL ??
  'https://jdk4pkdou2.execute-api.eu-central-1.amazonaws.com/dev'

export const UNLEASH_URL =
  process.env.REACT_APP_UNLEASH_URL ??
  'https://gitlab.com/api/v4/feature_flags/unleash/27383646'

export const UNLEASH_INSTANCE_ID =
  process.env.REACT_APP_UNLEASH_INSTANCE_ID ?? 'y4wLt37n6YQtwcELYA7n'

export const STAGE = process.env.REACT_APP_STAGE ?? 'dev'

export const PREVENT_COMMON_COMPONENT =
  Boolean(process.env.REACT_APP_PREVENT_COMMON_COMPONENT) || false
