import { Typography } from '@material-ui/core'

import styled from '../styled'
import { getPrefix } from '../utils'

type TypographyStyledProps = {
  fontWeight?: number
  lineHeight?: string
  $customColor?: string
}

const TypographyStyled = styled(Typography)<TypographyStyledProps>`
  &.${getPrefix}-MuiTypography-root {
    font-weight: ${(props) => props.fontWeight};
    line-height: ${(props) => props.lineHeight};
    color: ${(props) => props.$customColor};
  }

  &.${getPrefix}-MuiTypography-root a {
    color: ${(props) => props.$customColor || props.theme.palette?.primary};
  }

  &.${getPrefix}-MuiTypography-body1 {
    font-size: ${(props) => props.theme.typography?.large_size};
    line-height: ${(props) => props.lineHeight || 24}px;
    font-style: normal;
    font-weight: ${(props) =>
      props.fontWeight || props.theme.typography?.font_weight_regular};
  }

  &.${getPrefix}-MuiTypography-body2 {
    font-size: ${(props) => props.theme.typography?.main_size};
    line-height: ${(props) => props.lineHeight || 24}px;
    font-style: normal;
    letter-spacing: 0;
    font-weight: ${(props) =>
      props.fontWeight || props.theme.typography?.font_weight_regular};
  }

  &.${getPrefix}-MuiTypography-caption {
    font-size: ${(props) => props.theme.typography?.caption_size};
    line-height: ${(props) => props.lineHeight || 16}px;
    font-style: normal;
    letter-spacing: 0.5px;
    color: ${(props) => props.$customColor || props.theme.palette?.grey_dark};
    font-weight: ${(props) =>
      props.fontWeight || props.theme.typography?.font_weight_regular};
  }

  &.${getPrefix}-MuiTypography-h1 {
    font-size: ${(props) => props.theme.typography?.title_size}px;
    line-height: ${(props) => props.lineHeight || 32}px;
  }

  &.${getPrefix}-MuiTypography-h2 {
    font-size: ${(props) => props.theme.typography?.subtitle_size}px;
    line-height: ${(props) => props.lineHeight || 24}px;
    font-weight: ${(props) =>
      props.fontWeight || props.theme.typography?.font_weight_regular};
  }

  &.${getPrefix}-MuiTypography-h3 {
    font-size: ${(props) => props.theme.typography?.caption_size}px;
    letter-spacing: 1px;
    line-height: ${(props) => props.lineHeight || 16}px;
    text-transform: uppercase;
  }
`

export { TypographyStyled }
