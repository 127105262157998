import {
  BodyText,
  H1,
  StackSpacing,
  Button,
  IconsId,
  EIcon
} from '@epilot/base-elements'
import React from 'react'

import { JOURNEY_EXIT_EVENT } from '../../../../utils/constants/EventConstant'
import { EventDetailType } from '../../../../utils/constants/types'

export type ConfirmationMessageComponentProps = {
  icon?: IconsId
  title?: string
  text?: string
  closeButtonText?: string
  showCloseButton?: boolean
}

export function ConfirmationMessageComponent({
  title = '',
  text = '',
  icon = 'check-circle-fill',
  closeButtonText = 'schließen',
  showCloseButton = true
}: ConfirmationMessageComponentProps) {
  const handleOnExitButton = () => {
    const event = new CustomEvent<EventDetailType>(JOURNEY_EXIT_EVENT, {
      detail: {
        eventCaller: 'ThankYouBlock',
        payload: { stepIndex: 0, isCleanJourneyData: true }
      }
    })

    document.dispatchEvent(event)
  }

  return (
    <StackSpacing alignItems="center" scale={2}>
      <EIcon color="primary" fontSize={90} name={icon} />
      <H1 color="textPrimary">{title}</H1>
      <BodyText color="textSecondary">{text}</BodyText>
      {showCloseButton && (
        <Button
          color="primary"
          onClick={() => handleOnExitButton()}
          size="large"
          variant="contained"
        >
          {closeButtonText}
        </Button>
      )}
    </StackSpacing>
  )
}
