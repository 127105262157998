import { Hidden } from '@epilot/base-elements'
import { EnumControl } from '@epilot/base-modules'
import { rankWith, isEnumControl } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'

import { EpilotControlProps } from '../../utils'
import { includeCommon } from '../../utils/includeCommon'

export type EnumSelectControlOptions = {
  optionsLabels?: string[]
  clearPathOnChange?: string[]
}
function EnumSelectControl({
  data,
  handleChange,
  path,
  uischema,
  errors,
  enabled,
  required,
  schema,
  visible
}: EpilotControlProps) {
  const { enum: options = [], errorMessage, examples } = schema
  const { label } = uischema
  const { optionsLabels, clearPathOnChange = [] } =
    (uischema.options as EnumSelectControlOptions) || {}
  const placeholder =
    Array.isArray(examples) && examples[0] ? examples[0] : label

  const handleOnChange = (value: string) => {
    if (clearPathOnChange && clearPathOnChange.length > 0) {
      clearPathOnChange.forEach((path) => {
        handleChange(path, undefined)
      })
    }
    handleChange(path, value)
  }

  return (
    <Hidden xsUp={!visible}>
      <EnumControl
        defaultSelection={''}
        disabled={!enabled}
        errorMessage={
          errors
            ? errorMessage && typeof errorMessage
              ? errorMessage
              : errors
            : ''
        }
        label={label ? (label as string) : ''}
        onChange={handleOnChange}
        options={options}
        optionsLabels={optionsLabels}
        placeholder={placeholder}
        required={required}
        uiType="select"
        value={data || ''}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: EnumSelectControl }) as never
)

export const SelectTester = rankWith(4, isEnumControl)
