import * as PricingClient from '@epilot/pricing-client'
import type { Components } from '@epilot/pricing-client'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { PRICING_API_URL } from './config'

export type ProductEntity = Components.Schemas.Product
export type PriceEntity = Components.Schemas.Price
export type CatalogSearchResult = Components.Schemas.CatalogSearchResult

export type ProductPrice = {
  productId: string
  priceId: string
}

export const RecurrenceInterval: Record<string, string> = {
  ['monthly']: 'monthly',
  ['weekly']: 'weekly',
  ['every_quarter']: 'quarterly',
  ['every_6_months']: 'semiannually',
  ['yearly']: 'annually'
}

const getClient = () => {
  const client = PricingClient.getClient()

  client.defaults.baseURL = PRICING_API_URL

  return client
}

export async function searchPrices(orgId: string, prices: ProductPrice[]) {
  const headers = {
    'X-Ivy-Org-ID': orgId
  }
  const searchQuery = uniqueEntityIdsOf(prices)
    .filter(Boolean)
    .map((id) => `_id:${id}`)
    .join(' OR ')
  const data = {
    q: `${searchQuery}`,
    hydrate: true
  }

  return getClient().$searchCatalog(headers, data)
}

const uniqueEntityIdsOf = (prices: ProductPrice[]) =>
  Object.keys(
    prices.reduce<Record<string, string>>(
      (acc, { productId, priceId }) => ({
        ...acc,
        [productId]: productId,
        [priceId]: priceId
      }),
      {}
    )
  )

export function useSearchPrices(orgId: string, prices: ProductPrice[]) {
  const queryKeys = uniqueEntityIdsOf(prices)

  const queryResult = useQuery(
    ['SEARCH_PRODUCTS_BY_PRICES', orgId, ...queryKeys],
    () => {
      if (!orgId || prices?.length === 0) {
        return Promise.resolve({
          data: { hits: 0, results: [] }
        }) as never
      }

      return searchPrices(orgId, prices)
    }
  )

  const data = useMemo(() => queryResult.data?.data, [queryResult])

  return {
    ...({ hits: 0, results: [] } as CatalogSearchResult),
    isLoading: queryResult.isLoading,
    ...(data as CatalogSearchResult)
  }
}
