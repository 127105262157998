import * as React from 'react'

function SvgChart(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 178 148"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#chart_svg__clip0)">
        <g strokeLinejoin="round">
          <path
            d="M24.899 121.24v2.738M26.298 122.611H23.5"
            stroke="#EFF2F3"
            strokeLinecap="round"
          />
          <path
            clipRule="evenodd"
            d="M149.24 71.29l.001.008c.182 1.253 1.312 2.239 2.745 2.395-1.433.157-2.563 1.144-2.745 2.397l-.001.005c-.179-1.256-1.309-2.245-2.744-2.402h-.002c1.436-.156 2.567-1.146 2.746-2.402v-.001z"
            fill="#E5EBEE"
            fillRule="evenodd"
            stroke="#E5EBEE"
          />
        </g>
        <g stroke="#EFF2F3" strokeLinejoin="round">
          <path
            clipRule="evenodd"
            d="M161.94 41.772v.004c.114.716.82 1.28 1.716 1.369-.896.09-1.602.653-1.716 1.37v.003c-.112-.718-.818-1.283-1.715-1.373h-.001c.897-.09 1.604-.655 1.716-1.372v-.001z"
            fill="#EFF2F3"
            fillRule="evenodd"
          />
          <path
            d="M118.89 15v4.929M121.41 17.463h-5.037"
            strokeLinecap="round"
          />
        </g>
        <g fill="#EFF2F3">
          <path d="M148.56 48.638c.379 0 .686-.461.686-1.03s-.307-1.03-.686-1.03c-.38 0-.687.462-.687 1.03s.307 1.03.687 1.03zM8.86 106.99c.568 0 1.03-.307 1.03-.686s-.462-.687-1.03-.687-1.03.308-1.03.687.461.686 1.03.686zM170.52 76.782c.38 0 .687-.461.687-1.03 0-.568-.307-1.03-.687-1.03-.379 0-.686.462-.686 1.03 0 .569.307 1.03.686 1.03zM130.02 20.492c.38 0 .687-.461.687-1.03s-.307-1.03-.687-1.03c-.379 0-.686.462-.686 1.03s.307 1.03.686 1.03zM40.094 21.865a.686.686 0 100-1.373.686.686 0 000 1.373zM28.08 71.29a1.716 1.716 0 100-3.432 1.716 1.716 0 000 3.432zM69.716 15.432a1.716 1.716 0 100-3.432 1.716 1.716 0 000 3.432z" />
        </g>
        <g stroke="#EFF2F3" strokeLinecap="round" strokeLinejoin="round">
          <path d="M16.36 85.58v4.929M18.879 88.043h-5.037M8.4 49.308v2.738M9.798 50.677H7" />
        </g>
        <path
          clipRule="evenodd"
          d="M23.962 27.356l.001.009c.16 1.432 1.148 2.559 2.402 2.737-1.254.18-2.243 1.307-2.402 2.74l-.001.006c-.156-1.435-1.145-2.566-2.401-2.746h-.002c1.257-.178 2.247-1.309 2.403-2.745v-.001z"
          fill="#E5EBEE"
          fillRule="evenodd"
          stroke="#E5EBEE"
          strokeLinejoin="round"
        />
        <path
          d="M154.39 108.36a1.716 1.716 0 100-3.431 1.716 1.716 0 000 3.431z"
          fill="#EFF2F3"
        />
        <g filter="url(#chart_svg__filter0_d)">
          <path d="M132.77 30.102H39.41v102.28h93.36V30.102z" fill="#F9FAFB" />
        </g>
        <path
          d="M137.57 23.924H45.584v101.6h91.986v-101.6z"
          stroke="#C9D5DB"
          strokeMiterlimit={10}
        />
        <path
          d="M54.51 30.102v77.57h74.825"
          stroke="#C9D5DB"
          strokeMiterlimit={10}
        />
        <path d="M75.791 80.9H58.629v26.086h17.162V80.9z" fill="#EFF2F3" />
        <path d="M99.13 63.053H82.655v44.62H99.13v-44.62z" fill="#E5EBEE" />
        <path
          d="M123.84 42.458h-16.475v65.214h16.475V42.458zM58.628 114.54h-4.805v4.805h4.805v-4.805z"
          fill="#C9D5DB"
        />
        <path d="M64.806 114.54h-4.119v4.805h4.119v-4.805z" fill="#E5EBEE" />
        <path d="M73.044 114.54h-4.805v4.805h4.805v-4.805z" fill="#EFF2F3" />
        <g fill="#C9D5DB">
          <path d="M127.96 115.91h-19.221v.687h19.221v-.687zM127.96 119.34h-19.221v.686h19.221v-.686zM127.96 120.72h-19.221v.686h19.221v-.686z" />
        </g>
      </g>
      <defs>
        <clipPath id="chart_svg__clip0">
          <path d="M0 0h178v148H0z" fill="#fff" />
        </clipPath>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height={112.28}
          id="chart_svg__filter0_d"
          width={103.36}
          x={34.407}
          y={29.102}
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2.5} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgChart
