import { Hidden } from '@epilot/base-elements'
import { ProductDisplayControl, Product } from '@epilot/base-modules'
import { schemaTypeIs, rankWith, and, optionIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'

import { EpilotControlProps } from '../../utils'

type ProductSelectionControlOptions = {
  /**
   * is displayed as a product
   */
  product: boolean
  /**
   * @default 'ProductTile'
   */
  uiType: 'ProductTile' | 'ProductDetail' | 'PricingCard'
  /**
   * label to be used inside the action button
   */
  actionButtonLabel?: string
}

function ProductSelectionControl({
  uischema,
  schema,
  handleChange,
  path,
  visible
}: EpilotControlProps) {
  const options = uischema.options as ProductSelectionControlOptions

  const { default: object } = schema

  const handleOnChange = () => {
    handleChange(path, object.productName)
  }

  return (
    <Hidden xsUp={!visible}>
      <ProductDisplayControl
        actionButtonCallBack={handleOnChange}
        actionButtonLabel={options.actionButtonLabel}
        product={object as Product}
        uiType={options.uiType || 'ProductTile'}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(ProductSelectionControl as never)

export const ProductSelectionTester = rankWith(
  3,
  and(schemaTypeIs('object'), optionIs('product', true))
)
