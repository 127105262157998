import { Grid, useTheme, GridProps, Hidden } from '@epilot/base-elements'
import { LayoutProps, Layout, rankWith, uiTypeIs, or } from '@jsonforms/core'
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'

export type GridLayoutOptions = GridProps & {
  background?: 'none' | 'primary' | 'secondary' | 'contrast'
}

const GridLayout = ({
  uischema,
  renderers,
  schema,
  path,
  enabled,
  visible
}: LayoutProps) => {
  const layout = uischema as Layout
  const { background, ...rest } = (uischema.options as GridLayoutOptions) || {}
  const theme = useTheme()

  let gridItemBgColor

  switch (background) {
    case 'primary':
      gridItemBgColor = theme.palette.primary.main
      break
    case 'secondary':
      gridItemBgColor = theme.palette.secondary.main
      break
    case 'contrast':
      gridItemBgColor = theme.palette.primary.contrastText
      break
    default:
      gridItemBgColor = 'transparent'
      break
  }

  return (
    <Hidden xsUp={!visible}>
      <Grid
        {...getGridType(layout)}
        {...rest}
        style={{
          backgroundColor: gridItemBgColor,
          borderRadius: `${theme.shape.borderRadius}px`,
          ...rest.style
        }}
      >
        {layout.elements.map((child, index) => (
          <JsonFormsDispatch
            enabled={enabled}
            key={index}
            path={path}
            renderers={renderers}
            schema={schema}
            uischema={child}
          />
        ))}
      </Grid>
    </Hidden>
  )
}

function getGridType(uischema: Layout) {
  if (uischema.type === 'GridContainerLayout') {
    return { container: true }
  } else if (uischema.type === 'GridItemLayout') {
    return { item: true }
  }

  return {}
}

export const GridLayoutTester = rankWith(
  2,
  or(
    uiTypeIs('GridLayout'),
    uiTypeIs('GridItemLayout'),
    uiTypeIs('GridContainerLayout')
  )
)

export default withJsonFormsLayoutProps(GridLayout)
