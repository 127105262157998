import * as React from 'react'

function SvgUser(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 32 32"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M5.333 25.333A6.667 6.667 0 0112 18.667h8a6.667 6.667 0 016.667 6.666 4 4 0 01-4 4H9.333a4 4 0 01-4-4z"
      />
      <path d="M16 2.667A6.667 6.667 0 1016 16a6.667 6.667 0 000-13.333z" />
    </svg>
  )
}

export default SvgUser
