import { Card, CardContent, CardProps, Hidden } from '@epilot/base-elements'
import { LayoutProps, Layout, rankWith, uiTypeIs, or } from '@jsonforms/core'
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'

export type CardLayoutOptions = CardProps & {}

const CardLayout = ({
  uischema,
  renderers,
  schema,
  path,
  enabled,
  visible
}: LayoutProps) => {
  const layout = uischema as Layout
  const { ...rest } = uischema.options as CardLayoutOptions

  return (
    <Hidden xsUp={!visible}>
      <Card {...rest}>
        <CardContent>
          {layout.elements.map((child, index) => (
            <JsonFormsDispatch
              enabled={enabled}
              key={index}
              path={path}
              renderers={renderers}
              schema={schema}
              uischema={child}
            />
          ))}
        </CardContent>
      </Card>
    </Hidden>
  )
}

export const CardLayoutTester = rankWith(2, or(uiTypeIs('CardLayout')))

export default withJsonFormsLayoutProps(CardLayout)
