import { Hidden } from '@epilot/base-elements'
import { schemaTypeIs, rankWith, and, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EpilotControlProps } from '../../../utils'
import { includeCommon } from '../../../utils/includeCommon'

import { ContactForm } from './components/ContactForm'
import { isContactControlData, isContactControlOptions } from './types.guard'
import { mapToContactOptions } from './utils'

function ContactControl({
  data,
  handleChange,
  path,
  errors,
  uischema,
  required,
  visible
}: EpilotControlProps) {
  const hasError = !!errors

  const { t } = useTranslation()
  // map configurator dto to props the ContactForm can understand easily
  const [contactOptions, setContactOptions] = useState(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    () => mapToContactOptions(uischema.options as any, t)
  )

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setContactOptions(mapToContactOptions(uischema.options as any, t))
  }, [uischema.options, t, path])

  if (!isContactControlOptions(contactOptions)) {
    console.error(
      'ContactControl -> Invalid uischema.options detected. Passed options are:',
      { options: contactOptions }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  if (!isContactControlData(data)) {
    console.error('ContactControl -> Invalid data detected. Current data is:', {
      data
    })

    return <div>Invalid Block. Check console for more details</div>
  }

  return (
    <Hidden xsUp={!visible}>
      <ContactForm
        data={data}
        handleChange={handleChange}
        hasError={hasError}
        isRequired={!!required}
        path={path}
        {...contactOptions}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: ContactControl }) as never
)

export const ContactControlTester = rankWith(
  5,
  and(schemaTypeIs('object'), uiTypeIs('PersonalInformationControl'))
)
