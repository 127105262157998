import {
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography
} from '@epilot/base-elements'
import { toDashCase } from '@epilot/journey-logic-commons'
import React, { useMemo } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useFormHandler } from '../../../../utils/hooks/forms/useFormHandler'
import { NumberInputFormProps, NumberInputFormValues } from '../types'
import {
  generatePlaceholderBasedOnFormat,
  NUMBER_INPUT_FIELD_NAME
} from '../utils'

const useStyles = makeStyles({
  numberInput: {
    fontWeight: 'bold',
    color: '#C5D0DB'
  }
})

export const NumberInputForm = (props: NumberInputFormProps) => {
  const { hasError, handleChange, path, data, fields, isRequired } = props
  const classes = useStyles()

  const { t } = useTranslation()

  const { control } = useFormHandler<NumberInputFormValues>({
    handleChange,
    path,
    fields,
    data,
    isRequired,
    formProps: {
      defaultValues: {
        numberInput: data?.numberInput
      }
    }
  })

  const id = `${toDashCase(path)}-${NUMBER_INPUT_FIELD_NAME}`

  const placeholder = useMemo(
    () =>
      generatePlaceholderBasedOnFormat(
        fields.numberInput.format?.digitsBeforeDecimalPoint,
        fields.numberInput.format?.decimalPlaces
      ),
    [
      fields.numberInput.format?.decimalPlaces,
      fields.numberInput.format?.digitsBeforeDecimalPoint
    ]
  )

  return (
    <Grid container>
      <Grid item xs={12}>
        <Controller
          control={control}
          defaultValue={fields.numberInput.defaultValue}
          name={NUMBER_INPUT_FIELD_NAME}
          render={({ field, fieldState }) => {
            return (
              <TextField
                InputLabelProps={{ htmlFor: id }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {fields.numberInput.unit?.show && (
                        <Typography
                          className={classes.numberInput}
                          variant="h4"
                        >
                          {fields.numberInput.unit?.label}
                        </Typography>
                      )}
                    </InputAdornment>
                  )
                }}
                error={hasError && fieldState.invalid}
                fullWidth
                helperText={
                  hasError && fieldState.invalid
                    ? fields.numberInput.errorMessage ||
                      fieldState.error?.message ||
                      (fieldState.error?.type === 'validate'
                        ? t('field_number_input_invalid_format', {
                            format: placeholder
                          })
                        : t('field_required'))
                    : ''
                }
                id={id}
                inputProps={{ inputMode: 'decimal' }}
                label={fields.numberInput.label}
                placeholder={
                  fields.numberInput.format?.show
                    ? placeholder
                    : fields.numberInput.placeholder
                }
                required={isRequired}
                {...field}
              />
            )
          }}
          rules={{
            required: isRequired,
            pattern: {
              value: /^[0-9]+([.,][0-9]+)?$/,
              message: t('field_number_input_invalid_digits')
            },
            validate: (value) => {
              // dont validate further if no value or if validate option is disabled
              if (!fields.numberInput.format?.validate || !value) return true

              // accepts decimal numbers with or without decimal point (, or .)
              const parts = value.includes(',')
                ? value.split(',')
                : value.split('.')

              if (
                fields.numberInput.format?.digitsBeforeDecimalPoint !=
                  parts[0].length ||
                fields.numberInput.format?.decimalPlaces !=
                  (parts[1] ? parts[1].length : 0)
              ) {
                return false
              }

              return true
            }
          }}
        />
      </Grid>
    </Grid>
  )
}
