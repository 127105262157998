import { getClient } from '@epilot/file-client'
import axios, { AxiosResponse } from 'axios'

import { PresignedUrlReq, S3File } from '../utils'
import { getBase64 } from '../utils/helper'
import { FILE_API_URL } from '../utils/services/config'

export const apiCall = (
  method: 'GET' | 'POST' | 'PUT',
  url: string,
  data?: Record<string, unknown>,
  headers: Record<string, unknown> = {}
): Promise<AxiosResponse<any>> => {
  return axios({
    method,
    url,
    data,
    headers: headers
  })
}

export const postPresignedPutUrls = (
  data: PresignedUrlReq,
  apiResultPresignedUrl: string
) => {
  return apiCall('POST', apiResultPresignedUrl, data, {
    'Content-Type': 'application/json'
  })
}

export const uploadFile = async (
  file: File,
  orgId: string
): Promise<S3File | undefined> => {
  const client = getClient()

  client.defaults.headers['x-ivy-org-id'] = orgId

  client.defaults.baseURL = FILE_API_URL

  try {
    const response = await client.uploadFilePublic(
      {},
      { filename: file.name, mime_type: file.type }
    )
    const { data } = response
    const base64File = await getBase64(file)

    if (typeof base64File !== 'string') {
      throw Error('Could not get the base64 representation of the file')
    }

    if (!data.upload_url) {
      throw Error('Upload URL not defined')
    }

    if (!data.s3ref) {
      throw Error('S3 Ref not defined')
    }

    const base64data = base64File.split(',')[1]
    const buffer = Buffer.from(base64data, 'base64')

    await putUploadPresignedUrl(buffer, data.upload_url, file.type)

    const currentKey = data.s3ref.key
    const fileName = currentKey.includes('/')
      ? currentKey.substring(currentKey.lastIndexOf('/') + 1)
      : currentKey

    const fileNameArray = file.name.split('.')
    const fileType = fileNameArray[fileNameArray.length - 1]

    return {
      file_name: fileName,
      file_size: file.size,
      original_name: file.name,
      file_type: fileType,
      s3ref: data.s3ref
    }

    // Upload file to s3
  } catch (e) {
    console.error('uploadFile failure -->', e)

    return
  }
}

export const putUploadPresignedUrl = (
  data: Buffer,
  presignedUrl: string,
  contentType: string
) => {
  return apiCall('PUT', presignedUrl, data as any, {
    'Content-Type': contentType,
    'Content-Encoding': 'base64'
  })
}
