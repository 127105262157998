import { isObject } from '../helper'

/**
 * Iterates through passed initial values, and modifies the keys to only contain the blockname
 * @param _filterValuesInitial initial values where keys might contain step name
 * @returns initial values with keys only containing block name
 */
export const getFilterValues = (
  _filterValuesInitial: Record<string, unknown>
): Record<string, unknown> => {
  const _filterValues: Record<string, unknown> = {}

  Object.keys(_filterValuesInitial).forEach((key) => {
    const value = _filterValuesInitial[key]
    const blockName = key.split('/').pop()

    if (!blockName) {
      console.error('getFilterValues --> blockName could not be identified')

      return
    }

    Object.assign(_filterValues, { [blockName]: value })
  })

  return _filterValues
}

/**
 * Finds index of items by attempting to match given value against the array elements
 * @param items array of values
 * @param matchKey key in element of values, which should be used to compare matchedValue against
 * @param matchedValue value to compare against
 */
export const getItemIndex = (
  items: unknown[],
  matchKey: string,
  id: string
): number => {
  return items.findIndex((item) => {
    if (isObject(item) && matchKey in item) {
      const v = item[matchKey]

      if (typeof v === 'string') {
        return id === v
      }
    }

    console.error(
      'applyFilters --> detected value could not be filtered. Likely the matchKey provided does not return an item of type string'
    )

    return false
  })
}
